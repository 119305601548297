import api from "../../../../helper/auth_interceptor";
import { getUser } from "../../../../helper/auth_helper";
import FileSaver from "file-saver";

export async function exportBuildingReport(id, fileName) {
  const user = await getUser();
  const headers = {
    Authorization: `Bearer ${user.access_token}`,
  };
  const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/report/api/v1/drive/building/${id}`;

  try {
    const response = await api.get(apiUrl, {
      headers,
      responseType: "blob", // This ensures the response is treated as binary data (Blob)
    });

    // Create a Blob from the response data
    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });

    // Save the file using FileSaver
    FileSaver.saveAs(blob, fileName);
  } catch (error) {
    console.error("Error downloading the file:", error);
  }
}

export async function exportAddressReport(id, fileName) {
  const user = await getUser();
  const headers = {
    Authorization: `Bearer ${user.access_token}`,
  };
  const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/report/api/v1/drive/address/${id}`;

  try {
    const response = await api.get(apiUrl, {
      headers,
      responseType: "blob", // This ensures the response is treated as binary data (Blob)
    });

    // Create a Blob from the response data
    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });

    // Save the file using FileSaver
    FileSaver.saveAs(blob, fileName);
  } catch (error) {
    console.error("Error downloading the file:", error);
  }
}
