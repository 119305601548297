import React, { useState, useEffect, useRef } from "react";
import { DateRangePicker } from "react-date-range";
import { useTranslation } from 'react-i18next';
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import moment from "moment";
import { toast } from "react-toastify";

export const Planning = ({
  planningDetails,
  setPlanningDetails,
  popInfo,
  quotePartners,
  newQuoteDetails,
  dateCheck
}) => {
  const [startRangeOpen, setStartRangeOpen] = useState(false);
  const [deliveryRangeOpen, setDeliveryRangeOpen] = useState(false);
  const [rangeStartSelected, setRangeStartSelected] = useState(false);
  const [rangeDeliverySelected, setRangeDeliverySelected] = useState(false);
  const [dateError, setDateError] = useState(false);
  const paddingsForDiv={
    padding: "2%"
  }
  const dateFromClient={
    marginLeft: "15px",
    marginTop: "15px"
  }
  const dateFromClientDiv={
    padding: "1% 3%"
  }
  const { t } = useTranslation();
  const [startRangeOutside, setStartRangeOutside] = useState([
    {
      startDate: "",
      endDate: "",
      key: 'selection'
    }
  ]);
  const [deliveryRangeOutside, setDeliveryRangeOutside] = useState([
    {
      startDate: "",
      endDate: "",
      key: 'selection'
    }
  ]);
  const [dateErrorStartOutside, setDateErrorStartOutside] = useState(false);
  const startRangeRefOutside = useRef(null);
  const deliveryRangeRefOutside = useRef(null);

  const desiredStartDateFrom = moment(newQuoteDetails.desiredStartDateFrom, "DD-MM-YYYY");

  const handleStartRangeChangeOutside = (ranges) => {
    const startDate = moment(ranges.selection.startDate);
    if (startDate && startDate.isSameOrAfter(desiredStartDateFrom, 'day')) {
      setStartRangeOutside([ranges.selection]);
      setRangeStartSelected(true);
      if (dateErrorStartOutside) {
        setDateErrorStartOutside(false);
      }
    } else {
      setDateErrorStartOutside(true);
    }
  };

  const handleDeliveryRangeChange = (ranges) => {
    if (ranges.selection.startDate >= startRangeOutside[0].startDate) {
      setDeliveryRangeOutside([ranges.selection]);
      setRangeDeliverySelected(true);
      if (dateError) {
        setDateError(false);
      }
    } else {
      setDateError(true);
    }
  };

  useEffect(() => {
    setPlanningDetails({
      ...planningDetails,
      desiredOutsideDeliveryDateBegin: deliveryRangeOutside[0].startDate,
      desiredOutsideDeliveryDateEnd: deliveryRangeOutside[0].endDate,
    });
  }, [deliveryRangeOutside]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        startRangeOpen &&
        startRangeRefOutside.current &&
        !startRangeRefOutside.current.contains(event.target)
      ) {
        setStartRangeOpen(false);
      }
      if (
        deliveryRangeOpen &&
        deliveryRangeRefOutside.current &&
        !deliveryRangeRefOutside.current.contains(event.target)
      ) {
        setDeliveryRangeOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [startRangeOpen, deliveryRangeOpen]);

  const deliveryDateStr = newQuoteDetails?.project?.endDate
  const [day, month, year] = deliveryDateStr.split('-').map(Number);
  const deliveryDate = new Date(year, month - 1, day);
  const fourteenDaysEarlier = new Date(deliveryDate);
  fourteenDaysEarlier.setDate(deliveryDate.getDate() - 14);
  const formattedDate = `${fourteenDaysEarlier.getDate().toString().padStart(2, '0')}-${(fourteenDaysEarlier.getMonth() + 1).toString().padStart(2, '0')}-${fourteenDaysEarlier.getFullYear()}`;

  const formattedDateParts = formattedDate.split('-').map(Number);
  const formattedDateObject = new Date(formattedDateParts[2], formattedDateParts[1] - 1, formattedDateParts[0]);
  const externalDate = new Date(deliveryRangeOutside[0]?.endDate)
  const isExternalDateGreaterThanFormattedDate = externalDate > formattedDateObject;

  console.log("deliveryRangeOutside",deliveryRangeOutside)
  return (
    <div className="form-head">
      <section class={`accordion`}>
        <div class="tab plan">
          <input type="checkbox" name="accordion-1" id="cb10" />
          <label htmlFor="cb10" className="tab-label form-head quote-detail" >
            <h2>{t('heading_planningQuote')}</h2>
          </label>
          <label style={dateFromClient}><h5>Dates from Client</h5></label>
          <div className="planning-content drone">
            <div class="first-row drone" style={dateFromClientDiv}>
              <div class="rows-content">
                <input className="date form-control" placeholder={newQuoteDetails?.project?.endDate} disabled type="text" />
              </div>
            </div>
          </div>
          <div>
            {popInfo?.outsideProductOfferList?.length === 0 && (
              <div style={paddingsForDiv}>
                <div className="planning-headers">
                  <label>{t('externalPlaningDate_espPlanning')}</label>
                </div>
                <div className="planning-content">
                  <div className="first-row">
                    <div className="rows-content">
                      <label>{t('deliveryDateRange_dspPlanning')}</label>
                      <div class="date form-control">
                        <button class="date-range" onClick={() => setDeliveryRangeOpen(!deliveryRangeOpen)}>
                          {rangeDeliverySelected ? (
                            moment(deliveryRangeOutside[0].startDate).format("DD-MM-YYYY") + " - " + moment(deliveryRangeOutside[0].endDate).format("DD-MM-YYYY")
                          ) : ("Delivery Range")}
                        </button>
                        <div ref={deliveryRangeRefOutside}>
                          {dateError && <p className="message">{t('dileveryDateErrorMsg')}</p>}
                          {deliveryRangeOpen && (
                            <DateRangePicker
                              ranges={deliveryRangeOutside}
                              onChange={handleDeliveryRangeChange}
                            />
                          )}
                        </div>
                        {dateCheck && <div className="PlanningErrorMessage">{t('fillAllDates_errorMsg')}.</div>}
                        {isExternalDateGreaterThanFormattedDate && (
                          <span className="dateWarning">{t('deliveryDateSupportErrorMsg')}
                          </span>)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};
