import React from 'react';
import { Stepper, Step, StepLabel, StepContent } from '@mui/material';


const StepperList = ({ steps, activeStep }) => {
  return (
    <Stepper orientation="horizontal" activeStep={activeStep}>
      {steps.map((step, index) => (
        <Step key={index} completed={activeStep >= index}         sx={{
          '& .MuiStepLabel-root .Mui-completed': {
            color: '#FF6B00', // circle color (COMPLETED)
          },
          '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
            {
              color: 'grey.500', // Just text label (COMPLETED)
            },
          '& .MuiStepLabel-root .Mui-active': {
            color: '#FF6B00', // circle color (ACTIVE)
          },
          '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
            {
              color: 'common.white', // Just text label (ACTIVE)
            },
          '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
            fill: 'black', // circle's number (ACTIVE)
          },
        }}>
          <StepLabel>
            {step.label}
          </StepLabel>
          <StepContent style={{ height: 'auto', overflow: 'hidden' }} orientation="vertical">
            {step.subSteps && step.subSteps.map((subStep, subIndex) => (
              <div key={subIndex} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <div style={{ marginRight: '10px', fontSize:'13px' }}>{subStep.label}</div>
                {/* Add more details or components for each sub-step */}
              </div>
            ))}
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
};

export default StepperList;
