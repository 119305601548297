import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from 'react-i18next';
import "./projectLeaderDetails.modules.css";
import { getUser } from "../../../../helper/auth_helper";


function ProjectLeaderDetails({ projectInfo, setProject, isSuprAdmin, isOrgAdmin, validationErrors }) {
  const handleValueChange = (newProjectDetailsValues) => {
    setProject(newProjectDetailsValues);
  };

  const [users, setUsers] = useState([])
  const { t } = useTranslation();
  useEffect(() => {
    const fetchData = async () => {
      const reqBody = {
        organizationName: null,
        location: null,
        licenceType: null,
        startDate: null,
        endDate: null,
      };

      try {
        const user = await getUser();
        const headers = {
          Authorization: `Bearer ${user.access_token}`,
          "Content-Type": "application/json"
        };

        let apiUrl;
        if (projectInfo.selectedId) {
          apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/users/editProject/filter/${projectInfo.selectedId}`;
        } else {
          apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/users/editProject/filter/${projectInfo.organizationId}`;
        }

        const response = await fetch(apiUrl, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(reqBody),
        });

        const data = await response.json();
        let filteredUsers;
        if (projectInfo.selectedId) {
          filteredUsers = data.data.filter(user => user.organizationId === parseInt(projectInfo.selectedId));
          if (parseInt(projectInfo.selectedId) !== projectInfo.organizationId) {
            projectInfo.leaderContactNo = ''
            projectInfo.leaderFirstName = ''
            projectInfo.leaderLastName = ''
          }
        }
        else {
          filteredUsers = data.data.filter(user => user.organizationId === projectInfo.organizationId);
        }
        setUsers(filteredUsers);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [projectInfo.selectedId]);

  return (
    <div class="projectleader-details">
      <div className="form-head project-leader">
        <h2 className="pageHeading">{t('tabTwoHeader_projectEditProject')}</h2>
      </div>
      {(isOrgAdmin || isSuprAdmin || !projectInfo.readOnly) ?
        (<div class="project-leaderdetails-content">
          <div class="first-row">
            <div className={`rows-content ${validationErrors.leaderEmail && 'error'}`}>
              <label>{t('emailAddress')} <span className="required">*</span></label>
              <select
                className={`form-control ${validationErrors.leaderEmail ? 'error' : ''}`}
                value={projectInfo.leaderEmail}
                onChange={(e) => {
                  const selectedEmail = e.target.value;
                  const selectedUser = users.find(user => user.email === selectedEmail);
                  handleValueChange({
                    ...projectInfo,
                    leaderEmail: selectedEmail,
                    leaderFirstName: selectedUser ? selectedUser.firstName : "",
                    leaderLastName: selectedUser ? selectedUser.lastName : "",
                    leaderContactNo: selectedUser ? selectedUser.contactNo : ""
                  });
                }}
              >
                <option value="">Select Email</option>
                {users && users.map(user => (
                  <option key={user.email} value={user.email}>{user.email}</option>
                ))}
              </select>
              {validationErrors.leaderEmail && <p className="error-message">{validationErrors.leaderEmail}</p>}
            </div>
            <div className={`rows-content ${validationErrors.leaderContactNo && 'error'}`}>
              <label>{t('phoneNo')} <span className="required">*</span></label>

              <input
                className="form-control"
                placeholder={projectInfo.leaderContactNo}
                value={projectInfo.leaderContactNo}
                onChange={(e) =>
                  handleValueChange({
                    ...projectInfo,
                    leaderContactNo: e.target.value,
                  })
                }
                type="text"
                maxLength={18}
              />
              {validationErrors.leaderContactNo && <p className="error-message">{validationErrors.leaderContactNo}</p>}
            </div>
          </div>
          <div class="second-row">
            <div className={`rows-content ${validationErrors.leaderFirstName && 'error'}`}>
              <label>{t('firstName')} <span className="required">*</span></label>
              <input
                className="form-control"
                placeholder={projectInfo.leaderFirstName}
                value={projectInfo.leaderFirstName}
                onChange={(e) =>
                  handleValueChange({
                    ...projectInfo,
                    leaderFirstName: e.target.value,
                  })
                }
                type="text"
              />
              {validationErrors.leaderFirstName && <p className="error-message">{validationErrors.leaderFirstName}</p>}
            </div>
            <div className={`rows-content ${validationErrors.leaderLastName && 'error'}`}>
              <label>{t('lastName')} <span className="required">*</span></label>

              <input
                className="form-control"
                placeholder={projectInfo.leaderLastName}
                value={projectInfo.leaderLastName}
                onChange={(e) =>
                  handleValueChange({
                    ...projectInfo,
                    leaderLastName: e.target.value,
                  })
                }
                type="text"
              />
              {validationErrors.leaderLastName && <p className="error-message">{validationErrors.leaderLastName}</p>}
            </div>
          </div>
        </div>) : (
          <div class="project-leaderdetails-content">
            <div class="first-row">
              <div class="rows-content">
                <label>{t('firstName')}e <span className="required">*</span></label>
                <input
                  className="form-control"
                  placeholder={projectInfo.leaderFirstName}
                  value={projectInfo.leaderFirstName}
                  onChange={(e) =>
                    handleValueChange({
                      ...projectInfo,
                      leaderFirstName: e.target.value,
                    })
                  }
                  type="text"
                  disabled
                />
              </div>
              <div class="rows-content">
                <label>{t('lastName')}<span className="required">*</span></label>

                <input
                  className="form-control"
                  placeholder={projectInfo.leaderLastName}
                  value={projectInfo.leaderLastName}
                  onChange={(e) =>
                    handleValueChange({
                      ...projectInfo,
                      leaderLastName: e.target.value,
                    })
                  }
                  type="text"
                  disabled
                />
              </div>
            </div>
            <div class="second-row">
              <div class="rows-content">
                <label>{t('phoneNo')} <span className="required">*</span></label>

                <input
                  className="form-control"
                  placeholder={projectInfo.leaderContactNo}
                  value={projectInfo.leaderContactNo}
                  onChange={(e) =>
                    handleValueChange({
                      ...projectInfo.current,
                      leaderContactNo: e.target.value,
                    })
                  }
                  type="text"
                  disabled
                />
              </div>
              <div class="rows-content">
                <label>{t('emailAddress')}<span className="required">*</span></label>
                <input
                  className="form-control"
                  placeholder={projectInfo.leaderEmail}
                  value={projectInfo.leaderEmail}
                  onChange={(e) =>
                    handleValueChange({
                      ...projectInfo,
                      leaderEmail: e.target.value,
                    })
                  }
                  type="text"
                  disabled
                />
              </div>
            </div>
          </div>
        )}
    </div>
  );
}

export default ProjectLeaderDetails;
