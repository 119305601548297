import React from "react";
import styles from "./paginationTool.module.css";

export const NewPaginationTool = ({
  currentPage,
  totalPages,
  rowsPerPage,
  rowsPerPageOptions,
  onPageChange,
  onRowsPerPageChange,
}) => {
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <div className={styles.paginationControls}>
      <div className={styles.traverseControls}>
        <button
          className={styles.paginationButton}
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
        >
          &lt;
        </button>
        <div className={styles.pageNumbers}>
          {pageNumbers.map((pageNumber) => (
            <button
              key={pageNumber}
              className={`${styles.pageNumber} ${
                currentPage === pageNumber ? styles.activePage : ""
              }`}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </button>
          ))}
        </div>
        <button
          className={styles.paginationButton}
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          &gt;
        </button>
      </div>
      <div className={styles.dropDownsSelect}>
        <select
          className={styles.rowsPerPageSelect}
          value={rowsPerPage}
          onChange={(e) => onRowsPerPageChange(Number(e.target.value))}
        >
          {rowsPerPageOptions.map((option) => (
            <option key={option} value={option}>
              Show {option}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
