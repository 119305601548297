import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import UserItem from "../userItem/userItem";
import "./userList.modules.css";

function UserList({
  userList,
  deleteFromUserList,
  updateUserInList,
  id,
  fetchProject,
}) {
  const getIndex = (index) => `${index} - userListItem`;

  return userList.map((user, index) => {
    const userIndex = getIndex(index);

    return (
      <div key={userIndex}>
        <UserItem
          user={user}
          fetchProject={fetchProject}
          urlId={id}
          index={index}
          deleteFromUserList={() => deleteFromUserList(user.id)}
          updateUserInList={(updatedUser) =>
            updateUserInList(updatedUser, user.id)
          }
        />
      </div>
    );
  });
}

export default UserList;
