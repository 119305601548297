import React from "react";
import { DateRangePicker } from "react-date-range";
import searchbar from "../../../images/search-bar.png";
import status from "../../../images/status-icon.png";
import role from "../../../images/role.png";
import date from "../../../images/date-icon.png";
import createicon from "../../../images/create-icon.png";

export const UserListFilter = ({
t,
requestBody,
handleSearchFormSubmit,
onSelectStatus,
onSelectRole,
setShowCalendar,
showCalendar,
dateRangeRef,
selectedDateRange,
handleDateChange,
toggleClearFilter,
handleClickExport


}) => {
    return(
        <div class="userlist-details-content">
        <div class="content-options">
          <div class="search-option searchfilter">
            <div class="search-input">
              <img src={searchbar} alt="home icon" />
              <input
                className="checkInputManageUserList"
                type="text"
                placeholder={t("search")}
                value={requestBody.searchText}
                onChange={handleSearchFormSubmit}
              />
            </div>
          </div>

          <div class="content-button search filterIconsMainDiv">
            <img src={status} alt="status icon" />
            <span>
              <select
                className="user-select"
                onChange={(e) => onSelectStatus(e)}
              >
                <option value="">
                  {t("filter1_manageUsers")}
                </option>
                <option value="ACTIVE">
                  {t("active_dropDownMenu")}
                </option>
                <option value="INACTIVE">
                  {t("inactive_dropDownMenu")}
                </option>
              </select>
            </span>
          </div>

          <div class="content-button search filterIconsMainDiv">
            <img src={role} alt="role icon" />
            <span>
              <select
                className="user-select"
                onChange={(e) => onSelectRole(e)}
              >
                <option value="">
                  {t("filterRole_manageUsers")}
                </option>
                <option value="SUPRMEN_ADMIN">
                  {t("suprmenAdmin_dropDownMenu")}
                </option>
                <option value="ORG_ADMIN">
                  {t("orgAdmin_dropDownMenu")}
                </option>
                <option value="REGULAR">
                  {t("regular_dropDownMenu")}
                </option>
                <option value="VIEWER">
                  {t("viewer_dropDownMenu")}
                </option>
              </select>
            </span>
          </div>

          <div class="content-button date filterIconsMainDiv">
            <img
              src={date}
              alt="date icon"
              className="dateicon"
            />
            <button
              className="btn-date"
              onClick={() => setShowCalendar(!showCalendar)}
            >
              <span>{t("dateFilter_manageUsers")}</span>
            </button>
            <div className="dateRange" ref={dateRangeRef}>
              {showCalendar && (
                <DateRangePicker
                  ranges={selectedDateRange}
                  onChange={handleDateChange}
                />
              )}
            </div>
          </div>
          <div class="content-button clear filterIconsMainDiv">
            <button class="btn-clear" onClick={toggleClearFilter}>
              <span>{t("clearFilter_manageUsers")}</span>
            </button>
          </div>
          <div class="export">
            <button className="btn" onClick={handleClickExport}>
              <span>{t("exportBtn_manageUsers")}</span>
            </button>
          </div>
        </div>

        <div class="right-buttons">
          <div class="create-user-btn">
            <button class="btn quoteBtns" type="button">
              <a href="/createUser" className="quotelink">
                <img src={createicon} alt="createIcon"></img>
                <span>{t("tableHeadingBtn_manageUsers")}</span>
              </a>
            </button>
          </div>
        </div>
      </div>
    )
}