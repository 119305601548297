import rightArrow from "../../images/right-arrow.png";
import home from "../../images/home.png";
import styles from "./pageHeader.module.css";

export const PageHeader = ({ pageName, returnPage }) => {
  return (
    <div className={styles.contentHeader}>
      <h1 className={styles.contentHeaderHeading}>{pageName}</h1>

      <div className={styles.user}>
      <button>
        <img src={home} alt="home icon" />
        <img src={rightArrow} alt="right arrow icon" />
        <span>{returnPage}</span>
      </button>
    </div>
    </div>
  );
};
