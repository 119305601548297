import React, { useState, useEffect } from "react";
import "./searchWithDropdown.modules.css";
import { getUser } from "../../helper/auth_helper";
import { useTranslation } from 'react-i18next';

const SearchWithDropdown = ({ onSelectItem, getApiUrl, searchInputValue }) => {
  //constants and state declarations
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showAllResults, setShowAllResults] = useState(false);
  const { t } = useTranslation();

  //useEffect to fetch results for typed in text spontaneously
  useEffect(() => {
    let timeoutId;
    const fetchSearchResults = async () => {
      const user = await getUser();

      try {
        const url = getApiUrl(searchTerm);
        const headers = {
          Authorization: `Bearer ${user.access_token}`,
        };

        const response = await fetch(url, {
          headers,
        });
        const data = await response.json();
        if (Array.isArray(data)) {
          
          setSearchResults(data);
        } else {
          setSearchResults([]);
        }

        console.log("API call with search term:", url);
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    };
    if (searchTerm !== "") {
      timeoutId = setTimeout(() => {
        fetchSearchResults();
      }, 500);
    } else {
      clearTimeout(timeoutId);
      setSearchResults([]);
    }

    return () => clearTimeout(timeoutId);
  }, [getApiUrl, searchTerm]);

  //function to handle input change
  const handleInputChange = (event) => {
    const { value } = event.target;
    setSearch(value);
  };

  //function to set correct search value after input change
  const setSearch = (value) => {
    searchInputValue.current = value;
    setSearchTerm(value);
  };

  //list toggling functions
  const handleShowMore = () => {
    setShowAllResults(true);
  };

  const handleShowLess = () => {
    setShowAllResults(false);
  };

  const displayedResults = showAllResults
    ? searchResults
    : searchResults.slice(0, 3);

  return (
    <div class="search-inviteuser">
    <div>
       <input
          className="form-control searchBar"
          type="text"
          placeholder={t('placeholderUserSearch_editProjectTable')}
          value={searchTerm}
          onChange={handleInputChange}
          />
    </div>
    <div  class="result-data">
       {displayedResults.map((result) => (
       <div className="resultDiv" key={result.id}>
          <span>
             <h4>
                {result.firstName} {result.lastName}
             </h4>
             <p>{result.username}</p>
          </span>
          <button
             className="btn plusBtn"
             onClick={() => {
          onSelectItem(result);
          setSearch();
          }}
          >
          +
          </button>
       </div>
       ))}
       {searchResults.length > 3 && (
       <div>
          {!showAllResults && (
          <button className="btn showMoreBtn" onClick={handleShowMore}>
          {t('showMore_editProjectTable')}
          </button>
          )}
          {showAllResults && (
          <button className="btn showLessBtn" onClick={handleShowLess}>
          {t('showLess_editProjectTable')}
          </button>
          )}
       </div>
       )}
    </div>
 </div>
  );
};
export default SearchWithDropdown;
