import React, { useEffect, useState } from "react";
import "./insideService.modules.css";
import { useTranslation } from 'react-i18next';
import { getUser } from "../../../../../helper/auth_helper";
import api from "../../../../../helper/auth_interceptor";
import { toast } from "react-toastify";
import Frame from "../../../../../images/Frame.png";
import secondaryDelete from "../../../../../images/secondaryDelete.png";

export const InsideServiceAccord = ({
  offerDetails,
  newQuoteDetails
}) => {
  const [data, setData] = useState([]);
  const [isAnyOptionSelected, setIsAnyOptionSelected] = useState(true);
  const { t } = useTranslation();

  const AlignRight={
    position:"relative",
    right: "15px"
  }

  const serviceData = newQuoteDetails.quoteInsideServices;


  const getData = async () => {
    const user = await getUser();
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/quote/insideServices`;

    try {
      const response = await api.get(apiUrl, {
        headers,
      });
      if (response) {
        setData(response.data);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessages = Object.values(error.response.data).join("\n ");
        toast.error(errorMessages);
      }
    }
  };

  function calculateTotalCost(selectedInsideServiceOffer) {
    if (!selectedInsideServiceOffer) return 0;
  
    const totalCost = selectedInsideServiceOffer.insideServiceOffers.reduce((total, item) => total + item.cost, 0);
    const commissionPercentage = selectedInsideServiceOffer.commission || 0;
    const totalWithCommission = totalCost + commissionPercentage;
  
    return totalWithCommission.toFixed(2); 
  }


  useEffect(() => {
    getData();
  }, []);





  return (
    <div className="form-head">
      <section class="accordion">
        <div class="tab">
          <input type="checkbox" name="accordion-1" id="cb7" />
          <label for="cb7" class="tab-label form-head quote-detail">
            <h2>{t('interiorServices_insideServiceQuote')}</h2>
          </label>
          <div class="tab-content record">
            <div>
            
              <div className="row">
                <div class="model col-lg-3 col-md-3 col-sm-3 inside-service">
                  {data?.map((item, index) => (
                    <div
                      class={`outsideIteminputfields ${index === 0
                          ? "first-item"
                          : index === 1
                            ? "second-item"
                            : ""
                        }`}
                    >
                      <input
                        type="checkbox"
                        className="InServSectOffrInput"
                        checked={serviceData.map((service) => service.insideServices.id).includes(item.id)}
                        disabled
                     
                      />
                      <label for={item.id}>{item.name}</label>
                    </div>
                  ))}
                </div>
                <div className="col-lg-9 col-md-9 col-sm-9">
                  <div className="left-content">
                    <table className="dspTable">
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th style={AlignRight}>Price(€)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {offerDetails.selectedInsideServiceOffer && offerDetails.selectedInsideServiceOffer.insideServiceOffers.map((item, index) => (
                          <tr key={index}>
                            <td>{item.quoteInsideServices.insideServices.name}</td>
                            <td style={AlignRight}>{new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(item.cost)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <p className="commissionOutTag">{t('commisions_partnerAccord')} - {new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(offerDetails.selectedInsideServiceOffer && offerDetails.selectedInsideServiceOffer.commission)}</p>
                    <p className="commissionOutTag" >{t('totalCost_outsideAccordian')} - {new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(calculateTotalCost(offerDetails.selectedInsideServiceOffer))}  ({t('commissionsIncluded_outsideAccordian')})</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
};
