import React, { useEffect, useState } from "react";
import { getUser } from "../../../../helper/auth_helper";
import { useTranslation } from "react-i18next";
import api from "../../../../helper/auth_interceptor";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./projectDetails.modules.css";
import moment from "moment";

function ProjectDetails({
  isOrgAdmin,
  projectInfo,
  setProject,
  validationErrors,
}) {
  const [orgDetails, setOrgDetails] = useState(undefined);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { t } = useTranslation();
  const handleValueChange = (newProjectDetailsValues) => {
    setProject(newProjectDetailsValues);
  };
  const endDateChange = (date) => {
    const enDate = moment(date).format("DD-MM-YYYY");
    setProject({ ...projectInfo, endDate: enDate });
  };

  const getOrgDetails = async () => {
    const user = await getUser();
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/organization`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try {
      const response = await api.get(apiUrl, {
        headers,
      });

      setOrgDetails(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  function convertDateFromString(dateString) {
    const momentDate = moment(dateString, "DD-MM-YYYY", true);
    if (momentDate.isValid()) {
      const convertedDate = momentDate.toDate();
      return convertedDate;
    } else {
      console.error("Invalid date format");
      return null;
    }
  }

  useEffect(() => {
    getOrgDetails();
  }, []);

  useEffect(() => {
    setStartDate(convertDateFromString(projectInfo.startDate));
    setEndDate(convertDateFromString(projectInfo.endDate));
  }, [projectInfo.startDate, projectInfo.endDate]);

  return (
    <div class="project-details">
      <div className="form-head project-details">
        <h2 className="client pageHeading">
          {t("tabOneTableHeader_projectEditProject")}
        </h2>
      </div>

      <div class="project-details-content">
        <div class="first-row">
          <div className={`rows-content ${validationErrors.name && "error"}`}>
            <label>
              {t("tabOneProjectName_projectEditProject")}{" "}
              <span className="required">*</span>
            </label>
            {!projectInfo.readOnly ? (
              <input
                className="form-control"
                placeholder={projectInfo.name}
                value={projectInfo.name}
                onChange={(e) =>
                  handleValueChange({
                    ...projectInfo,
                    name: e.target.value,
                  })
                }
                type="text"
              />
            ) : (
              <input
                className="form-control"
                placeholder={projectInfo.name}
                value={projectInfo.name}
                type="text"
                disabled
              />
            )}

            {validationErrors.name && (
              <p className="error-message">{validationErrors.name}</p>
            )}
          </div>
          <div className="rows-content">
            <label>
              {t("tabOneOrgName_projectEditProject")}{" "}
              <span className="required">*</span>
            </label>

            {isOrgAdmin || projectInfo.readOnly ? (
              <input
                type="text"
                className="form-control"
                placeholder={projectInfo.organizationName}
                disabled
              />
            ) : (
              <select
                className="form-control"
                value={projectInfo.organizationName}
                onChange={(e) => {
                  const selectedOrgName = e.target.value;
                  const selectedOrgDetail = orgDetails.find(
                    (orgDetail) => orgDetail.name === selectedOrgName
                  );
                  handleValueChange({
                    ...projectInfo,
                    organizationName: selectedOrgName,
                    selectedId: selectedOrgDetail ? selectedOrgDetail.id : "",
                  });
                }}
              >
                {orgDetails?.map((orgDetail) => (
                  <option key={orgDetail.id} value={orgDetail.name}>
                    {orgDetail.name}
                  </option>
                ))}
              </select>
            )}
          </div>
        </div>

        <div class="second-row">
          <div class="rows-content">
            <label>{t("tabOneDescription_projectEditProject")} </label>
            {!projectInfo.readOnly ? (
              <textarea
                className="form-control"
                placeholder={projectInfo.description}
                value={projectInfo.description}
                onChange={(e) =>
                  handleValueChange({
                    ...projectInfo,
                    description: e.target.value,
                  })
                }
              ></textarea>
            ) : (
              <textarea
                className="form-control"
                placeholder={projectInfo.description}
                value={projectInfo.description}
                disabled
              ></textarea>
            )}
          </div>
        </div>

        <div class="third-row">
          {/* <div class="rows-content">
            <label>
              Start Date <span className="required">*</span>
            </label>

            <div className="date form-control">
              <DatePicker
                dateFormat="dd-MM-yyyy"
                selected={startDate}
                onChange={(date) => {startDateChange(date)
                  setStartDate(date)}}
              />
            </div>
          </div> */}
          <div
            className={`rows-content ${validationErrors.endDate && "error"}`}
          >
            <label>
              {t("tabOneDate_projectEditProject")}{" "}
              <span className="required">*</span>
            </label>

            <div className="date form-control">
              {!projectInfo.readOnly ? (
                <DatePicker
                  dateFormat="dd-MM-yyyy"
                  selected={endDate}
                  onChange={(date) => {
                    endDateChange(date);
                    setEndDate(date);
                  }}
                />
              ) : (
                <p>{moment(endDate).format("DD-MM-YYYY")}</p>
              )}
            </div>
            {validationErrors.endDate && (
              <p className="error-message">{validationErrors.endDate}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectDetails;
