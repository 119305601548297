import React, { useState } from "react";
import { getUser } from "../../../../helper/auth_helper";
import { useTranslation } from "react-i18next";
import api from "../../../../helper/auth_interceptor";
import { useDropzone } from "react-dropzone";
import Modal from "react-modal";
import "./bulkUploader.modules.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import upload from "../../../../images/upload.png";
import bin from "../../../../images/bin.png";
import csv from "../../../../images/csvFormat.png";

const BulkUpload = ({ projectId, getAddresses, projectInfo }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const navigate = useNavigate();
  const currentPage = 1;
  const { t } = useTranslation();
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles);
    },
  });

  const handleDeleteFile = (fileName) => {
    const updatedFiles = files.filter((file) => file.name !== fileName);
    setFiles(updatedFiles);
  };

  const formatFileSize = (sizeInBytes) => {
    const sizeInKilobytes = sizeInBytes / 1024;
    return sizeInKilobytes.toFixed(2) + " KB";
  };

  const handleSubmit = async () => {
    const user = await getUser();
    const formData = new FormData();
    formData.append("file", files[0]);
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/project-management/api/v1/project/upload/${projectId}`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
      "Content-Type": "multipart/form-data; boundary=someRandomBoundary",
    };
    try {
      const response = await api.post(apiUrl, formData, { headers });
      if (response) {
        getAddresses("", currentPage);

        navigate("/editProject/" + projectId);
      }
    } catch (error) {
      if (error.response && error.response.status === 415) {
        toast.error(
          `${t('format_ErrorMessage')}`
        );
      } else if (error.response && error.response.status === 400) {
        if (
          error.response.data &&
          Object.values(error.response.data).length > 0
        ) {
          const errorMessages = Object.values(error.response.data).join("\n ");
          toast.error(errorMessages);
        } else {
          toast.error(`${t('badRequest_errorMessage')}`);
        }
      } else if (error.response && error.response.status === 409) {
        toast.error(
          `${t('blkUploadErr1_errorMessage')}`
        );
      } else {
        toast.error(`${t('blkUploadErr2_errorMessage')}`);
      }
      console.error("Error fetching data:", error);
    }

    setFiles([]);
    setModalIsOpen(false);
  };

  return (
    <div>
      {!projectInfo.readOnly ? (
        <button
          className="btn btn-success bulkUploadBtn"
          onClick={() => setModalIsOpen(true)}
        >
          {t("bulkUploadBtn_bulkUpload")}
        </button>
      ) : (
        <button className="btn btn-success bulkUploadBtn" disabled>
          {t("bulkUploadBtn_bulkUpload")}
        </button>
      )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={{
          content: {
            width: "50%",
            height: "70%",
            margin: "auto",
          },
        }}
      >
        <div className="modal-content">
          <div class="modal-header">
            <div>
              <h2>{t("pageHeader_bulkUpload")}</h2>
            </div>
            <div>
              <button
                className="closeBtn"
                onClick={() => setModalIsOpen(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  height="25px"
                >
                  <line x1="18" y1="6" x2="6" y2="18" />
                  <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
              </button>
            </div>
          </div>

          <div class="userdiv">
            <div {...getRootProps()} className="dropzone">
              <input {...getInputProps()} />
              <img src={upload}></img>
              <h4>{t("textHeading_bulkUpload")}</h4>
              <p>{t("textDescription_bulkUpload")}</p>
            </div>
            {files.length > 0 && (
              <div className="fileDiv row">
                <div class="main-file-content">
                  <div class="sub-file-content">
                    <div>
                      <img src={csv}></img>
                    </div>
                    <div>
                      <ul>
                        {files.map((file) => (
                          <li key={file.name}>
                            <h5>{file.name}</h5>
                            <p>{formatFileSize(file.size)}</p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div>
                    {files.map((file) => (
                      <button
                        className="bin"
                        onClick={() => handleDeleteFile(file.name)}
                      >
                        <img src={bin}></img>
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            )}
            <span className="closeBtn bulk-upload">
              <button
                className="btn btn-outline-success btn1 modal-cancel-btn"
                onClick={() => setModalIsOpen(false)}
              >
                <span>{t("cancel")}</span>
              </button>
              <button
                className="btn btn-success modal-submit-btn"
                onClick={handleSubmit}
              >
                <span>{t("submit")}</span>
              </button>
            </span>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BulkUpload;
