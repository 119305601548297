import React, { useEffect, useState } from "react";
import { getUser } from "../../../../helper/auth_helper";
import { useTranslation } from 'react-i18next';
import api from "../../../../helper/auth_interceptor";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./projectDetails.modules.css";

function ProjectDetails({
  isSuprAdmin,
  isOrgAdmin,
  projectDetails,
  projectInfo,
  setProject,
  validationErrors,
}) {
  const [orgDetails, setOrgDetails] = useState(undefined);
  const [endDate, setEndDate] = useState("");
  const { t } = useTranslation();
  const handleValueChange = (newProjectDetailsValues) => {
    setProject(newProjectDetailsValues);
  };

  const getOrgDetails = async () => {
    const user = await getUser();
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/organization`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try {
      const response = await api.get(apiUrl, {
        headers,
      });

      setOrgDetails(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleValueChange({
      ...projectInfo,
      endDate: endDate,
    });
  }, [endDate]);

  useEffect(() => {
    getOrgDetails();
  }, []);

  return (
    <>
      <div class="project-details">
        <div className="form-head project-details">
          <h2 className="pageHeading">{t('tabOneTableHeader_projectEditProject')}</h2>
        </div>
        <div class="project-details-content">
          <div class="first-row">
            <div className={`rows-content ${validationErrors.name && "error"}`}>
              
              <label>
                {t('tabOneProjectName_projectEditProject')} <span className="required">*</span>
              </label>
              <input
                className="form-control"
                placeholder={t('tabOneProjectName_projectEditProject')}
                value={projectInfo.name}
                onChange={(e) =>
                  handleValueChange({
                    ...projectInfo,
                    name: e.target.value,
                  })
                }
                type="text"
              ></input>
              {validationErrors.name && (
                <p className="error-message">{validationErrors.name}</p>
              )}
            </div>
            <div
              className={`rows-content ${
                validationErrors.selectedId ? "error" : ""
              }`}
            >
              <label>
                {t('tabOneOrgName_projectEditProject')} <span className="required">*</span>
              </label>
              <div>
                <select
                  className="form-control "
                  onChange={(e) =>
                    handleValueChange({
                      ...projectInfo,
                      selectedId: e.target.value,
                    })
                  }
                >
                  <option>{t('tabOnePlaceholder1_project')}</option>
                  {orgDetails?.map((orgDetail) => (
                    <option value={orgDetail.id}>{orgDetail.name}</option>
                  ))}
                </select>
                {validationErrors.selectedId && (
                  <p className="error-message">{validationErrors.selectedId}</p>
                )}
              </div>
              
            </div>
          </div>
          <div class="second-row">
            <div className={`rows-content ${
                validationErrors.description && "error"
              }`}>
              
              <label>{t('tabOneDescription_projectEditProject')}</label>
              <textarea
                className="form-control"
                placeholder={t('tabOnePlaceholder2_project')}
                value={projectInfo.description}
                onChange={(e) =>
                  handleValueChange({
                    ...projectInfo,
                    description: e.target.value,
                  })
                }
              ></textarea>
              {validationErrors.description && (
                <p className="error-message">{validationErrors.description}</p>
              )}
            </div>
          </div>
          <div class="third-row">
            <div className={`rows-content ${validationErrors.endDate && "error"}`}>
              
              <label>
              {t('tabOneDate_projectEditProject')} <span className="required">*</span>
              </label>
              <div className="date form-control">
                <DatePicker
                  selected={endDate}
                  onSelect={(date) => setEndDate(date)}
                />
              </div>
              {validationErrors.endDate && (
                <p className="error-message">{validationErrors.endDate}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProjectDetails;
