import React, { useState, useEffect } from "react";
import { getUser } from "../../../../../helper/auth_helper";
import api from "../../../../../helper/auth_interceptor";
import { toast } from "react-toastify";
import StepperList from "../stepper/StepperList";


export const Workflow = ({ id, setStatBtn }) => {
    const [currentStep, setCurrentStep] = useState(-1);
    const [steps, setSteps] = useState([]);
    // const mainWorkflowDiv = {
    //     margin: '0',
    //     position: 'sticky',
    //     top: '0px',
    //     background: '#f6f7fc',
    //     padding: '25px 14px',
    //     zIndex: '999',
    //     overrflowX: 'scroll',
    // }


    
    useEffect(() => {
        getWorkflowStep();
    }, []);

    const getWorkflowStep = async () => {
        try {
            const user = await getUser();
            const headers = {
                Authorization: `Bearer ${user.access_token}`,
            };
            const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/workflowinstance/timeline/${id}`;

            const response = await api.get(apiUrl, { headers });

            if (response) {
               setSteps(response.data.stepList);
               setCurrentStep(response.data.activeStep);
            }
        } catch (error) {
            if (error.response && error.response.data) {
                const errorMessages = Object.values(error.response.data).join("\n ");
                toast.error(errorMessages);
            }
        }
    };

    return (
        <div className="editSidebar">
        <StepperList steps={steps} activeStep={currentStep}></StepperList>
        </div>
    );
};
