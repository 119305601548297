import React from "react";
import checkmark from "../../../../images/checkmark.png";
import "./progressBar.modules.css";
import { useTranslation } from 'react-i18next';

export const ProgressBar = ({ componentCompletion,  newQuoteDetails, insepectionCheck, inpectionSelected }) => {
  console.log(componentCompletion.current.outsideInspection);
  const { t } = useTranslation();
  return (
    <div class="timebar-content">
      <div class="sub-content">
        <input
          type="checkbox"
          checked={componentCompletion.current.quoteDetails}
          disabled
        />
        <label>
          <h6>{t('quoteDetails_progressBarQuote')}</h6>
        </label>
      </div>

      <div class="sub-content">
        <input
          type="checkbox"
          checked={componentCompletion.current.scanOption}
          disabled
        />
        <label>
          <h6>{t('selectScanOption_progressBarQuote')}</h6>
        </label>
      </div>
      {newQuoteDetails?.quoteStatusType !== 'DRAFT' && (
           <div class="sub-content">
           <input
             type="checkbox"
             checked={componentCompletion.current.partner}
             disabled
           />
           <label>
             <h6>Select Partner</h6>
           </label>
         </div>
      )}
   

      {( newQuoteDetails.productType === "OUTSIDE" ||
         newQuoteDetails.productType === "BOTH") && (
        <>
          <div class="sub-content">
            <input
              type="checkbox"
              checked={componentCompletion.current.outside}
              disabled
            />
            <label>
              <h6>{t('exterior_progressBarQuote')}</h6>
            </label>
          </div>
          <div class="sub-content">
            <input
              type="checkbox"
              checked={componentCompletion.current.outsidePurpose}
              disabled
            />
            <label>
              <h6>{t('exteriorPurpose_progressBarQuote')}</h6>
            </label>
          </div>
          <div class="sub-content">
            <input
              type="checkbox"
              checked={componentCompletion.current.outsideExtras}
              disabled
            />
            <label>
              <h6>{t('exteriorExtras_progressBarQuote')}</h6>
            </label>
          </div>
          {inpectionSelected ? (
            <div class="sub-content">
            <input
              type="checkbox"
              checked={insepectionCheck}
              disabled
            />
            <label>
              <h6>{t('exteriorInspectionReport_progressBarQuote')}</h6>
            </label>
          </div>
          ) : null}
          
          
        </>
      )}
{( newQuoteDetails.productType === "INSIDE" ||
         newQuoteDetails.productType === "BOTH") && (
          <>
           <div class="sub-content">
        <input
          type="checkbox"
          checked={componentCompletion.current.insideProduct}
          disabled
        />
        <label>
          <h6>{t('interiorProduct_progressBarQuote')}</h6>
        </label>
      </div>
           <div class="sub-content">
        <input
          type="checkbox"
          checked={componentCompletion.current.insideServices}
          disabled
        />
        <label>
          <h6>{t('interiorService_progressBarQuote')}</h6>
        </label>
      </div>

     
          </>
        )}
    
      <div class="sub-content">
        <input
          type="checkbox"
          checked={newQuoteDetails.quoteStatusType === 'DRAFT' ?? componentCompletion.current.planning}
          disabled
        />
        <label>
          <h6>{t('heading_planningQuote')}</h6>
        </label>
      </div>
    </div>
  );
};
