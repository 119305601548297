import React from "react";
import { useTranslation } from 'react-i18next';


export const PurchaseHistory= ({info, list}) => {
  const { t } = useTranslation();
  
    return(
       
          <div class="project-leaderdetails-content">
            <div className="general-info">
              <h4>{t('tabThreeSubHead1_editOrg')}</h4>
              <div className="generalInfo">
                <p>{t('tabThreeStartDate_editOrg')}: {info.startDate}</p>
                <p>{t('tabThreeEndDate_editOrg')}: {info.endDate}</p>
                <p>
                  {t('tabThreeMinNoBuilds_editOrg')}:
                  {info.minNumberOfInactiveBuildings}
                </p>
                <p>
                  {t('tabThreeActiveBuildings_editOrg')}:
                  {info.numberOfActiveBuildings}
                </p>
                <p>
                  {t('tabThreeAllowedBuildings_editOrg')}:
                  {info.numberOfAllowedBuildings}
                </p>
              </div>
            </div>
            <div className="bundle-address-data">
              <h4>{t('tabThreeSubHead2_editOrg')}</h4>
              <div className="bundle-address-data-table">
                <table>
                  <thead>
                    <tr>
                    <th  class="opac">{t('name_table')}</th>
                    <th  class="opac">{t('noOfBuildingPerBucket_Table')}</th>
                    <th  class="opac">{t('avgPricePerBuildingPerYear_table')}</th>
                    <th  class="opac">{t('pricePerBucket_table')}</th>
                    <th  class="opac">{t('minActiveBuilding_table')}</th>
                    <th  class="opac">{t("startDate_table")}</th>
                    <th  class="opac">{t("endDate_table")}</th>
                    <th  class="opac">{t('price_table')}</th>
                    <th  class="opac">{t('discount_table')}</th>
                                          
                    </tr>
                  </thead>
                  <tbody>
                    {list.map((item) => (
                      <tr>
                        <td>{item.bundleName}</td>
                        <td>{item.numberOfBuildingsInBucket}</td>
                        <td>
                          
                          €{item.avgPricePerBuildingPerYear.toLocaleString('nl-NL', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                        </td>
                        <td> €{item.pricePerBucket.toLocaleString('nl-NL', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        <td>
                          
                          {item.minimumNumberOfInactiveBuilding}
                        </td>
                        <td>{item.startDate}</td>
                        <td>{item.endDate}</td>
                        <td> €{item.price.toLocaleString('nl-NL', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        <td>{item.discount !== null ? `${item.discount}%` : ''}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
    )
}
