import React, { useState, useEffect, useRef } from "react";
import "./planning.modules.css";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import moment from "moment";
import { useTranslation } from 'react-i18next';


export const Planning = ({
  planningDetails,
  setPlanningDetails,
  dateErrors,
}) => {
  const [startRangeOpen, setStartRangeOpen] = useState(false);
  const [deliveryRangeOpen, setDeliveryRangeOpen] = useState(false);
  const [rangeStartSelected, setRangeStartSelected] = useState(false);
  const [rangeDeliverySelected, setRangeDeliverySelected] = useState(false);
  const[dateError, setDateError] = useState(false);

  const [startRange, setStartRange] = useState([
    {
      startDate: "",
      endDate: "",
      key: "selection",
    },
  ]);
  const [deliveryRange, setDeliveryRange] = useState([
    {
      startDate: "",
      endDate: "",
      key: "selection",
    },
  ]);
  const { t } = useTranslation();
  const startRangeRef = useRef(null);
  const deliveryRangeRef = useRef(null);

  const handleDeliveryRangeChangeOutside = (ranges) => {
    if (ranges.selection.startDate >= startRange[0].startDate) {
      setDeliveryRange([ranges.selection]);
      setRangeDeliverySelected(true);
      if (dateError) {
        setDateError(false);
      }
    } else {
      setDateError(true);
    }
  };

  useEffect(() => {
    setPlanningDetails({
      ...planningDetails,
      desiredDeliveryDateFrom: deliveryRange[0].startDate,
      desiredDeliveryDateTo: deliveryRange[0].endDate,
    });
  }, [startRange, deliveryRange]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        startRangeOpen &&
        startRangeRef.current &&
        !startRangeRef.current.contains(event.target)
      ) {
        setStartRangeOpen(false);
      }

      if (
        deliveryRangeOpen &&
        deliveryRangeRef.current &&
        !deliveryRangeRef.current.contains(event.target)
      ) {
        setDeliveryRangeOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [startRangeOpen, deliveryRangeOpen]);

  return (
    <div className="planning-content">
      <div className="first-row">

        <div className="rows-content">
          <label>{t('finalDeliveryDate')}</label>
          <div class="date form-control">
            <button
              class="date-range"
              onClick={() => setDeliveryRangeOpen(!deliveryRangeOpen)}
            >
              {rangeDeliverySelected
                ? moment(deliveryRange[0].startDate).format("DD-MM-YYYY") +
                  " - " +
                  moment(deliveryRange[0].endDate).format("DD-MM-YYYY")
                : `${t('deliveryRange')}`}
            </button>
            <div ref={deliveryRangeRef}>
              {dateError && <p className="message">{t('dileveryDateErrorMsg')} </p>}
              {deliveryRangeOpen && (
                <DateRangePicker
                  ranges={deliveryRange}
                  onChange={handleDeliveryRangeChangeOutside}
                />
              )}
            </div>
            <p className="datemessage">{dateErrors.desiredEndDate}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
