import React, { useState, useEffect } from "react";
import "./quoteAccordian.modules.css";
import { useTranslation } from 'react-i18next';
import { getUser } from "../../../../../helper/auth_helper";
import api from "../../../../../helper/auth_interceptor";

import { toast } from "react-toastify";



export const QuoteDetails = ({
  suprmenAdmin,
  // setComponentCompletion,
  validationCheck,
  newQuoteDetails,
  setProjectId,
  setDspId,
}) => {
  const [projectData, setProjectData] = useState([]);

  const [doData, setDoData] = useState([]);
  const { t } = useTranslation();


  const updateProject = (value) => {
    setProjectId(value);
  };

  const projectDataId = newQuoteDetails.project.id;

  const getProject = async () => {
    const user = await getUser();
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/project-management/api/v1/project`;

    try {
      const response = await api.get(apiUrl, {
        headers,
      });
      if (response) {
        setProjectData(response.data);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessages = Object.values(error.response.data).join("\n ");
        toast.error(errorMessages);
      }
    }
  };

  async function getDO() {
    const user = await getUser();
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/organization/dsp `;

    try {
      const response = await api.get(apiUrl, {
        headers,
      });
      if (response) {
        setDoData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const mappedData = projectData.map(({ id, name }) => ({ id, name }));

  useEffect(() => {
    getProject();
    getDO();
    
  }, []);


  // useEffect(()=>{
  //   if (newQuoteDetails.project.id) {
  //     setComponentCompletion(true);
  //   }
  // }, [newQuoteDetails.project.id])

  return (
    <div className="form-head">
      <section class={`accordion ${validationCheck ? "no-selection" : ""}`}>
        <div class="tab">
          <input type="checkbox" name="accordion-1" id="cb1" checked />
          <label for="cb1" class="tab-label form-head quote-detail">
            <h2>{t('quoteDetails_quoteOverviewQuote')}</h2>
          </label>
          <div class="tab-content">
            <div class="quote-content">
              <div class="first-row">
                <div class="rows-content">
                  <label>{t('clientName_quoteOverviewQuote')}</label>
                  <input
                    className="form-control"
                    placeholder="Client Name 01"
                    value={newQuoteDetails.project.organization.name}
                    disabled
                    type="text"
                  />
                </div>
                <div class="rows-content">
                  <label>{t('projectName_quoteOverviewQuote')}</label>

                  <select
                    className="form-control"
                    type="text"
                    disabled
                    onChange={(e) => updateProject(e.target.value)}
                  >
                    <option>{newQuoteDetails.project.name}</option>
                    {mappedData.map((item) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div class="second-row">
                <div class="rows-content">
                  <label>{t('emailAddress_quoteOverviewQuote')}</label>
                  <input
                    className="form-control"
                    placeholder="abc@gmail.com"
                    value={newQuoteDetails.project.projectLeader.email}
                    disabled
                    type="text"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {validationCheck && (
        <div className="message">{t('pleaseFillFields_errorMessage')}</div>
      )}
    </div>
  );
};
