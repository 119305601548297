import React, { useState, useEffect } from "react";
import { getUser } from "../../../../helper/auth_helper";
import api from "../../../../helper/auth_interceptor";
import { useTranslation } from 'react-i18next';
import "./partnerAccordian.modules.css";
export const Partner = ({
    setNewQuoteDetails,
    newQuoteDetails,
    setComponentCompletion,
    validationCheck,
    quotePartners
}) => {
    const [dsp, setDsp] = useState([]);
    const [internalPartners, setInternalPartners] = useState([])
    const [inspectionPartners, setInspectionPartners] = useState([]);
    const checkOptionsInspection = { marginTop: "0px" }
    const checkOptionsInput = { top: "0px" }
    const { t } = useTranslation();
    const MainScanDivLabel ={
        paddingTop: "25px"
        }
        
        const MainScanDivTabContent ={
        padding: "0px 25px 10px 25px"
        }
        const MainScanDivLabelH2 ={
          paddingLeft:"25px"
          }

    async function getDSP() {
        const user = await getUser();
        const headers = {
            Authorization: `Bearer ${user.access_token}`,
        };
        const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/organization/dsp `;

        try {
            const response = await api.get(apiUrl, {
                headers,
            });
            if (response) {
                setDsp(response.data)
            }
        } catch (error) {
        }
    }
    async function getInspectionReportPartners() {
        const user = await getUser();
        const headers = {
            Authorization: `Bearer ${user.access_token}`,
        };
        const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/organization/inspection `;

        try {
            const response = await api.get(apiUrl, {
                headers,
            });
            if (response) {
                setInspectionPartners(response.data)
            }
        } catch (error) {
        }
    }
    async function getInternalPartners() {
        const user = await getUser();
        const headers = {
            Authorization: `Bearer ${user.access_token}`,
        };
        const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/organization/internal `;

        try {
            const response = await api.get(apiUrl, {
                headers,
            });
            if (response) {
                setInternalPartners(response.data)
            }
        } catch (error) {
        }
    }


    useEffect(() => {
        getDSP();
        getInspectionReportPartners();
        getInternalPartners();

    }, []);


    const [selectedDSP, setSelectedDSP] = useState([]);
    const [selectedInspection, setSelectedInspection] = useState([]);
    const [selectedInternal, setSelectedInternal] = useState([]);


    const handleCheckboxChange = (e, category) => {
        const { value, checked } = e.target;
        if (checked) {
            setComponentCompletion(true);
        }

        // Check if value is valid and a number
        const numericValue = !isNaN(value) ? Number(value) : null;

        if (numericValue !== null) {
            let updatedSelectedDSP = [...selectedDSP];
            let updatedSelectedInternal = [...selectedInternal];
            let updatedSelectedInspection = [...selectedInspection];

            switch (category) {
                case 'DSP':
                    updatedSelectedDSP = checked ? [...selectedDSP, numericValue] : selectedDSP.filter(item => item !== numericValue);
                    break;
                case 'Inspection':
                    updatedSelectedInspection = checked ? [...selectedInspection, numericValue] : selectedInspection.filter(item => item !== numericValue);
                    break;
                case 'Internal':
                    updatedSelectedInternal = checked ? [...selectedInternal, numericValue] : selectedInternal.filter(item => item !== numericValue);
                    break;
                default:
                    break;
            }

            setSelectedDSP(updatedSelectedDSP);
            setSelectedInternal(updatedSelectedInternal);
            setSelectedInspection(updatedSelectedInspection);

            // Setting values in setNewQuoteDetails
            setNewQuoteDetails(prevState => ({
                ...prevState,
                selectedDSP: updatedSelectedDSP,
                selectedInternal: updatedSelectedInternal,
                selectedInspection: updatedSelectedInspection
            }));
        } else {
            console.log('Invalid numeric value:', value);
        }
    };
    const scanType = newQuoteDetails.productType;
    if (newQuoteDetails?.quoteStatusType === 'QUOTE_FORWARDED') {
        setComponentCompletion(true)
}
    return (
        <div className="form-head">
            <section class={`accordion ${validationCheck ? 'no-selection' : ''}`}>
                {newQuoteDetails.quoteStatusType !== 'QUOTE_FORWARDED' && (
                    <div className="tab">
                        <input type="checkbox" name="accordion-1" id="cb3" checked />
                        <label for="cb3" className="tab-label form-head quote-detail select PartnerLabel" >
                            <h2 style={MainScanDivLabelH2}>{t('selectPartner_partnerAccord')}</h2>
                        </label>
                        <div class="tab-content" style={MainScanDivTabContent}>
                            {(scanType === 'OUTSIDE' || scanType === 'BOTH') && (
                                <div className="selected-partner">
                                    <div class="check-header">
                                        <div class="check-header-title">
                                            {/* <input type="checkbox" id="dsp" /> */}
                                            <label for="DSP" className="DSPLabel">{t('dsp_partnerAccord')}</label>
                                        </div>
                                    </div>
                                    <div class="check-options inspection" style={checkOptionsInspection}>
                                        {dsp?.map((item) => (
                                            <div class="check">
                                                <input type="checkbox" style={checkOptionsInput} value={item.id} onChange={(e) => handleCheckboxChange(e, 'DSP')} />
                                                <label for={item.organizationType}>{item.name}</label>
                                            </div>
                                        ))}

                                    </div>
                                </div>
                            )}
                            {(scanType === 'OUTSIDE' || scanType === 'BOTH') && (
                                <div>
                                    <div class="check-header">
                                        <div class="check-header-title">
                                            {/* <input type="checkbox" id="Inspection" /> */}
                                            <label for="Inspection" className="DSPLabel">{t('inpectionPartner_partnerAccord')}</label>
                                        </div>
                                    </div>
                                    <div class="check-options inspection" style={checkOptionsInspection}>
                                        {inspectionPartners?.map((item) => (
                                            <div class="check">
                                                <input type="checkbox" style={checkOptionsInput} value={item.id} onChange={(e) => handleCheckboxChange(e, 'Inspection')} />
                                                <label for={item.organizationType}>{item.name}</label>
                                            </div>
                                        ))}

                                    </div>
                                </div>
                            )}
                            {(scanType === 'INSIDE' || scanType === 'BOTH') && (
                                <div>
                                    <div class="check-header">
                                        <div class="check-header-title">
                                            {/* <input type="checkbox" id="internal" /> */}
                                            <label for="internal" className="DSPLabel">{t('internalPartner_partnerAccord')}</label>
                                        </div>
                                    </div>
                                    <div class="check-options inspection" style={checkOptionsInspection}>
                                        {internalPartners?.map((item) => (
                                            <div class="check">
                                                <input type="checkbox" style={checkOptionsInput} value={item.id} onChange={(e) => handleCheckboxChange(e, 'Internal')} />
                                                <label for={item.organizationType}>{item.name}</label>
                                            </div>
                                        ))}

                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                )}
                {newQuoteDetails.quoteStatusType === 'QUOTE_FORWARDED' && (
                    <div className="tab">
                        <input type="checkbox" name="accordion-1" id="cb3" />
                        <label htmlFor="cb3" className="tab-label form-head quote-detail" >
                            <h2 style={MainScanDivLabelH2}>{t('selectedPartner_partnerAccord')}</h2>
                        </label>
                        <div className="tab-content" style={MainScanDivTabContent}>
                            {quotePartners.map(partner => (
                                <div>
                                    <div class="check-header">
                                        <div class="check-header-title">
                                            <label for="internal">{partner.partnerType}</label>
                                        </div>
                                    </div>
                                    <div>
                                        <input type="checkbox" checked={true} />
                                        <label for={partner.orgName}>{partner.orgName}</label>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </section>
            {validationCheck && <div className="message">{t('pleaseChooseAtLeastOneField_errorMessage')}</div>}
        </div>
    )
}