import React, { useState, useEffect } from "react";
import "./outsideinspection.modules.css";
import { useTranslation } from 'react-i18next';
import { getUser } from "../../../../../helper/auth_helper";
import api from "../../../../../helper/auth_interceptor";
import { toast } from "react-toastify";
import Frame from "../../../../../images/Frame.png";
import secondaryDelete from "../../../../../images/secondaryDelete.png";


export const OutsideInspection = ({ newQuoteDetails, offerDetails }) => {
  const [data, setData] = useState([]);
  const [isAnyOptionSelected, setIsAnyOptionSelected] = useState(true);




  const inspectionData = newQuoteDetails.quoteOutsideInspections;

  const AlignRight={
    position:"relative",
    right: "15px"
  }
  const { t } = useTranslation();
  const getData = async () => {
    const user = await getUser();
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/quote/outsideInspection`;

    try {
      const response = await api.get(apiUrl, {
        headers,
      });
      if (response) {

        setData(response.data);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessages = Object.values(error.response.data).join("\n ");
        toast.error(errorMessages);
      }
    }
  }

  function calculateTotalCost(selectedOutsideInspectionOffer) {
    if (!selectedOutsideInspectionOffer) return 0;
  
    const totalCost = selectedOutsideInspectionOffer.outsideInspectionOffers.reduce((total, item) => total + item.cost, 0);
    const commissionPercentage = selectedOutsideInspectionOffer.commission || 0;
  
    const totalWithCommission = totalCost + commissionPercentage;
  
    return totalWithCommission.toFixed(2); // Adjust decimal places as needed
  }
  


  useEffect(() => {
    getData();
  }, [])


  return (
    <div className="form-head">
      <section class="accordion">
        <div class="tab">
          <input type="checkbox" name="accordion-1" id="cb5" />
          <label for="cb5" class="tab-label form-head quote-detail">
            <h2>{t('inspectionReportHead_outsideInspectionQuote')}</h2>
          </label>
          <div class="tab-content record">
            <div>
            
              <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-3">
                  <div class="model">
                    {data?.map((item) => (
                      <div className="outsideIteminputfields">
                        <input type="checkbox" className="outInspSectOffrInput" disabled checked={inspectionData.map((data) => data.outsideInspection.id).includes(item.id)} value={item.id} />
                        <label for={item.id}>{item.name}</label>
                      </div>
                    ))}

                  </div>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-9 tableDspEdit">
                  <div className="left-content">
                    <table className="dspTable">
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th style={AlignRight}>Price(€)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {offerDetails.selectedOutsideInspectionOffer && offerDetails.selectedOutsideInspectionOffer.outsideInspectionOffers.map((item, index) => (
                          <tr key={index}>
                            <td>{item.quoteOutsideInspection.outsideInspection.name}</td>
                            <td style={AlignRight}>{new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(item.cost)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <p className="commissionOutTag">{t('commisions_partnerAccord')} - {new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(offerDetails.selectedOutsideInspectionOffer && offerDetails.selectedOutsideInspectionOffer.commission)} </p>
                    <p className="commissionOutTag">{t('totalCost_outsideAccordian')} -  {new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(calculateTotalCost(offerDetails.selectedOutsideInspectionOffer))} ({t('commissionsIncluded_outsideAccordian')})</p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  )
}