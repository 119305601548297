import React, { useState, useEffect, useCallback } from "react";
import "./outsidePurpose.modules.css";
import api from "../../../../helper/auth_interceptor";
import { getUser } from "../../../../helper/auth_helper";
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";

export const OutsidePurpose = ({
  outsidePurpose,
  setOutsidePurpose,
  setComponentCompletion,
  otherPurposeText,
  setOtherPurposeText,
  validationCheck,
  setInspectionSelected,
  setCheckboxSelcted
}) => {
  //state and constant declarations
  const [data, setData] = useState([]);
  const inspectionData = data.filter((item) => item.id >= 1 && item.id <= 4);
  const renovationData = data.filter((item) => item.id >= 5 && item.id <= 8);
  const assetData = data.filter((item) => item.id >= 9 && item.id <= 12);
  const otherPurposeData = data.filter(
    (item) => item.id >= 13 && item.id <= 13
  );

  const [outSidePurposeCheckBox, setOutSidePurposeCheckBox] = useState({
    RENOVATION: false,
    INSPECTION: false,
    ASSET_MANAGEMENT: false,
    other: false
  })
  const { t } = useTranslation();
  const [textState, setTextState] = useState(false);
  const [reCheck, setReCheck] = useState(false)

  //function to handle vvalue change whenver an item is selected
  const handleValueChange = (item, key) => {
    setComponentCompletion(true);
    const keyData = outsidePurpose.filter((product) => product.outsidePurposeType === key)
    if (!keyData.length) {
      const updateData = data.filter((product) => product.outsidePurposeType === key && product.id === item.id)
      setOutsidePurpose(outsidePurpose.concat(updateData));
      setOutSidePurposeCheckBox(prev => {
        return {
          ...prev,
          [key]: !outSidePurposeCheckBox[key]
        }
      })
      isChecked(item, key)
    } else {
      const checkedData = outsidePurpose.filter((product) => product.outsidePurposeType === key && product.id === item.id)
      if (checkedData.length) {
        setOutsidePurpose(outsidePurpose.filter((product) => !checkedData.includes(product)));
        if (keyData.length == 1) {
          setOutSidePurposeCheckBox(prev => {
            return {
              ...prev,
              [key]: false
            }
          })
          isChecked(item, key)
          setComponentCompletion(false);
        }
      } else {
        const updateData = data.filter((product) => product.outsidePurposeType === key && product.id === item.id)
        setOutsidePurpose(outsidePurpose.concat(updateData));
      }
    }
    setReCheck(!reCheck)

  }
//function to handle value of selected item
  const handleFieldValueChange = (key) => {
    setOutSidePurposeCheckBox(prev => ({
      ...prev,
      [key]: !prev[key]
    }));

    let updatedProducts;
    if (!outSidePurposeCheckBox[key]) {
      const newDefaultValues = data.filter((product) => product.outsidePurposeType === key && product.defaultSelected === true);
      const oldValues = outsidePurpose.filter((item) => item.outsidePurposeType !== key || item.defaultSelected === false);
      updatedProducts = newDefaultValues.concat(oldValues);
    } else {
      updatedProducts = outsidePurpose.filter((product) => product.outsidePurposeType !== key);
    }

    setOutsidePurpose(updatedProducts);
    setInspectionSelected(key === "INSPECTION");
    setCheckboxSelcted(key === "INSPECTION");
    setReCheck(prev => !prev); 
    setComponentCompletion(updatedProducts.length > 0);
  }
//function to add text 
  const handleOtherPurposeChange = (event) => {
    const inputValue = event; // Access event target value

    setOtherPurposeText(inputValue);

    const text = "Others";

    const otherPurposeId = otherPurposeData[0]?.id; // Assuming there's only one other purpose item

    if (inputValue.trim() !== "") {
      // If input value is not empty, add other purpose item to the array if not already present
      if (!outsidePurpose.some((product) => product.id === otherPurposeId)) {
        const updatedProducts = [
          { id: otherPurposeId, name: text },
        ];
        setOutsidePurpose(outsidePurpose.concat(updatedProducts));
      }
    } else {
      // If input value is empty, remove other purpose item from the array if present
      const updatedProducts = outsidePurpose.filter(
        (product) => product.id !== otherPurposeId
      );
      setOutsidePurpose(outsidePurpose.concat(updatedProducts));
    }
  };

  const isChecked = useCallback((item, key) => {
    setInspectionSelected(outSidePurposeCheckBox.INSPECTION);

    if (outSidePurposeCheckBox[key]) {
      const checkedData = outsidePurpose.filter((product) => product.outsidePurposeType === key && product.id === item.id)
      return checkedData.length ? true : false
    } else {
      return false;
    }
  }, [reCheck]);

//useEffect to fetch data for outside purpose 
  useEffect(() => {
    const getData = async () => {
      const user = await getUser();
      const headers = {
        Authorization: `Bearer ${user.access_token}`,
      };
      const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/quote/outsidePurpose`;

      try {
        const response = await api.get(apiUrl, {
          headers,
        });
        if (response) {
          setData(response.data);
        }
      } catch (error) {
        if (error.response && error.response.data) {
          const errorMessages = Object.values(error.response.data).join("\n ");
          toast.error(errorMessages);
        }
      }
    };

    getData();
  }, []);

  return (
    <div className="form-head">
      <section class={`accordion ${validationCheck ? "no-selection" : ""}`}>
        <div class="tab">
          <input className="CheckInput" type="checkbox" name="accordion-1" id="cb4" />
          <label for="cb4" class="tab-label form-head quote-detail">
            <h2>{t('exteriorPurpose_outsidePurposeCreateQuote')}</h2>
          </label>
          <div class="tab-content record">
            <div>
              <div class="check-header">
                <div class="check-header-title">
                  <input
                    className="CheckInput"
                    type="checkbox"
                    id="Inspection"
                    checked={outSidePurposeCheckBox.INSPECTION}
                    onChange={() => handleFieldValueChange("INSPECTION")}
                  />
                  <label for="Inspection">{t('inspection_outsideInspectionQuote')}</label>
                </div>
              </div>
              <div class="check-options purpose">
                {inspectionData?.map((item) => (
                  <div class="check">
                    <input
                      className="CheckInput"
                      type="checkbox"
                      value={item.id}
                      checked={isChecked(item, "INSPECTION")}
                      onChange={() => handleValueChange(item, "INSPECTION")}
                    />
                    <label for={item.id}>{item.name}</label>
                  </div>
                ))}
              </div>
              {validationCheck && (
                <div className="message">{t('pleaseChooseAtLeastOneField_errorMessage')}</div>
              )}
            </div>

            <div>
              <div class="check-header">
                <div class="check-header-title">
                  <input
                    className="CheckInput"
                    type="checkbox"
                    id="Quantities"
                    checked={outSidePurposeCheckBox.RENOVATION}
                    onChange={() => handleFieldValueChange("RENOVATION")}

                  />
                  <label for="Quantities">{t('renovationSustainability_outsidePurposeCreateQuote')}</label>
                </div>
              </div>
              <div class="check-options purpose">
                {renovationData?.map((item) => (
                  <div class="check">
                    <input
                      className="CheckInput"
                      type="checkbox"
                      value={item.id}
                      checked={isChecked(item, "RENOVATION")}
                      onChange={() => handleValueChange(item, "RENOVATION")}
                    />
                    <label for={item.id}>{item.name}</label>
                  </div>
                ))}
              </div>
              {validationCheck && (
                <div className="message">{t('pleaseChooseAtLeastOneField_errorMessage')}</div>
              )}
            </div>

            <div>
              <div class="check-header">
                <div class="check-header-title">
                  <input
                    className="CheckInput"
                    type="checkbox"
                    id="Quantities"
                    checked={outSidePurposeCheckBox.ASSET_MANAGEMENT}
                    onChange={() => handleFieldValueChange("ASSET_MANAGEMENT")}

                  />
                  <label for="Quantities">{t('assetManagement_outsidePurposeCreateQuote')}</label>
                </div>
              </div>
              <div class="check-options purpose">
                {assetData?.map((item) => (
                  <div class="check">
                    <input
                      className="CheckInput"
                      type="checkbox"
                      value={item.id}
                      checked={isChecked(item, "ASSET_MANAGEMENT")}
                      onChange={() => handleValueChange(item, "ASSET_MANAGEMENT")}
                    />
                    <label for={item.id}>{item.name}</label>
                  </div>
                ))}
              </div>
              {validationCheck && (
                <div className="message">{t('pleaseChooseAtLeastOneField_errorMessage')}</div>
              )}
            </div>

            <div>
              <div class="check-header">
                <div class="check-header-title">
                  <input
                    className="CheckInput"
                    type="checkbox"
                    id="Quantities"
                    onChange={() => setTextState(true)}
                  />
                  <label for="Quantities">{t('Others_outsidePurposeCreateQuote')}</label>
                </div>

                {textState ? (
                  <input

                    type="text"
                    value={otherPurposeText}
                    onChange={(e) => {
                      handleOtherPurposeChange(e.target.value);
                    }}
                    className="form-control"
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
      {validationCheck && (
        <div className="message">{t('outsidePurpose_errorMessage')}
        </div>
      )}
    </div>
  );
};
