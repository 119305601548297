import React, { useEffect, useState } from "react";
import api from "../../helper/auth_interceptor";
import { useTranslation } from "react-i18next";
import "./QuoteCreation.modules.css";
import { Circles } from "react-loader-spinner";
import home from "../../images/home.png";
import rightArrow from "../../images/right-arrow.png";
import { getUser } from "../../helper/auth_helper";
import { useNavigate } from "react-router-dom";
import { ProgressBar } from "./components/progressBar/progressBar";
import { QuoteDetails } from "./components/quoteAccordian/quoteAccordian";
import { ScanOption } from "./components/scanOptionAccordian/scanOptionAccordian";
import { Outside } from "./components/outsideAccordian/outsideAccordian";
import { OutsidePurpose } from "./components/outsidePurposeAccordian/outsidePurpose";
import { OutsideInspection } from "./components/outsideInspectionAccordian/outsideInspection";
import { OutsideExtrasAccord } from "./components/outsideExtrasAccordian/outsideExtras";
import { InsideServiceAccord } from "./components/insideServiceAccordian/insideService";
import { InsideProductAccord } from "./components/insideProductAccordian/insideProduct";
import { Planning } from "./components/planning/planning";
import hamburger from "../../images/hamburger.jpg";
import { ProtectedSidebar } from "../sidebar/protectedSidebar";
import QuoteOverviewModel from "./components/quoteOverview/QuoteOverviewModel";
import { toast } from "react-toastify";

const defaultQuoteDetails = {
  clientName: "",
  contact: "",
  projectId: "",
};

const defaultPlanning = {
  desiredDeliveryDateFrom: "",
  desiredDeliveryDateTo: "",
};

const defaultScanOption = {
  productType: "",
};

function QuoteCreation({ suprmenAdmin, orgAdmin, viewer, regular }) {
 //states and constant declaration

  const navigate = useNavigate();
  const [project, setProject] = useState([]);
  const [inpectionSelected, setInpectionSelected] = useState(false);
  const [quoteDetails, setQuoteDetails] = useState(defaultQuoteDetails);
  const [planningDetails, setPlanningDetails] = useState(defaultPlanning);
  const [scanOption, setScanOption] = useState(defaultScanOption);
  const [outsideProduct, setOutsideProduct] = useState([]);
  const [insideServices, setInsideServices] = useState([]);
  const [insideProduct, setInsideProduct] = useState([]);
  const [outsideInspection, setOutsideInspection] = useState([]);
  const [outsidePurpose, setOutsidePurpose] = useState([]);
  const [outsideExtras, setOutsideExtras] = useState([]);
  const [otherPurposeText, setOtherPurposeText] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [checkboxSelected, setCheckboxSelcted] = useState(false);
  const [validationCheckInsideProduct, setValidationCheckInsideProduct] =
    useState(false);
  const [validationCheckQuoteDetails, setValidationCheckQuoteDetails] =
    useState(false);
  const [validationCheckInsideServices, setValidationCheckInsideServices] =
    useState(false);
  const [validationCheckOutside, setValidationCheckOutside] = useState(false);
  const [validationCheckOutsidePurpose, setValidationCheckOutsidePurpose] =
    useState(false);
  const [validationCheckOutsideInsp, setValidationCheckOutsideInsp] =
    useState(false);
  const [validationCheckOutsideExtras, setValidationCheckOutsideExtras] =
    useState(false);
  const [validationCheckPlanning, setValidationCheckPlanning] = useState(false);
  const [validationCheckScan, setValidationCheckScan] = useState(false);
  const [validationCheck, setValidationCheck] = useState(false);
  const [componentCompletion, setComponentCompletion] = useState({
    quoteDetails: false,
    scanOption: false,
    outside: false,
    outsidePurpose: false,
    outsideInspection: false,
    outsideExtras: false,
    insideService: false,
    insideProduct: false,
    planning: false,
  });

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);

  const MainScanDiv = { padding: "0px" };

  //fetch project api function

  async function FetchProject() {
    const user = await getUser();

    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/project-management/api/v1/project`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try {
      const response = await api.get(apiUrl, { headers });
      setProject(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  const handleShowModal = () => {
    setShowModal(true);
  };
  useEffect(() => {
    FetchProject();
  }, []);

  //useEffect to check for validations
  useEffect(() => {
    if (componentCompletion.quoteDetails) {
      setValidationCheckQuoteDetails(false);
    }

    if (componentCompletion.scanOption) {
      setValidationCheckScan(false);
    }

    if (componentCompletion.insideProduct) {
      setValidationCheckInsideProduct(false);
    }
    if (componentCompletion.insideService) {
      setValidationCheckInsideServices(false);
    }
    if (componentCompletion.outside) {
      setValidationCheckOutside(false);
    }
    if (componentCompletion.outsidePurpose) {
      setValidationCheckOutsidePurpose(false);
    }
    if (componentCompletion.outsideExtras) {
      setValidationCheckOutsideExtras(false);
    }
    if (componentCompletion.outsideInspection) {
      setValidationCheckOutsideInsp(false);
    }
    FetchProject();
  }, [componentCompletion]);


  //function to handle validations
  const handleSubmitValidation = async (isDraft) => {
    if (!quoteDetails.projectId && !scanOption.productType) {
      setValidationCheckQuoteDetails(true);
      setValidationCheckScan(true);
      setLoading(false);
      return;
    }
    if (!quoteDetails.projectId) {
      setValidationCheckQuoteDetails(true);
      setLoading(false);
      return;
    }
    if (!scanOption.productType) {
      setValidationCheckScan(true);
      setLoading(false);
      return;
    }
    setValidationCheckQuoteDetails(false);
    setValidationCheckScan(false);
    setLoading(false);

    let requiredComponents = [];
    let incompleteComponents = [];
    let scanOptionLabel = "";
    if (!isDraft) {
      if (scanOption.productType === "INSIDE") {
        requiredComponents = [
          "quoteDetails",
          "insideServices",
          "insideProduct",
          "planning",
        ];
        scanOptionLabel = "Scan Option (Inside)";
      } else if (scanOption.productType === "OUTSIDE") {
        requiredComponents = [
          "quoteDetails",
          "outside",
          "outsidePurpose",

          "outsideExtras",
          "planning",
        ];
        scanOptionLabel = "Scan Option (Outside)";
      } else if (scanOption.productType === "BOTH") {
        requiredComponents = [
          "quoteDetails",
          "outside",
          "outsidePurpose",

          "outsideExtras",
          "insideServices",
          "insideProduct",
          "planning",
        ];
        scanOptionLabel = "Scan Option (Both)";
      }
    }

    incompleteComponents = requiredComponents.filter(
      (component) => !componentCompletion[component]
    );

    if (incompleteComponents.length > 0) {
      setValidationCheck(true);
      let errorMessage = "Please complete the following components:\n";
      incompleteComponents.forEach((component) => {
        switch (component) {
          case "quoteDetails":
            errorMessage += "- Quote Details\n";
            setValidationCheckQuoteDetails(true);
            break;
          case "outside":
            errorMessage += "- Outside\n";
            setValidationCheckOutside(true);
            break;
          case "outsidePurpose":
            errorMessage += "- Outside Purpose\n";
            setValidationCheckOutsidePurpose(true);
            break;
          case "outsideInspection":
            errorMessage += "- Outside Inspection Report\n";
            setValidationCheckOutsideInsp(true);
            break;
          case "outsideExtras":
            errorMessage += "- Outside Extras\n";
            setValidationCheckOutsideExtras(true);
            break;
          case "insideServices":
            errorMessage += "- Inside Service\n";
            setValidationCheckInsideServices(true);
            break;
          case "insideProduct":
            errorMessage += "- Inside Product\n";
            setValidationCheckInsideProduct(true);
            break;
          default:
            break;
        }
      });

      setLoading(false);
      return;
    }

    if (isDraft == true) {
      handleSubmit(true);
    } else {
      handleShowModal();
    }
  };

  //main submit fumction to submit all the data
  const handleSubmit = async (isDraft) => {
    setLoading(true);
    const requestBody = {
      clientName: quoteDetails.clientName,
      contact: quoteDetails.contact,
      productType: scanOption.productType,
      projectId: quoteDetails.projectId,
      quoteOutsideProducts: outsideProduct.map((item) => item.id),
      quoteOutsidePurposes: outsidePurpose.map((item) => item.id),
      quoteOutsideExtraServices: outsideExtras.map((item) => item.id),
      quoteOutsideInspections: outsideInspection.map((item) => item.id),
      quoteInsideProducts: insideProduct.map((item) => item.id),
      quoteInsideServices: insideServices.map((item) => item.id),
      otherPurpose: otherPurposeText,
      draft: isDraft,
    };

    if (suprmenAdmin) {
      requestBody.dspOrgId = quoteDetails.dspOrgId;
    }

    if (suprmenAdmin) {
      requestBody.dspOrgId = quoteDetails.dspOrgId;
    }

    setLoading(true);

    try {
      const user = await getUser();
      const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/quote`;
      const headers = {
        Authorization: `Bearer ${user.access_token}`,
      };

      await api.post(apiUrl, requestBody, { headers });
      navigate("/quoteview");
      toast.success(`${t("quoteUpdated")}`);
    } catch (error) {
      console.error("Error updating quote:", error);
    } finally {
      setLoading(false);
    }
  };
//validation for draft 
  const draftSetting = () => {
    handleSubmitValidation(true);
  };

  const selectedProject = project?.filter(
    (project) => project?.id == quoteDetails?.projectId
  )[0];
  return (
    <div className={`main-wrap ${loading ? "blur" : ""}`}>
      {loading && (
        <div className="loader-overlay">
          <Circles type="TailSpin" color="#FF6B00" height={150} width={100} />
        </div>
      )}
      <div className="quote-body">
        <input id="slide-sidebar" type="checkbox" role="button" />
        <label for="slide-sidebar">
          <span>
            <i class="fa fa-bars">
              <img class="sidebar-toggle" src={hamburger}></img>
            </i>
          </span>
        </label>
        <div class="sidebar">
          <div class="sidebar-content">
            <div>
              <ProtectedSidebar />
            </div>
          </div>
        </div>
        <div class="portfolio">
          <div class="main-content">
            <div class="main-header-content">
              <div className="pageHeader">
                <h1>{t("quoteCreation_quoteCreation")}</h1>
              </div>
              <div class="user">
                <button
                  className="btn btn-light quoteBtns rqstbtn "
                  type="button"
                >
                  <img src={home} alt="home icon" />
                  <img src={rightArrow} alt="right arrow icon" />
                  <span>{t("requestForQuotes_quoteCreation")}</span>
                </button>
              </div>
            </div>
            <div>
              <div className="page-body">
                <div className="formDivquote">
                  <div class="timebar">
                    <ProgressBar
                      componentCompletion={componentCompletion}
                      scanOption={scanOption}
                      inpectionSelected={inpectionSelected}
                    />
                  </div>
                  <div class="main-record withTimebar">
                    <div class="quote-details-record">
                      <div class="quote-details">
                        <QuoteDetails
                          quoteDetails={quoteDetails}
                          setQuoteDetails={(quoteDetails) =>
                            setQuoteDetails(quoteDetails)
                          }
                          suprmenAdmin={suprmenAdmin}
                          validationCheck={validationCheckQuoteDetails}
                          setComponentCompletion={(status) =>
                            setComponentCompletion({
                              ...componentCompletion,
                              quoteDetails: status,
                              planning: status,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div class="quote-scan-record " style={MainScanDiv}>
                      <div class="quote-details">
                        <ScanOption
                          scanOption={scanOption}
                          setScanOption={(scanOption) =>
                            setScanOption(scanOption)
                          }
                          validationCheck={validationCheckScan}
                          setComponentCompletion={(status) =>
                            setComponentCompletion({
                              ...componentCompletion,
                              scanOption: status,
                            })
                          }
                        />
                      </div>
                    </div>
                    {(scanOption.productType === "OUTSIDE" ||
                      scanOption.productType === "BOTH") && (
                      <>
                        <div class="quote-outside">
                          {/* component for outside tab */}
                          <div class="quote-details">
                            <Outside
                              outsideProduct={outsideProduct}
                              setOutsideProduct={(outsideProduct) =>
                                setOutsideProduct(outsideProduct)
                              }
                              validationCheck={validationCheckOutside}
                              setComponentCompletion={(status) =>
                                setComponentCompletion({
                                  ...componentCompletion,
                                  outside: status,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div class="quote-option-record">
                          <div class="quote-details">
                            {/* component for outside purpose tab */}
                            <OutsidePurpose
                              outsidePurpose={outsidePurpose}
                              setOutsidePurpose={(outsidePurpose) =>
                                setOutsidePurpose(outsidePurpose)
                              }
                              setInspectionSelected={(inspectionSelected) =>
                                setInpectionSelected(inspectionSelected)
                              }
                              validationCheck={validationCheckOutsidePurpose}
                              otherPurposeText={otherPurposeText}
                              setOtherPurposeText={(otherPurposeText) =>
                                setOtherPurposeText(otherPurposeText)
                              }
                              setCheckboxSelcted={(checkboxSelected) =>
                                setCheckboxSelcted(checkboxSelected)
                              }
                              setComponentCompletion={(status) =>
                                setComponentCompletion({
                                  ...componentCompletion,
                                  outsidePurpose: status,
                                })
                              }
                            />
                          </div>
                        </div>

                        <div class="quote-outside-extras-record">
                          <div class="quote-details">
                            {/* component for outside extras tab */}
                            <OutsideExtrasAccord
                              outsideExtras={outsideExtras}
                              setOutsideExtras={(outsideExtras) =>
                                setOutsideExtras(outsideExtras)
                              }
                              validationCheck={validationCheckOutsideExtras}
                              setComponentCompletion={(status) =>
                                setComponentCompletion({
                                  ...componentCompletion,
                                  outsideExtras: status,
                                })
                              }
                            />
                          </div>
                        </div>
                      </>
                    )}

                    {inpectionSelected ? (
                      <div class="quote-outside-record">
                        <div class="quote-details">
                          {/* component for outside inspection tab */}
                          <OutsideInspection
                            outsideInspection={outsideInspection}
                            setOutsideInspection={(outsideInspection) =>
                              setOutsideInspection(outsideInspection)
                            }
                            checkboxSelected={checkboxSelected}
                            validationCheck={validationCheckOutsideInsp}
                            setComponentCompletion={(status) =>
                              setComponentCompletion({
                                ...componentCompletion,
                                outsideInspection: status,
                              })
                            }
                          />
                        </div>
                      </div>
                    ) : null}

                    {(scanOption.productType === "INSIDE" ||
                      scanOption.productType === "BOTH") && (
                      <>
                        <div class="quote-inside-summary-record">
                          <div class="quote-details">
                            {/* component for inside product tab */}
                            <InsideProductAccord
                              insideProduct={insideProduct}
                              setInsideProduct={(insideProduct) =>
                                setInsideProduct(insideProduct)
                              }
                              validationCheck={validationCheckInsideProduct}
                              setComponentCompletion={(status) =>
                                setComponentCompletion({
                                  ...componentCompletion,
                                  insideProduct: status,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div class="quote-inside-services-record">
                          <div class="quote-details">
                            {/* component for inside service tab */}
                            <InsideServiceAccord
                              insideServices={insideServices}
                              setInsideServices={(insideServices) =>
                                setInsideServices(insideServices)
                              }
                              validationCheck={validationCheckInsideServices}
                              setComponentCompletion={(status) =>
                                setComponentCompletion({
                                  ...componentCompletion,
                                  insideServices: status,
                                })
                              }
                            />
                          </div>
                        </div>
                      </>
                    )}
                    <div class="quote-planning-record ">
                      <div class="quote-details">
                        {/* component for planning tab */}
                        <Planning
                          planningDetails={planningDetails}
                          setPlanningDetails={(planningDetails) =>
                            setPlanningDetails(planningDetails)
                          }
                          validationCheck={validationCheckPlanning}
                          projectEndDate={selectedProject?.endDate}
                        />
                      </div>
                    </div>
                    <div class="bottom-button right">
                      <button
                        className="btn-cancel"
                        onClick={draftSetting}
                        type="submit"
                      >
                        <span>{t("saveAsDraft_quoteCreation")}</span>
                      </button>
                      <button
                        class="btn-cancel"
                        disabled=""
                        onClick={() => navigate("/quoteview")}
                      >
                        <span>{t("cancel")}</span>
                      </button>
                      <button
                        className="btn-save"
                        onClick={() => {
                          handleSubmitValidation(false);
                        }}
                      >
                        <span>{t("submit")}</span>
                      </button>

                      <div>
                        <QuoteOverviewModel
                          show={showModal}
                          handleClose={handleCloseModal}
                          quoteDetails={quoteDetails}
                          scanOption={scanOption}
                          planningDetails={planningDetails}
                          outsideProduct={outsideProduct}
                          insideProduct={insideProduct}
                          insideServices={insideServices}
                          outsidePurpose={outsidePurpose}
                          outsideExtras={outsideExtras}
                          outsideInspection={outsideInspection}
                          otherPurposeText={otherPurposeText}
                          handleSubmit={handleSubmit}
                          inpectionSelected={inpectionSelected}
                          validationCheck={validationCheck}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuoteCreation;
