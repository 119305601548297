import React, {useState, useEffect} from "react";
import "./outsideinspection.modules.css";
import { useTranslation } from 'react-i18next';
import { getUser } from "../../../../helper/auth_helper";
import api from "../../../../helper/auth_interceptor";
import { toast } from "react-toastify";


export const OutsideInspection = ({setNewQuoteDetails, newQuoteDetails,setComponentCompletion, setInspectionCheck, validationCheck, isDraft}) =>{
   const [data, setData] = useState([]);
   const [isAnyOptionSelected, setIsAnyOptionSelected] = useState(false);
   const checkOptionsInput = { top: "0px" }
   const { t } = useTranslation();
   const handleValueChange = (selectedId) => {

      const isIdSelected = newQuoteDetails.quoteOutsideInspections.some((item) => item.outsideInspection.id === selectedId);
      let updatedProducts;
    
      if (isIdSelected) {
      
        updatedProducts = newQuoteDetails.quoteOutsideInspections.filter((item) => item.outsideInspection.id !== selectedId);
        
      } else {
      
        const selectedProduct = data.find((item) => item.id === selectedId);
        updatedProducts = [...newQuoteDetails.quoteOutsideInspections, { outsideInspection: selectedProduct }];
      }
      
      const updatedQuoteDetails = { ...newQuoteDetails, quoteOutsideInspections: updatedProducts };
      setNewQuoteDetails(updatedQuoteDetails);
      setIsAnyOptionSelected(updatedProducts.length > 0);
      setInspectionCheck(updatedProducts.length > 0);
    
      return updatedQuoteDetails;
    };

    const inspectionData = newQuoteDetails.quoteOutsideInspections;
    const backendIds=inspectionData.map((data) => data.outsideInspection.id);
    if(backendIds){
      setInspectionCheck(true);
    }
    
   const getData= async()=>{
      const user = await getUser();
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/quote/outsideInspection`;

    try {
      const response = await api.get(apiUrl, {
        headers,
      });
      if (response) {
        
        setData(response.data);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessages = Object.values(error.response.data).join("\n ");
        toast.error(errorMessages);
      }
    }
   }

   const accordionClass = isAnyOptionSelected ? 'accordion' : 'accordionerror';


   useEffect(()=>{
      getData();
              
   },[])
   useEffect(()=>{
      setIsAnyOptionSelected(backendIds.length>0);  
   },[backendIds])
    return(
        <div className="form-head">
        <section className={!isDraft && accordionClass}>
           <div class="tab">
              <input type="checkbox" name="accordion-1" id="cb6"/>
              <label for="cb6" class="tab-label form-head quote-detail">
                 <h2>{t('inspectionReportHead_outsideInspectionQuote')}</h2>
              </label>
              <div class="tab-content record">
              <div>
                  <div class="check-header">
                     <div class="check-header-title">
                        <input type="checkbox" style={checkOptionsInput} id="Inspection" checked={isAnyOptionSelected} />
                        <label for="Inspection">{t('inspection_outsideInspectionQuote')}</label>
                     </div>
                  </div>
                  <div class="check-options inspection">
                     {data?.map((item)=>(
                        <div class="check">
                        <input type="checkbox"  style={checkOptionsInput} checked={inspectionData.map((data) => data.outsideInspection.id).includes(item.id)} value={item.id} onChange={() => handleValueChange(item.id)} />
                        <label for={item.id}>{item.name}</label>
                     </div>
                     ))}
                                      
                  </div>
               </div>
              </div>
           </div>
        </section>
        {!isDraft && !isAnyOptionSelected && <div className="message">{t('pleaseChooseAtLeastOneField_errorMessage')}</div>}
     </div>
    )
}