import React, { useState, useEffect } from "react";
import "./outsidePurpose.modules.css";
import { useTranslation } from 'react-i18next';
import api from "../../../../helper/auth_interceptor";
import { getUser } from "../../../../helper/auth_helper";
import { toast } from "react-toastify";

export const OutsidePurpose = ({
  setNewQuoteDetails,
  newQuoteDetails,
  setComponentCompletion,
  otherPurposeText,
  setOtherPurposeText,
  validationCheck,
  setInspectionSelected,
  isDraft
}) => {
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const inspectionData = data.filter((item) => item.id >= 1 && item.id <= 4);
  const renovationData = data.filter((item) => item.id >= 5 && item.id <= 8);
  const assetData = data.filter((item) => item.id >= 9 && item.id <= 12);
  const otherPurposeData = data.filter(
    (item) => item.id >= 13 && item.id <= 13
  );
  const [isAnyOptionSelected, setIsAnyOptionSelected] = useState(false);
  const [isInspectionSelected, setIsInspectionSelected] = useState(false);
  const [isRenovationSelected, setIsRenovationSelected] = useState(false);
  const [isAssetManagementSelected, setIsAssetManagementSelected] =
    useState(false);
    
  const [textState, setTextState] = useState(false);
  const checkOptionsInput = { top: "0px" }
  const handleValueChange = (selectedId, category) => {
    const isIdSelected = newQuoteDetails.quoteOutsidePurposes.some(
      (item) => item.outsidePurpose.id === selectedId
    );
    let updatedProducts;

    if (isIdSelected) {
      updatedProducts = newQuoteDetails.quoteOutsidePurposes.filter(
        (item) => item.outsidePurpose.id !== selectedId
      );
    } else {
      const selectedProduct = data.find((item) => item.id === selectedId);
      updatedProducts = [
        ...newQuoteDetails.quoteOutsidePurposes,
        { outsidePurpose: selectedProduct },
      ];
    }

    const updatedQuoteDetails = {
      ...newQuoteDetails,
      quoteOutsidePurposes: updatedProducts,
    };

    setNewQuoteDetails(updatedQuoteDetails);
    
    setComponentCompletion(updatedProducts.length > 0);

    const isInspectionSelected = updatedQuoteDetails.quoteOutsidePurposes.some(
      (item) =>
        inspectionData.findIndex(
          (data) => data.id === item.outsidePurpose.id
        ) !== -1
    );

    setIsInspectionSelected(isInspectionSelected);
    setInspectionSelected(isInspectionSelected);

    const isRenovationSelected = updatedQuoteDetails.quoteOutsidePurposes.some(
      (item) =>
        renovationData.findIndex(
          (data) => data.id === item.outsidePurpose.id
        ) !== -1
    );
    setIsRenovationSelected(isRenovationSelected);
    const isAssetManagementSelected =
      updatedQuoteDetails.quoteOutsidePurposes.some(
        (item) =>
          assetData.findIndex((data) => data.id === item.outsidePurpose.id) !==
          -1
      );

    setIsAssetManagementSelected(isAssetManagementSelected);

    const anyCategorySelected =
      isInspectionSelected || isRenovationSelected || isAssetManagementSelected;
    setComponentCompletion(anyCategorySelected);

    return updatedQuoteDetails;
  };

  const inspectionDataItem = newQuoteDetails.quoteOutsidePurposes
    .filter(
      (item) => item.outsidePurpose.id >= 1 && item.outsidePurpose.id <= 4
    )
    .map((item) => item.outsidePurpose.id);
  const renovationDataItem = newQuoteDetails.quoteOutsidePurposes
    .filter(
      (item) => item.outsidePurpose.id >= 5 && item.outsidePurpose.id <= 8
    )
    .map((item) => item.outsidePurpose.id);
  const assetDataItem = newQuoteDetails.quoteOutsidePurposes
    .filter(
      (item) => item.outsidePurpose.id >= 9 && item.outsidePurpose.id <= 12
    )
    .map((item) => item.outsidePurpose.id);

  

  const otherPurposeTextItem = newQuoteDetails.quoteOutsidePurposes.find(
    (item) => item.outsidePurpose.id === 13
  )?.otherPurpose;

  if(inspectionDataItem>0 || renovationDataItem> 0 || assetDataItem> 0 || otherPurposeTextItem>0){
    setComponentCompletion(true);
  }
  

  const handleOtherPurposeChange = (event) => {
    const inputValue = event;
    const otherPurposeId = 13;
    const updatedProducts = [...newQuoteDetails.quoteOutsidePurposes];

    const otherPurposeIndex = updatedProducts.findIndex(
      (item) => item.outsidePurpose.id === otherPurposeId
    );

    if (inputValue.trim() !== "") {
      if (otherPurposeIndex !== -1) {
        updatedProducts[otherPurposeIndex] = {
          ...updatedProducts[otherPurposeIndex],
          otherPurpose: inputValue,
        };
      } else {
        updatedProducts.push({
          outsidePurpose: { id: otherPurposeId },
          otherPurpose: inputValue,
        });
      }
    } else {
      if (otherPurposeIndex !== -1) {
        updatedProducts.splice(otherPurposeIndex, 1);
      }
    }

    const updatedQuoteDetails = {
      ...newQuoteDetails,
      quoteOutsidePurposes: updatedProducts,
    };
    setNewQuoteDetails(updatedQuoteDetails);

    setComponentCompletion(updatedProducts.length > 0);
  };

  const getData = async () => {
    const user = await getUser();
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/quote/outsidePurpose`;

    try {
      const response = await api.get(apiUrl, {
        headers,
      });
      if (response) {
        setData(response.data);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessages = Object.values(error.response.data).join("\n ");
        toast.error(errorMessages);
      }
    }
  };

  const accordionClass = isAnyOptionSelected ? 'accordion' : 'accordionerror';

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setIsInspectionSelected(inspectionDataItem.length > 0);
    setIsRenovationSelected(renovationDataItem.length > 0);
    setIsAssetManagementSelected(assetDataItem.length > 0);
    setIsAnyOptionSelected(inspectionDataItem.length > 0||renovationDataItem.length > 0||assetDataItem.length > 0)
    setInspectionSelected(inspectionDataItem.length > 0);

  }, [inspectionDataItem, renovationDataItem, assetDataItem]);

  

  return (
    <div className="form-head">
      <section className={!isDraft ? accordionClass : ''}>
        <div class="tab">
          <input className="CheckInput" type="checkbox" name="accordion-1" id="cb5" />
          <label for="cb5" class="tab-label form-head quote-detail">
            <h2>{t('exteriorPurpose_outsidePurposeCreateQuote')}</h2>
          </label>
          <div class="tab-content record">
            <div>
              <div class="check-header">
                <div class="check-header-title">
                  <input
                  className="CheckInput"
                    type="checkbox"
                    id="Inspection"
                    checked={isInspectionSelected}
                  />
                  <label for="Inspection">{t('inspection_outsideInspectionQuote')}</label>
                </div>
              </div>
              <div class="check-options purpose">
                {inspectionData?.map((item) => (
                  <div class="check">
                    <input
                    className="CheckInput"
                      type="checkbox"
                      checked={inspectionDataItem.includes(item.id)}
                      value={item.id}
                      onChange={() => handleValueChange(item.id, "Inspection")}
                    />
                    <label for={item.id}>{item.name}</label>
                  </div>
                ))}
              </div>
              {!isDraft && !isAnyOptionSelected && (
                <div className="message">{t('pleaseChooseAtLeastOneField_errorMessage')}</div>
              )}
            </div>

            <div>
              <div class="check-header">
                <div class="check-header-title">
                  <input
                  className="CheckInput"
                    type="checkbox"
                    id="Quantities"
                    checked={isRenovationSelected}
                  />
                  <label for="Quantities">{t('renovationSustainability_outsidePurposeCreateQuote')}</label>
                </div>
              </div>
              <div class="check-options purpose">
                {renovationData?.map((item) => (
                  <div class="check">
                    <input
                    className="CheckInput"
                      type="checkbox"
                      checked={renovationDataItem.includes(item.id)}
                      value={item.id}
                      onChange={() => handleValueChange(item.id, "Renovation")}
                    />
                    <label for={item.id}>{item.name}</label>
                  </div>
                ))}
              </div>
              {!isDraft && !isAnyOptionSelected && (
                <div className="message">{t('pleaseChooseAtLeastOneField_errorMessage')}</div>
              )}
            </div>

            <div>
              <div class="check-header">
                <div class="check-header-title">
                  <input
                  className="CheckInput"
                    type="checkbox"
                    id="Quantities"
                    checked={isAssetManagementSelected}
                  />
                  <label for="Quantities">{t('assetManagement_outsidePurposeCreateQuote')}</label>
                </div>
              </div>
              <div class="check-options purpose">
                {assetData?.map((item) => (
                  <div class="check">
                    <input
                    className="CheckInput"
                      type="checkbox"
                      checked={assetDataItem.includes(item.id)}
                      value={item.id}
                      onChange={() =>
                        handleValueChange(item.id, "AssetManagement")
                      }
                    />
                    <label for={item.id}>{item.name}</label>
                  </div>
                ))}
              </div>
              {!isDraft && !isAnyOptionSelected && (
                <div className="message">{t('pleaseChooseAtLeastOneField_errorMessage')}</div>
              )}
            </div>

            <div>
              <div class="check-header">
                {otherPurposeTextItem ? (
                  <>
                    <div class="check-header-title">
                      <input
                      style={checkOptionsInput}
                        type="checkbox"
                        checked={otherPurposeTextItem}
                        id="Quantities"
                      />
                      <label for="Quantities">{t('Others_outsidePurposeCreateQuote')}</label>
                    </div>
                    <input
                    
                      type="text"
                      value={otherPurposeTextItem}
                      onChange={(e) => {
                        handleOtherPurposeChange(e.target.value);
                      }}
                      className="form-control"
                    />
                  </>
                ) : (
                  <>
                    <div class="check-header-title">
                      <input
                       className="CheckInputOthers"
                        type="checkbox"
                        id="Quantities"
                        onChange={() => setTextState(true)}
                      />
                      <label for="Quantities">{t('Others_outsidePurposeCreateQuote')}</label>
                    </div>
                    {textState ? (
                      <input
                      
                        type="text"
                        value={otherPurposeTextItem}
                        onChange={(e) => {
                          handleOtherPurposeChange(e.target.value);
                        }}
                        className="form-control"
                      />
                    ) : null}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {!isDraft && !isAnyOptionSelected && (
        <div className="message">
         {t('outsidePurpose_errorMessage')}
        </div>
      )}
    </div>
  );
};
