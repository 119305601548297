import React, { useState, useEffect } from "react";
import "./outsideinspection.modules.css";
import { useTranslation } from 'react-i18next';
import { getUser } from "../../../../../helper/auth_helper";
import api from "../../../../../helper/auth_interceptor";
import { toast } from "react-toastify";
import Frame from "../../../../../images/Frame.png";
import secondaryDelete from "../../../../../images/secondaryDelete.png";
import CurrencyInput from 'react-currency-input-field';

export const OutsideInspection = ({
  outsideInspection,
  setOutsideInspection,
  partnerStatusList,
  setComponentCompletion,
  validationCheck,
  nullItems,
  setNewQuoteDetails,
  newQuoteDetails,
  setOutsideInspectionTotal,
  collectDataInspection,
}) => {
  const [data, setData] = useState([]);
  const [isAnyOptionSelected, setIsAnyOptionSelected] = useState(false);
  const [typing, setTyping] = useState(false);
  const [checkedRows, setCheckedRows] = useState([]);
  const [uncheckedRows, setUncheckedRows] = useState([
    { product: "", price: "" },
  ]);
  const { t } = useTranslation();
  const inspectionData = newQuoteDetails.quoteOutsideInspections;
  const RightAlign = {textAlign: "right" }
  const handleSubmit = () => {
    const allRowsData = {};

    checkedRows.forEach((row) => {
      const product = row.product || null;
      const price = row.price !== "" ? Number(row.price) : null;
      allRowsData[product] = price;
    });

    uncheckedRows.forEach((row) => {
      if (row.product.trim() !== "" && row.price.trim() !== "") {
        // Check if both product and price are not empty
        allRowsData[row.product] = Number(row.price);
      }
    });

   
    collectDataInspection(allRowsData);
  };

  const getTotalPrice = () => {
    let totalPrice = 0;

    checkedRows.forEach((row) => {
      totalPrice += Number(row.price);
    });

    uncheckedRows.forEach((row) => {
      totalPrice += Number(row.price);
    });
    setOutsideInspectionTotal(totalPrice);

    return totalPrice;
  };

 

  const getData = async () => {
    const user = await getUser();
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/quote/outsideInspection`;

    try {
      const response = await api.get(apiUrl, {
        headers,
      });
      if (response) {
        setData(response.data);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessages = Object.values(error.response.data).join("\n ");
        toast.error(errorMessages);
      }
    }
  };

  const handleInputChange = (event, index) => {
    const { name, value } = event.target;
    if (name === "price") {
   
      const cleanValue = value.replace(/[^\d,]/g, "").replace(/,/g, ".");
    
      // Check if the value is not negative
      if (!isNaN(cleanValue) && cleanValue >= 0) {
        const updatedRows = [...uncheckedRows];
        updatedRows[index][name] = cleanValue.toString();
        setUncheckedRows(updatedRows);
      } else {
        // If the value is negative or NaN, update the state with an empty string
        const updatedRows = [...uncheckedRows];
        updatedRows[index][name] = "";
        setUncheckedRows(updatedRows);
      }
    } else {
      const updatedRows = [...uncheckedRows];
      updatedRows[index][name] = value;
      setUncheckedRows(updatedRows);
    }
  };

  const handleInputCheckedChange = (event, index) => {
    const { name, value } = event.target;
    if (name === "price") {

      const cleanValue = value.replace(/[^\d,]/g, "").replace(/,/g, ".");
     

      // Check if the value is not negative
      if (!isNaN(cleanValue) && cleanValue >= 0) {
        const updatedRows = [...checkedRows];
        updatedRows[index][name] = cleanValue.toString();
        setCheckedRows(updatedRows);
      } else {
        // If the value is negative or NaN, update the state with an empty string
        const updatedRows = [...checkedRows];
        updatedRows[index][name] = "";
        setCheckedRows(updatedRows);
      }
    }

    else {
      const updatedRows = [...checkedRows];
      updatedRows[index][name] = value;
      setCheckedRows(updatedRows);
    }
  };

  const handleAddRow = () => {
    setUncheckedRows([
      ...uncheckedRows,
      { product: "", price: "", hours: "", rate: "", total: "" },
    ]);
  };

  const handleRemoveRow = (index) => {
    const list = [...uncheckedRows];
    list.splice(index, 1);
    setUncheckedRows(list);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    handleSubmit();
    getTotalPrice();
  }, [checkedRows, uncheckedRows]);

  useEffect(() => {
    const newRowData = newQuoteDetails.quoteOutsideInspections.map((item) => ({
      product: item.outsideInspection.name,
      price: item.outsideInspection.id === 1 ? 0 : "",
    }));

    setCheckedRows([...newRowData]);
  }, [newQuoteDetails]);
  return (
    <div className="form-head">
      <section class={`accordion ${validationCheck ? "no-selection" : ""}`}>
        <div class="tab">
          <input type="checkbox" name="accordion-1" id="cb5" />
          <label for="cb5" class="tab-label form-head quote-detail">
            <h2>{t('inspectionReportHead_outsideInspectionQuote')}</h2>
          </label>
          <div class="tab-content record">
            <div>
              <div class="check-header">
               
              </div>
              {!partnerStatusList.includes("OFFER_SENT") && (
                <div className="row">
                  <div className="col-lg-3 col-md-3 col-sm-3">
                    <div class="model">
                      {data?.map((item) => (
                        <div className="outsideIteminputfields">
                          <input
                            type="checkbox"
                            className="OutsideInspectionCheckInput"
                            disabled
                            checked={inspectionData
                              .map((data) => data.outsideInspection.id)
                              .includes(item.id)}
                            value={item.id}
                          />
                          <label for={item.id}>{item.name}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 tableDspEdit">
                    <div className="left-content ">
                      <table className="dspTable">
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>Price(€)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {checkedRows.length > 0 &&
                            checkedRows.map((item, index) => (
                              <tr key={index}>
                                <td>{item.product}</td>
                                <td>
                                  {item.product ===
                                    'Excel overview "metrics"(free)' ? (

                                    <span>{item.price}</span>
                                  ) : (

                                    <div>
                                  <CurrencyInput
                                    name="price"
                                    style={RightAlign}
                                    className="tableInputs"
                                    placeholder=""
                                    allowDecimals={true}
                                    decimalsLimit={2}
                                    prefix=""
                                   
                                    intlConfig={{ locale: 'nl-NL', currency: 'EUR' }}
                                    onChange={(event) =>
                                      handleInputCheckedChange(event, index)
                                    }
                                  />
                                 
                                </div>
                                  
                                  )}
                                </td>
                                {nullItems.includes(item.product) && (
                                  <td className="message">
                                    {t('fillTheBox_errorMsg')}
                                  </td>
                                )}
                              </tr>
                            ))}
                          {uncheckedRows.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <input
                                  type="text"
                                  name="product"
                                  onChange={(event) => {
                                    handleInputChange(event, index);
                                    setTyping(event.target.value.trim() !== "");
                                  }}
                                  placeholder={t('enterCustomProduct')}
                                />
                              </td>
                              <td>

                              <div>
                                  <CurrencyInput
                                    name="price"
                                    style={RightAlign}
                                    className="tableInputs"
                                    placeholder=""
                                    allowDecimals={true}
                                    decimalsLimit={2}
                                    prefix=""
                                   
                                    intlConfig={{ locale: 'nl-NL', currency: 'EUR' }}
                                    onChange={(event) =>
                                      handleInputChange(event, index)
                                    }
                                  />
                               
                                </div>

                               
                              </td>

                              <td>
                                <button
                                  className="AddRowButton"
                                  onClick={() => handleAddRow(index)}
                                >
                                  <img src={Frame}></img>{" "}
                                </button>
                              </td>
                              <td>
                                {index !== 0 && (
                                  <button
                                    className="RemoveRowButton"
                                    onClick={() => handleRemoveRow(index)}
                                  >
                                    <img src={secondaryDelete}></img>{" "}
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>

                      </table>
                    </div>
                  </div>
                </div>
              )}
              {partnerStatusList.includes("OFFER_SENT") && (
                <div class="model">
                  {data?.map((item) => (
                    <div className="check">
                      <input
                        type="checkbox"
                        className="OutsideInspectionCheckInputOthers"
                        disabled
                        checked={inspectionData
                          .map((data) => data.outsideInspection.id)
                          .includes(item.id)}
                        value={item.id}
                      />
                      <label for={item.id}>{item.name}</label>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      {validationCheck && (
        <div className="message">{t('pleaseChooseAtLeastOneField_errorMessage')}</div>
      )}
    </div>
  );
};
