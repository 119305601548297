import { DateRangePicker } from "react-date-range";
import searchbar from "../../../images/search-bar.png";
import date from "../../../images/date-icon.png";
import createicon from "../../../images/create-icon.png";

export const OrgFilter = ({
    t,
    requestBody,
    handleSearchFormSubmit,
    setShowCalendar,
    showCalendar,
    dateRangeRef,
    selectedDateRange,
    handleDateChange,
    toggleClearFilter,
    handleClickExport,
    viewer,
    regular,
    suprmenAdmin,
    orgAdmin

}) => {
  return (
    <div class="organizationlist-details-content">
      <div class="content-options">
        <div class="search-option searchfilter">
          <div class="search-input">
            <img src={searchbar} alt="home icon" />
            <input
              className="checkInputManageOrgList"
              type="text"
              placeholder={t("search")}
              value={requestBody.searchText}
              onChange={handleSearchFormSubmit}
            />
          </div>
        </div>

        <div class="content-button date filterIconsMainDiv">
          <img src={date} alt="date icon" className="dateicon" />
          <button
            className="btn-date"
            onClick={() => setShowCalendar(!showCalendar)}
          >
            <span>{t("dateFilter_manageOrg")}</span>
          </button>
          <div className="dateRange" ref={dateRangeRef}>
            {showCalendar && (
              <DateRangePicker
                ranges={selectedDateRange}
                onChange={handleDateChange}
              />
            )}
          </div>
        </div>
        <div class="content-button clear filterIconsMainDiv">
          <button class="btn-clear" onClick={toggleClearFilter}>
            <span>{t("clearFilter_manageOrg")}</span>
          </button>
        </div>
        <div class="export">
          <button className="btn" onClick={handleClickExport}>
            <span>{t("exportBtn_manageOrg")}</span>
          </button>
        </div>
      </div>

      <div class="right-buttons">
        {!viewer && !regular && (suprmenAdmin || orgAdmin) ? (
          <div class="create-org-btn">
            <button class="btn quoteBtns" type="button">
              <a href="/createOrganization" className="quotelink">
                <img src={createicon} alt="create icon"></img>{" "}
                <span>{t("tableHeadingBtn_manageOrg")}</span>
              </a>
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};
