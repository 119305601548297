import { DateRangePicker } from "react-date-range";
import searchbar from "../../images/search-bar.png";
import status from "../../images/status-icon.png";
import role from "../../images/role.png";
import date from "../../images/date-icon.png";
import createicon from "../../images/create-icon.png";
import { useTranslation } from "react-i18next";

import styles from "./tableFilter.module.css";

export const TableFilter = ({
  showFilters,
  dateRangeRef,
  handleSearch,
  selectedDateRange,
  handleDateChange,
  showCalendar,
  setShowCalendar,
  statusOptions,
  roleOptions,
  onSelectStatus,
  onSelectRole,
  onClearFilters,
  onExport,
  onCreateNew,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.dataTableFilters}>
      {showFilters.search && (
        <div className={styles.searchFilter}>
          <img
            className={styles.filterImages}
            src={searchbar}
            alt="search icon"
          />
          <input
            className="checkInputProjectList"
            type="text"
            placeholder={t("search")}
            onChange={(e)=>{handleSearch(e.target.value)}}
          ></input>
        </div>
      )}

      {showFilters.status && (
        <div className={styles.statusFilter}>
          <img className={styles.filterImages} src={status} alt="status icon" />
          <span>
            <select onChange={onSelectStatus}>
              {statusOptions.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </span>
        </div>
      )}

      {showFilters.role && (
        <div className={styles.roleFilter}>
          <img className={styles.filterImages} src={role} alt="role icon" />
          <span>
            <select onChange={onSelectRole}>
              {roleOptions.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </span>
        </div>
      )}

      {showFilters.date && (
        <div className={styles.dateFilter}>
          <img className={styles.filterImages} src={date} alt="date icon" />
          <button onClick={() => setShowCalendar(!showCalendar)}>
            <span>{t("date")}</span>
          </button>
          <div>
            {showCalendar && (
              <DateRangePicker
                ranges={selectedDateRange}
                onChange={handleDateChange}
              />
            )}
          </div>
        </div>
      )}

      {showFilters.clear && (
        <div className={styles.clearFilter}>
          <button onClick={onClearFilters}>
            <span>{t("clear")}</span>
          </button>
        </div>
      )}

      {showFilters.export && (
        <div className={styles.exportFilter}>
          <button onClick={onExport}>
            <span>{t("exportBtn_projectList")}</span>
          </button>
        </div>
      )}
      {showFilters.createNew && (
        <div className={styles.createNew}>
          <button type="submit" onClick={onCreateNew}>
            <img src={createicon} alt="createIcon"></img>
            <span>{t("tableHeadingBtn_projectList")}</span>
          </button>
        </div>
      )}
    </div>
  );
};
