import React, { useEffect, useState } from "react";
import "./insideSummary.modules.css";
import { useTranslation } from 'react-i18next';
import { getUser } from "../../../../../helper/auth_helper";
import api from "../../../../../helper/auth_interceptor";
import { toast } from "react-toastify";


export const InsideProductAccord = ({ newQuoteDetails, }) => {
  const [data, setData] = useState([]);
  const [isAnyOptionSelected, setIsAnyOptionSelected] = useState(true);
  const { t } = useTranslation();
 

  const productData = newQuoteDetails.quoteInsideProducts;
  

  const getData = async () => {
    const user = await getUser();
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/quote/insideProduct`;

    try {
      const response = await api.get(apiUrl, {
        headers,
      });
      if (response) {
        setData(response.data);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessages = Object.values(error.response.data).join("\n ");
        toast.error(errorMessages);
      }
    }
  };



  useEffect(() => {
    getData();
     
  }, []);
  return (
    <div className="form-head">
      <section  className="accordion">
        <div class="tab">
          <input type="checkbox" name="accordion-1" id="cb9" />
          <label for="cb9" class="tab-label form-head quote-detail">
            <h2>{t('interiorProduct_insideProductQuote')}</h2>
          </label>
          <div class="tab-content record">
            <div class="edit-check-header">
              <div class="check-header">
              
              </div>
              <div class="check-options summary">
                {data?.map((item) => (
                  <div class="check">
                    <input type="checkbox" className="InProdSectOffrInput" checked={productData.map((product) => product.insideProduct.id).includes(item.id)} diasabled value={item.id} />
                    <label for={item.id}>{item.name}</label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      
    </div>
  );
};
