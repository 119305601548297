import React, { useState } from "react";
import { getUser } from "../../../../helper/auth_helper";
import api from "../../../../helper/auth_interceptor";
import { useDropzone } from "react-dropzone";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import upload from "../../../../images/upload.png";
import bin from "../../../../images/bin.png";
import csv from "../../../../images/csvFormat.png";
import { AttachFileOutlined } from "@mui/icons-material";

const BuildDocUpload = ({ projectId, buildingId }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [files, setFiles] = useState([]);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            setFiles(acceptedFiles);
        },
    });

    const handleDeleteFile = (fileName) => {
        const updatedFiles = files.filter((file) => file.name !== fileName);
        setFiles(updatedFiles);
    };

    const formatFileSize = (sizeInBytes) => {
        const sizeInKilobytes = sizeInBytes / 1024;
        return sizeInKilobytes.toFixed(2) + " KB";
    };

    const handleSubmit = async () => {
        const user = await getUser();
        const formData = new FormData();
        formData.append("file", files[0]);
        const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/project-management/api/v1/buildings/upload-document/${projectId}?buildingId=${buildingId}`;
        const headers = {
            Authorization: `Bearer ${user.access_token}`,
            "Content-Type": "multipart/form-data",
        };
        try {
            const response = await api.post(apiUrl, formData, { headers });
            if (response) {
                window.location.reload();
            }

        } catch (error) {
            console.error("Error fetching data:", error);
        }

        setFiles([]);
        setModalIsOpen(false);
    };

    return (
        <div>
            <button
                style={{ background: "none", border: "none", color: "grey", height: "20px", width: "20px",marginRight:"15px" }}
                onClick={() => setModalIsOpen(true)}
            >
                <AttachFileOutlined />

            </button>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                style={{
                    content: {
                        width: "50%",
                        height: "70%",
                        margin: "auto",
                    },
                }}
            >
                <div className="modal-content">
                    <div class="modal-header">
                        <div>
                            <h2> Upload Building Document</h2>
                        </div>
                        <div>
                            <button
                                className="closeBtn"
                                onClick={() => setModalIsOpen(false)}
                            >
                                x
                            </button>
                        </div>
                    </div>

                    <div class="userdiv">
                        <div {...getRootProps()} className="dropzone">
                            <input {...getInputProps()} type="file"
                                accept=".pdf,.doc,.docx,.txt,.png,.jpg,.jpeg" />
                            <img src={upload}></img>
                            <h4>Click to upload or drag and drop files here</h4>
                            <p>Only files with an Word, DocX, PDF, EXCEL, CSV or PPT extension can be uploaded</p>
                        </div>
                        {files.length > 0 && (
                            <div className="fileDiv row">
                                <div class="main-file-content">
                                    <div class="sub-file-content">
                                        <div>
                                            <img src={csv}></img>
                                        </div>
                                        <div>
                                            <ul>
                                                {files.map((file) => (
                                                    <li key={file.name}>
                                                        <h5>{file.name}</h5>
                                                        <p>{formatFileSize(file.size)}</p>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>

                                    <div>
                                        {files.map((file) => (
                                            <button
                                                className="bin"
                                                onClick={() => handleDeleteFile(file.name)}
                                            >
                                                <img src={bin}></img>
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                        <span >
                            <button
                                className="btn btn-outline-success btn1 modal-cancel-btn"
                                onClick={() => setModalIsOpen(false)}
                            >
                                <span>Cancel</span>
                            </button>
                            <button
                                className="btn btn-success modal-submit-btn"
                                onClick={handleSubmit}
                            >
                                <span>Submit</span>
                            </button>
                        </span>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default BuildDocUpload;
