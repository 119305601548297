import useAuth from '../hooks/useAuth';

const SigninRedirect=()=>{

    const [isLogin, user] = useAuth();

}



export default SigninRedirect;