import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from 'react-i18next';
import "./projectLeaderDetails.modules.css";
import { toast } from "react-toastify";
import { getUser, login } from "../../../../helper/auth_helper";
import api from "../../../../helper/auth_interceptor";

function ProjectLeaderDetails({ projectInfo, setProject, validationErrors }) {
  const handleValueChange = (newProjectDetailsValues) => {
    setProject(newProjectDetailsValues);
  };

  const [users, setUsers] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      if (
        projectInfo.selectedId !== null &&
        projectInfo.selectedId !== "" &&
        projectInfo.selectedId !== "Select an organization"
      ) {
        const reqBody = {
          organizationName: null,
          location: null,
          licenceType: null,
          startDate: null,
          endDate: null,
        };

        try {
          const user = await getUser();
          if (!user) {
            login();
            return;
          }
          const headers = {
            Authorization: `Bearer ${user.access_token}`,
            "Content-Type": "application/json"
          };
          const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/users/editProject/filter/${projectInfo.selectedId}`;
          const response = await fetch(apiUrl, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(reqBody),
          });
          const data = await response.json();
          const filteredUsers = data.data.filter(user => user.organizationId === parseInt(projectInfo.selectedId));
          setUsers(filteredUsers);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, [projectInfo.selectedId]); 

  return (
    <div className="projectleader-details">
      <div className="form-head project-leader">
        <h2 className="pageHeading">{t('tabTwoHeader_projectEditProject')}</h2>
      </div>
      <div className="project-leaderdetails-content">
        <div className="first-row">
          <div className={`rows-content ${validationErrors.leaderEmail && 'error'}`}>
            <label>
              {t('emailAddress')} <span className="required">*</span>
            </label>
            <select
              className={`form-control ${validationErrors.leaderEmail ? 'error' : ''} email-dropdown`}
              value={projectInfo.leaderEmail}
              onChange={(e) => {
                const selectedEmail = e.target.value;
                const selectedUser = users.find(user => user.email === selectedEmail);
                handleValueChange({
                  ...projectInfo,
                  leaderEmail: selectedEmail,
                  leaderFirstName: selectedUser ? selectedUser.firstName : "",
                  leaderLastName: selectedUser ? selectedUser.lastName : "",
                  leaderContactNo: selectedUser ? selectedUser.contactNo : ""
                });
              }}
            >
              <option value="">{t('emailAddress')}</option>
              {users && users.map(user => (
                <option key={user.email} value={user.email}>{user.email}</option>
              ))}
            </select>
            {validationErrors.leaderEmail && <p className="error-message">{validationErrors.leaderEmail}</p>}
          </div>
          <div className={`rows-content ${validationErrors.leaderContactNo && 'error'}`}>
            <label>
              {t('phoneNo')} <span className="required">*</span>
            </label>
            <input
              className="form-control"
              placeholder={t('phoneNo')}
              value={projectInfo.leaderContactNo}
              onChange={(e) => handleValueChange({
                ...projectInfo,
                leaderContactNo: e.target.value,
              })}
              type="text"
              maxLength={18}
            />
            {validationErrors.leaderContactNo && <p className="error-message">{validationErrors.leaderContactNo}</p>}
          </div>
        </div>
        <div className="second-row">
          <div className={`rows-content ${validationErrors.leaderFirstName && 'error'}`}>
            <label>
              {t('firstName')} <span className="required">*</span>
            </label>
            <input
              className="form-control"
              placeholder={t('firstName')}
              value={projectInfo.leaderFirstName}
              onChange={(e) =>
                handleValueChange({
                  ...projectInfo,
                  leaderFirstName: e.target.value,
                })
              }
              type="text"
            />
            {validationErrors.leaderFirstName && <p className="error-message">{validationErrors.leaderFirstName}</p>}
          </div>
          <div className={`rows-content ${validationErrors.leaderLastName && 'error'}`}>
            <label>
              {t('lastName')} <span className="required">*</span>
            </label>
            <input
              className="form-control"
              placeholder={t('lastName')} 
              value={projectInfo.leaderLastName}
              onChange={(e) =>
                handleValueChange({
                  ...projectInfo,
                  leaderLastName: e.target.value,
                })
              }
              type="text"
            />
            {validationErrors.leaderLastName && <p className="error-message">{validationErrors.leaderLastName}</p>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectLeaderDetails;
