

import api from "../../../../helper/auth_interceptor";
import { getUser } from "../../../../helper/auth_helper";


export const getColumnPreferences = async () => {
    const user = await getUser();
    const page = "ADDRESS_REPORT";
    const headers = {
        Authorization: `Bearer ${user.access_token}`,
      };
  try {
    const response = await api.get(`${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/user-preference/${page}`, {
        headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching column preferences", error);
    return null;
  }
};

// API to save the updated column preferences for the user
export const saveColumnPreferences = async (preferences) => {
    const user = await getUser();
    const headers = {
        Authorization: `Bearer ${user.access_token}`,
      };
  try {
    await api.post(`${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/user-preference/selected-columns`, preferences, {
        headers,
    });
  } catch (error) {
    console.error("Error saving column preferences", error);
  }
};
