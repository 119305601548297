import { UserManager } from "oidc-client";
//File to support OIDC client settings
const settings={
  authority: `${process.env.REACT_APP_KEYCLOAK_URL}/realms/SupRmen`, //base authority realm on keycloak url
  client_id: "frontend_client", // name for client id
  redirect_uri: process.env.REACT_APP_FRONT_END_REDIRECT_URL,
  response_type: 'code',
  scope: "openid profile",
  automaticSilentRenew: true,
  silent_redirect_uri: process.env.REACT_APP_FRONT_END_REDIRECT_URL,
  post_logout_redirect_uri: window.location.href, 
  revokeAccessTokenOnSignout: true
}

const userManager = new UserManager(settings);

//function to get user
export const getUser = async () => {
  const user = await userManager.getUser();
  console.log(user);
  
  return userManager.getUser();
}

//function to get login functionality
export const login = () => {
    const redirectUrl = window.location.pathname;
    return userManager.signinRedirect({state:{redirectUrl:redirectUrl}});
}
//function to get logout functionality
export const logout = () => {
    return userManager.signoutRedirect();
}
//function to get login redirection functionality
export const loginRedirect= ()=>{
    return userManager.signinRedirectCallback()
}

