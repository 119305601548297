import React, { useEffect, useState } from "react";
import "./insideService.modules.css";
import { useTranslation } from 'react-i18next';
import { getUser } from "../../../../../helper/auth_helper";
import api from "../../../../../helper/auth_interceptor";
import { toast } from "react-toastify";
import Frame from "../../../../../images/Frame.png";
import secondaryDelete from "../../../../../images/secondaryDelete.png";
import CurrencyInput from 'react-currency-input-field';

export const InsideServiceAccord = ({
  insideServices,
  setInsideServices,
  setComponentCompletion,
  validationCheck,

  setNewQuoteDetails, newQuoteDetails, collectDataInsideServices, setInsideServicesTotal, partnerStatusList, nullItems
}) => {
  const [data, setData] = useState([]);
  const [isAnyOptionSelected, setIsAnyOptionSelected] = useState(false);
  const [typing, setTyping] = useState(false);
  const [checkedRows, setCheckedRows] = useState([]);
  const [uncheckedRows, setUncheckedRows] = useState([
    { product: "", price: "" },
  ]);
  const { t } = useTranslation();

  const serviceData = newQuoteDetails.quoteInsideServices;
  const CheckInputItemMain = {
    top: "2px"
  }
  const CheckInputItemMainSecond = {
    top: "0px"
  }
  const CheckInputItem = {
    top: "12px"
  }
  const RightAlign = {
    textAlign: "right"
  }
  const handleSubmit = () => {

    const allRowsData = {};

    checkedRows.forEach((row) => {

      const product = row.product || null;
      const price = row.price !== "" ? Number(row.price) : null;
      allRowsData[product] = price;
    });

    uncheckedRows.forEach((row) => {
      if (row.product.trim() !== "" && row.price.trim() !== "") { // Check if both product and price are not empty
        allRowsData[row.product] = Number(row.price);
      }
    });



    collectDataInsideServices(allRowsData);
  };

  const getTotalPrice = () => {
    let totalPrice = 0;


    checkedRows.forEach((row) => {
      totalPrice += Number(row.price);
    });


    uncheckedRows.forEach((row) => {
      totalPrice += Number(row.price);
    });
    setInsideServicesTotal(totalPrice)

    return totalPrice;
  };

  const getData = async () => {
    const user = await getUser();
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/quote/insideServices`;

    try {
      const response = await api.get(apiUrl, {
        headers,
      });
      if (response) {
        setData(response.data);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessages = Object.values(error.response.data).join("\n ");
        toast.error(errorMessages);
      }
    }
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    if (name === 'price') {

      const cleanValue = value.replace(/[^\d,]/g, "").replace(/,/g, ".");
     

      // Check if the value is not negative
      if (!isNaN(cleanValue) && cleanValue >= 0) {
        const updatedRows = [...uncheckedRows];
        updatedRows[index][name] = cleanValue.toString();
        setUncheckedRows(updatedRows);
      } else {
        // If the value is negative or NaN, update the state with an empty string
        const updatedRows = [...uncheckedRows];
        updatedRows[index][name] = '';
        setUncheckedRows(updatedRows);
      }
    } else {
      const updatedRows = [...uncheckedRows];
      updatedRows[index][name] = value;
      setUncheckedRows(updatedRows);
    }
  }
  const handleInputCheckedChange = (index, event) => {
    const { name, value } = event.target;
    if (name === 'price') {
      const cleanValue = value.replace(/[^\d,]/g, "").replace(/,/g, ".");

      // Check if the value is not negative
      if (!isNaN(cleanValue) && cleanValue >= 0) {
        const updatedRows = [...checkedRows];
        updatedRows[index][name] = cleanValue.toString();
        setCheckedRows(updatedRows);
      } else {
        // If the value is negative or NaN, update the state with an empty string
        const updatedRows = [...checkedRows];
        updatedRows[index][name] = '';
        setCheckedRows(updatedRows);
      }
    } else {
      const updatedRows = [...checkedRows];
      updatedRows[index][name] = value;
      setCheckedRows(updatedRows);
    }
  }




  const handleAddRow = () => {
    setUncheckedRows([
      ...uncheckedRows,
      { product: "", price: "" },
    ]);
  };

  const handleRemoveRow = (index) => {
    const list = [...uncheckedRows];
    list.splice(index, 1);
    setUncheckedRows(list);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    handleSubmit();
    getTotalPrice();
  }, [checkedRows, uncheckedRows]);

  useEffect(() => {
    const newRowData = newQuoteDetails.quoteInsideServices.map((item) => ({
      product: item.insideServices.name,
      price: "",
    }));

    setCheckedRows(...checkedRows, newRowData);
  }, [newQuoteDetails]);

  return (
    <div className="form-head">
      <section class={`accordion ${validationCheck ? "no-selection" : ""}`}>
        <div class="tab">
          <input type="checkbox" name="accordion-1" id="cb7" />
          <label for="cb7" class="tab-label form-head quote-detail">
            <h2>{t('interiorServices_insideServiceQuote')}</h2>
          </label>
          <div class="tab-content record">
            <div>
              <div class="check-header">

              </div>
              {!(partnerStatusList.includes('OFFER_SENT')) && (
                <div className="row">
                  <div class="model col-lg-3 col-md-3 col-sm-3 inside-service">
                    {data?.map((item, index) => (
                      <div
                        class={`outsideIteminputfields ${index === 0
                          ? "first-item"
                          : index === 1
                            ? "second-item"
                            : ""
                          }`}
                      >
                        <input
                          style={CheckInputItem}
                          type="checkbox"
                          checked={serviceData.map((service) => service.insideServices.id).includes(item.id)}
                          disabled

                        />
                        <label for={item.id}>{item.name}</label>
                      </div>
                    ))}
                  </div>

                  <div className="col-lg-9 col-md-9 col-sm-9">
                    <div className="left-content ">
                      <table className="dspTable">
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>Price(€)</th>

                          </tr>
                        </thead>
                        <tbody>
                          {checkedRows.length > 0 && checkedRows.map((item, index) => (
                            <tr key={index}>
                              <td>{item.product}</td>
                              <td>
                              <div>
                                  <CurrencyInput
                                    name="price"
                                    className="tableInputs"
                                    placeholder=""
                                    allowDecimals={true}
                                    decimalsLimit={2}
                                    style={RightAlign}
                                    prefix=""
                                    
                                    intlConfig={{ locale: 'nl-NL', currency: 'EUR' }}
                                    onChange={(event) =>
                                      handleInputCheckedChange(index, event)
                                    }
                                  />
                             
                                </div>
                               
                              </td>
                              {nullItems.includes(item.product) && (
                                <td className="message">{t('fillTheBox_errorMsg')}</td>
                              )}
                            </tr>
                          ))}
                          {uncheckedRows.map((item, index) => (
                            <tr key={index + checkedRows.length}>
                              <td>


                                <input
                                  type="text"
                                  name="product"
                                  onChange={(event) => {
                                    handleInputChange(index, event);

                                  }}
                                  placeholder={t('enterCustomProduct')}
                                />

                              </td>
                              <td>
                              <div>
                                  <CurrencyInput
                                    name="price"
                                    style={RightAlign}
                                    className="tableInputs"
                                    placeholder=""
                                    allowDecimals={true}
                                    decimalsLimit={2}
                                    prefix=""
                                   
                                    intlConfig={{ locale: 'nl-NL', currency: 'EUR' }}
                                    onChange={(event) =>
                                      handleInputChange(index, event)
                                    }
                                  />
                                 
                                </div>
                                
                              </td>

                              <td>
                                <button className="AddRowButton" onClick={() => handleAddRow(index)}>
                                  <img src={Frame}></img> </button>

                              </td>
                              <td>
                                {index !== 0 && (
                                  <button
                                    className="RemoveRowButton"
                                    onClick={() => handleRemoveRow(index)}
                                  >
                                    <img src={secondaryDelete}></img> </button>
                                )}
                              </td>
                            </tr>
                          ))}

                        </tbody>

                      </table>
                    </div>
                  </div>

                </div>
              )}
              {(partnerStatusList.includes('OFFER_SENT')) && (
                <div class="model col-lg-2 col-md-2 col-sm-2 inside-service">
                  {data?.map((item, index) => (
                    <div
                      class={`check ${index === 0
                        ? "first-item"
                        : index === 1
                          ? "second-item"
                          : ""
                        }`}
                    >
                      <input
                        style={CheckInputItemMainSecond}
                        type="checkbox"
                        checked={serviceData.map((service) => service.insideServices.id).includes(item.id)}
                        disabled

                      />
                      <label for={item.id}>{item.name}</label>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

        </div>
      </section>
      {validationCheck && (
        <div className="message">{t('pleaseChooseAtLeastOneField_errorMessage')}</div>
      )}
    </div>
  );
};
