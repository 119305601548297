import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { getUser } from "../../../../helper/auth_helper";
import api from "../../../../helper/auth_interceptor";
import { useTranslation } from 'react-i18next';
import moment from "moment";


const QuoteOverviewModel = ({ show, handleClose, quoteDetails,
    scanOption,
    planningDetails,
    insideProduct,
    insideServices,
    outsideProduct,
    outsidePurpose,
    outsideExtras,
    outsideInspection,
    otherPurposeText,
    handleSubmit,
    inpectionSelected,
    validationCheck }) => {

    let uniqueOutsidePurpose = outsidePurpose.filter((item, index, self) =>
        index === self.findIndex((t) => (
            t.outsidePurposeType === item.outsidePurposeType
        ))
    );
    const [project, setProject] = useState([]);

    const projectName = useRef();
    const clientName = useRef();
    const email = useRef();
    const ProjectEndDate = useRef();
    const format1 = "DD-MM-YYYY";

    const { t } = useTranslation();
    const projectDataId = quoteDetails.projectId;
    const prodLabel = {
        width: "20%",
       
        padding: "7px 5px",
        margin: "2px 0 0 1%",
        display: "inline-flex",
        fontSize: "16px",

    }
    const prodValues = {
        width: "75%",
        padding: "5px",
        margin: "3px 0 0 1%",
        display: "inline-flex",
        fontSize: "16px",
        flexWrap: "wrap"
    }

    const DatesInline = {
        width: '100%',
        display: 'inline-flex',
    }

    const ForMainProdExternal = {
        background: "#d319d3",
        color: "white",
        padding: "1px 10px 4px 10px",
        margin: "2px 5px 2px 5px",
        borderRadius: "50px",
        height: "max-content",
        width: "auto",
        whiteSpace: "nowrap",
        textTransform: "lowercase"
    }
    const ForMainProdInternal = {
        background: "#18c5ae",
        color: "white",
        padding: "1px 10px 4px 10px",
        margin: "2px 5px 2px 5px",
        borderRadius: "50px",
        height: "max-content",
        width: "auto",
        whiteSpace: "nowrap",
        textTransform: "lowercase"
    }

    const ForSubProd = {
        background: "#c5bc18",
        color: "white",
        padding: "1px 10px 4px 10px",
        margin: "2px 5px 2px 5px",
        borderRadius: "50px",
        height: "max-content",
        width: "auto",
        whiteSpace: "nowrap",
        textTransform: "lowercase"
    }

    const InlineDiv = {
        display: "inline"
    }

    const BorderDivGeneral = {
        border: "1px solid #b1aeae",
        borderRadius: "15px",
        margin: "10px 0"
    }
    const BorderDiv = {
        border: "1px solid #b1aeae",
        borderRadius: "15px",
        margin: "10px 0"
    }

    const mainTitle = {
        marginLeft: "15px",
        marginTop: "15px",
        color: "#ffae00"
    }
    const TrnsformText = {
        textTransform: "capitalize"
    }

    async function FetchProject() {
        const user = await getUser();
        const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/project-management/api/v1/project`;
        const headers = {
            Authorization: `Bearer ${user.access_token}`,
        };
        try {
            const response = await api.get(apiUrl, { headers });
            setProject(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    useEffect(() => {
        FetchProject();
    }, []);
    useEffect(() => {
        if (projectDataId && project.length > 0) {
            const projectIdNumber = parseInt(projectDataId);
            const matchedProject = project.find(
                (item) => item.id === projectIdNumber
            );
            if (matchedProject) {

                projectName.current = matchedProject.name;
                clientName.current = matchedProject.organization.name;
                email.current = matchedProject.projectLeader.email;
                ProjectEndDate.current = matchedProject.endDate;
            }
        }
    }, [project, projectDataId]);
    return (
        <Modal show={show} onHide={handleClose} className='modal1'>
            <Modal.Header closeButton><h2>{t('quoteOverView_quoteOverviewQuote')}</h2></Modal.Header>
            <Modal.Body>
                <h6 style={mainTitle}>{t('general_quoteOverviewQuote')}</h6>
                <div style={BorderDivGeneral}>

                    <div>
                        <h5 style={prodLabel}>
                            {t('projectName_quoteOverviewQuote')}
                        </h5>
                        {projectName.current && (
                            <div style={prodValues}>
                                <div>{projectName.current}</div>
                            </div>
                        )}
                    </div>
                    <div >
                        <h5 style={prodLabel}>
                            {t('clientName_quoteOverviewQuote')}

                        </h5>
                        {clientName.current && (
                            <div style={prodValues}>
                                <div>{clientName.current}</div>
                            </div>
                        )}

                    </div>
                    <div >
                        <h5 style={prodLabel}>
                           {t('projectLeader_quoteOverviewQuote')}

                        </h5>
                        {email.current && (
                            <div style={prodValues}>
                                <div>{email.current}</div>
                            </div>
                        )}

                    </div>


                </div>

                <h6 style={mainTitle}>{t('project_quoteOverviewQuote')}</h6>
                <div style={BorderDiv}>
                    <h5 className="MainLabel" style={prodLabel}>{t('scanOption_quoteOverviewQuote')} </h5>
                    <div style={prodValues}>
                        {scanOption && scanOption.productType && (
                            scanOption.productType === "INSIDE" ? "Interior" :
                                scanOption.productType === "OUTSIDE" ? "Exterior" :
                                    scanOption.productType === "BOTH" ? "Interior and Exterior" :
                                        scanOption.productType
                        )}

                    </div>

                    {ProjectEndDate.current && (
                        <div style={DatesInline}>

                            <h5 style={prodLabel}>{t('expectedDate_quoteOverviewQuote')} </h5>
                            <div style={prodValues}>
                                <div>{ProjectEndDate.current}</div>
                            </div>


                        </div>
                    )}

                </div>

                <h6 style={mainTitle}>{t('product_quoteOverviewQuote')}</h6>
                <div style={BorderDiv}>

                    {(scanOption.productType === "OUTSIDE" ||
                        scanOption.productType === "BOTH") && (
                            <div style={InlineDiv}>
                                <h5 style={prodLabel}> {t('exterior_quoteOverviewQuote')} </h5>
                                <div style={prodValues}>
                                    {outsideProduct.length > 0 && (

                                        outsideProduct.map((item, index) => {
                                            if (index === 0) {
                                                return <span style={ForMainProdExternal}><span style={TrnsformText}>{item.name}</span></span>;
                                            } else if (index === outsideProduct.length - 1) {
                                                return <span style={ForSubProd}><span style={TrnsformText}>  {item.name}</span></span>;
                                            } else {
                                                return <span style={ForSubProd}><span style={TrnsformText}>{item.name}</span></span>;
                                            }

                                        })

                                    )}



                                    {

                                        uniqueOutsidePurpose.map((item, index) => {

                                            if (index === 0) {
                                                return (
                                                    <span style={ForSubProd}>
                                                        <span style={TrnsformText}> {item.name == "Others" ? item.name : item.outsidePurposeType.toLowerCase()}</span>
                                                    </span>
                                                );
                                            } else if (index === uniqueOutsidePurpose.length - 1) {
                                                return (
                                                    <span style={ForSubProd}>
                                                        <span style={TrnsformText}>{item.name == "Others" ? item.name : item.outsidePurposeType.toLowerCase()}</span>
                                                    </span>
                                                );
                                            } else {
                                                return (
                                                    <span style={ForSubProd}>
                                                        <span style={TrnsformText}>{item.name == "Others" ? item.name : item.outsidePurposeType.toLowerCase()}</span>
                                                    </span>
                                                );
                                            }
                                        })
                                    }


                                    {otherPurposeText && (
                                        <>

                                            <span style={ForSubProd}><span style={TrnsformText}> {otherPurposeText}</span></span>
                                        </>
                                    )}


                                    {outsideExtras.map((item, index) => {
                                        if (index === 0) {
                                            return <span style={ForSubProd}><span style={TrnsformText}>{item.name}</span></span>;
                                        } else if (index === outsideExtras.length - 1) {
                                            return <span style={ForSubProd}><span style={TrnsformText}>  {item.name}</span></span>;
                                        } else {
                                            return <span style={ForSubProd}><span style={TrnsformText}>{item.name}</span></span>;
                                        }
                                    })}


                                    {inpectionSelected ? (
                                        <React.Fragment>

                                            {outsideInspection.map((item, index) => {
                                                if (index === 0) {
                                                    return <span style={ForSubProd}><span style={TrnsformText}>{item.name}</span></span>;
                                                } else if (index === outsideInspection.length - 1) {
                                                    return <span style={ForSubProd}><span style={TrnsformText}>  {item.name}</span></span>;
                                                } else {
                                                    return <span style={ForSubProd}><span style={TrnsformText}>{item.name}</span></span>;
                                                }
                                            })}

                                        </React.Fragment>
                                    ) : null}
                                </div>
                            </div>
                        )}

                    {(scanOption.productType === "INSIDE" ||
                        scanOption.productType === "BOTH") && (
                            <div style={InlineDiv}>

                                <h5 style={prodLabel}>{t('interior_quoteOverviewQuote')} </h5>
                                <div style={prodValues}>
                                    {insideProduct.length > 0 && (

                                        insideProduct.map((item, index) => {

                                            if (index === 0) {
                                                return <span style={ForMainProdInternal}><span style={TrnsformText}>{item.name}</span></span>;
                                            } else if (index === insideProduct.length - 1) {
                                                return <span style={ForSubProd}><span style={TrnsformText}>  {item.name}</span></span>;
                                            } else {
                                                return <span style={ForSubProd}><span style={TrnsformText}>{item.name}</span></span>;
                                            }
                                        })

                                    )}

                                    {insideServices.map((item, index) => {
                                        if (index === 0) {
                                            return <span style={ForSubProd}><span style={TrnsformText}>{item.name}</span></span>;
                                        } else if (index === insideServices.length - 1) {
                                            return <span style={ForSubProd}><span style={TrnsformText}>  {item.name}</span></span>;
                                        } else {
                                            return <span style={ForSubProd}><span style={TrnsformText}>{item.name}</span></span>;
                                        }
                                    })}
                                </div>


                            </div>
                        )}

                </div>


                <div className="buttonContainer">
                    <button
                        type="submit"
                        className="btn-save"
                        onClick={() => handleSubmit()}
                    >
                        <span>{t("confirm")}</span>
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default QuoteOverviewModel;
