import React, { useState, useRef } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import searchbar from "../../../images/search-bar.png";
import date from "../../../images/date-icon.png";
import createicon from "../../../images/create-icon.png";
import useExport from "../../shared/useExport";
import moment from "moment";


const ProjectListFilters = ({
  viewer,
  regular,
  suprmenAdmin,
  orgAdmin,
  t,
  handleSearchFormSubmit,
  requestBody,
  fetchProjectDetails,
  toggleClearFilter,
  exportFunction,
  setShowCalendar,
  showCalendar,
  selectedDateRange,
  setSelectedDateRange,
  dateRangeRef,
}) => {
  const { handleExport } = useExport();

  const handleDateChange = (ranges) => {
    setSelectedDateRange([ranges.selection]);

    const startDate = moment(ranges.selection.startDate).format("DD-MM-YYYY");
    const endDate = moment(ranges.selection.endDate).format("DD-MM-YYYY");

    requestBody.current = {
      ...requestBody.current,
      startDate: startDate,
      endDate: endDate,
    };
    fetchProjectDetails();
  };

  return (
    <div className="projectlist-details-content">
      <div className="content-options">
        <div className="search-option searchfilter">
          <div className="search-input">
            <img src={searchbar} alt="home icon" />
            <input
              className="checkInputProjectList"
              type="text"
              placeholder={t("search")}
              value={requestBody.current.searchText || ""}
              onChange={handleSearchFormSubmit}
            />
          </div>
        </div>

        <div className="content-button date filterIconsMainDiv">
          <img src={date} alt="date icon" className="dateicon" />
          <button
            className="btn-date"
            onClick={() => setShowCalendar(!showCalendar)}
          >
            <span>{t("dateFilter_projectList")}</span>
          </button>
          <div className="dateRange" ref={dateRangeRef}>
            {showCalendar && (
              <DateRangePicker
                ranges={selectedDateRange}
                onChange={handleDateChange}
              />
            )}
          </div>
        </div>

        <div className="content-button clear filterIconsMainDiv">
          <button className="btn-clear" onClick={toggleClearFilter}>
            <span>{t("clearFilter_projectList")}</span>
          </button>
        </div>

        <div className="export">
          <button className="btn" onClick={exportFunction}>
            <span>{t("exportBtn_projectList")}</span>
          </button>
        </div>
      </div>

      <div className="right-buttons">
        {!viewer && !regular && (suprmenAdmin || orgAdmin) ? (
          <div className="create-project-btn">
            <button className="btn quoteBtns" type="button">
              <a href="/project" className="quotelink">
                <img src={createicon} alt="create icon" />
                <span>{t("tableHeadingBtn_projectList")}</span>
              </a>
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ProjectListFilters;
