import React, {useState} from 'react';
import {Modal, ModalBody, ModalHeader} from "reactstrap"
import TextEditor from './Email';

export default function Pop() {
  const [modal, setModal] = useState(false);
  return(
    <div>
      <Modal
      
      size="lg"
      isOpen={modal}
      toggle={() =>setModal(!modal)}
    
    >

      <ModalHeader
      toggle={() =>setModal(!modal)}
      >
      Email 
      </ModalHeader>

      <ModalBody>
      <TextEditor>

      Hello World!
      </TextEditor>
      </ModalBody>
      
      </Modal>
      


        <button className='togglebtn btn btn-primary' onClick={()=> setModal(true)}>Send Email </button>
    </div>
  )
};