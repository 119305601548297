import React, { useEffect, useState } from "react";
import "./insideService.modules.css";
import { useTranslation } from "react-i18next";
import { getUser } from "../../../../helper/auth_helper";
import api from "../../../../helper/auth_interceptor";
import { toast } from "react-toastify";

export const InsideServiceAccord = ({
  insideServices,
  setInsideServices,
  setComponentCompletion,
  validationCheck,
}) => {
  //states and constants declaration
  const [data, setData] = useState([]);
  const [isAnyOptionSelected, setIsAnyOptionSelected] = useState(false);
  const { t } = useTranslation();

  const handleValueChange = (selectedId, selectedName) => {
    let updatedProducts;

    // Check if the selectedId is already in the array
    const isAlreadySelected = insideServices.some(
      (services) => services.id === selectedId
    );

    if (isAlreadySelected) {
      // If the checkbox is unchecked, remove the item from the array
      updatedProducts = insideServices.filter(
        (services) => services.id !== selectedId
      );
    } else {
      updatedProducts = [
        ...insideServices,
        { id: selectedId, name: selectedName },
      ];
    }

    setInsideServices(updatedProducts);
    setIsAnyOptionSelected(updatedProducts.length > 0);
    setComponentCompletion(updatedProducts.length > 0);
    return updatedProducts;
  };

  //function to get data
  const getData = async () => {
    const user = await getUser();
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/quote/insideServices`;

    try {
      const response = await api.get(apiUrl, {
        headers,
      });
      if (response) {
        setData(response.data);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessages = Object.values(error.response.data).join("\n ");
        toast.error(errorMessages);
      }
    }
  };
  //useEffect to get data on page load.
  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="form-head">
      <section class={`accordion ${validationCheck ? "no-selection" : ""}`}>
        <div class="tab">
          <input
            className="CheckInput"
            type="checkbox"
            name="accordion-1"
            id="cb7"
          />
          <label for="cb7" class="tab-label form-head quote-detail">
            <h2>{t("interiorServices_insideServiceQuote")}</h2>
          </label>
          <div class="tab-content record">
            <div>
              <div class="check-header">
                <div class="check-header-title">
                  <input
                    className="CheckInput"
                    type="checkbox"
                    checked={isAnyOptionSelected}
                  />
                  <label>{t("interiorServices_insideServiceQuote")}</label>
                </div>
              </div>
              <div class="check-options inside-service">
                {data?.map((item, index) => (
                  <div
                    class={`check ${
                      index === 0
                        ? "first-item"
                        : index === 1
                        ? "second-item"
                        : ""
                    }`}
                  >
                    <input
                      className="CheckInput"
                      type="checkbox"
                      id={item.id}
                      onChange={() => handleValueChange(item.id, item.name)}
                    />
                    <label for={item.id}>{item.name}</label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      {validationCheck && (
        <div className="message">
          {t("pleaseChooseAtLeastOneField_errorMessage")}
        </div>
      )}
    </div>
  );
};
