import React, { useState, useEffect, useCallback } from "react";
import "./outsideinspection.modules.css";
import { getUser } from "../../../../helper/auth_helper";
import api from "../../../../helper/auth_interceptor";
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";

export const OutsideInspection = ({ outsideInspection, setOutsideInspection, setComponentCompletion, checkboxSelected, validationCheck }) => {
      //states and constant declaration
    const [data, setData] = useState([]);
    const [isAnyOptionSelected, setIsAnyOptionSelected] = useState(false);
    const [outSidePurposeCheckBox, setOutSidePurposeCheckBox] = useState({
        INSPECTION: false,
    });
    const [reCheck, setReCheck] = useState(false);

    const { t } = useTranslation();

    const getData = async () => {
        const user = await getUser();
        const headers = {
            Authorization: `Bearer ${user.access_token}`,
        };
        const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/quote/outsideInspection`;

        try {
            const response = await api.get(apiUrl, {
                headers,
            });
            if (response) {
                setData(response.data);
                const defaultSelectedItems = response.data.filter((item) => item.defaultSelected);
                if (defaultSelectedItems.length > 0) {
                    setOutsideInspection(defaultSelectedItems);
                    setOutSidePurposeCheckBox((prev) => ({
                        ...prev,
                        INSPECTION: true,
                    }));
                    setComponentCompletion(true);
                }
            }
        } catch (error) {
            if (error.response && error.response.data) {
                const errorMessages = Object.values(error.response.data).join("\n ");
                toast.error(errorMessages);
            }
        }
    };
//function handle value changes a new item is selected
    const handleValueChange = (item) => {
      setComponentCompletion(true);
      const updateData = data.filter((product) => product.name === item.name && product.id === item.id);
      const updatedOutsideInspection = [...outsideInspection, ...updateData];
  
      if (isChecked(item)) {
          const filteredOutsideInspection = outsideInspection.filter((product) => product.id !== item.id);
          setOutsideInspection(filteredOutsideInspection);
      } else {
          setOutsideInspection(updatedOutsideInspection);
      }
  
      setOutSidePurposeCheckBox((prev) => ({
          ...prev,
          INSPECTION: updatedOutsideInspection.length > 0,
      }));
  
      setReCheck(!reCheck);
  };
  
//function to handle value of selected item
    const handleFieldValueChange = (key) => {
        setOutSidePurposeCheckBox((prev) => ({
            ...prev,
            [key]: !outSidePurposeCheckBox[key],
        }));
        let updatedProducts;
        if (!outSidePurposeCheckBox[key]) {
            updatedProducts = data.filter((product) => product.defaultSelected === true);
            const newOutsideInspection = [...outsideInspection, ...updatedProducts];
            setOutsideInspection(newOutsideInspection);
        } else {
            updatedProducts = [];
            setOutsideInspection([]);
        }
        setReCheck(!reCheck);
        setComponentCompletion(updatedProducts.length > 0);
    };

    const isChecked = useCallback(
        (item) => {
            const checkedData = outsideInspection.filter((product) => product.id === item.id);
            return checkedData.length > 0;
        },
        [reCheck, outsideInspection]
    );

    useEffect(() => {
        if (checkboxSelected) {
            setOutSidePurposeCheckBox((prev) => ({
                ...prev,
                INSPECTION: true,
            }));
            let updatedProducts = data.filter((product) => product.defaultSelected === true);
            setOutsideInspection(updatedProducts);
        }
    }, [checkboxSelected]);

    //useEffect to get data
    useEffect(() => {
        getData();
    }, []);

    return (
        <div className="form-head">
            <section className={`accordion ${validationCheck ? "no-selection" : ""}`}>
                <div className="tab">
                    <input className="CheckInput" type="checkbox" name="accordion-1" id="cb5" />
                    <label htmlFor="cb5" className="tab-label form-head quote-detail">
                        <h2>{t('inspectionReportHead_outsideInspectionQuote')}</h2>
                    </label>
                    <div className="tab-content record">
                        <div>
                            <div className="check-header">
                                <div className="check-header-title">
                                    <input
                                        className="CheckInput"
                                        type="checkbox"
                                        id="Inspection"
                                        checked={outSidePurposeCheckBox.INSPECTION}
                                        onChange={() => handleFieldValueChange("INSPECTION")}
                                    />
                                    <label htmlFor="Inspection">{t('inspection_outsideInspectionQuote')}</label>
                                </div>
                            </div>
                            <div className="check-options inspection">
                                {data?.map((item) => (
                                    <div className="check" key={item.id}>
                                        <input
                                            className="CheckInput"
                                            type="checkbox"
                                            value={item.id}
                                            checked={isChecked(item)}
                                            onChange={() => handleValueChange(item)}
                                        />
                                        <label htmlFor={item.id}>{item.name}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {validationCheck && <div className="message">{t('pleaseChooseAtLeastOneField_errorMessage')}</div>}
        </div>
    );
};
