import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

// Import translations
import translationEN from './locales/en/translation.json';
import translationNL from './locales/nl/translation.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector) // detects user language
  .use(HttpApi) // loads translations
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      nl: {
        translation: translationNL,
      },
    },
    lng: localStorage.getItem('i18nextLng') || 'nl',
    fallbackLng: 'nl', // default language
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['cookie'],
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;

