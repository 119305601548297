import React, { useState, useEffect } from "react";
import outsideservice from "../../../../images/outside-service.png";
import { useTranslation } from 'react-i18next';
import "./outsideAccordian.modules.css";
import { getUser } from "../../../../helper/auth_helper";
import api from "../../../../helper/auth_interceptor";
import { toast } from "react-toastify";


export const Outside = ({ setNewQuoteDetails, newQuoteDetails, setComponentCompletion, isDraft }) => {
  const [outsideItem, setOutsideItem] = useState([]);
  const [isAnyOptionSelected, setIsAnyOptionSelected] = useState(false);
  const RightImage = {
    width: '100%',
    objectFit: 'contain',
    marginTop: '35px'
}
const { t } = useTranslation();
const handleValueChange = (selectedId) => {
  const isIdSelected = newQuoteDetails.quoteOutsideProducts.some((item) => item.outsideProduct.id === selectedId);
  let updatedProducts;

  if (isIdSelected) {

    updatedProducts = newQuoteDetails.quoteOutsideProducts.filter((item) => item.outsideProduct.id !== selectedId);
  } else {

    const selectedProduct = outsideItem.find((item) => item.id === selectedId);
    updatedProducts = [...newQuoteDetails.quoteOutsideProducts, { outsideProduct: selectedProduct }];
  }

  const updatedQuoteDetails = { ...newQuoteDetails, quoteOutsideProducts: updatedProducts };
  setNewQuoteDetails(updatedQuoteDetails);
  setIsAnyOptionSelected(updatedProducts.length > 0)
  setComponentCompletion(updatedProducts.length > 0);

  return updatedQuoteDetails;
};

const productData = newQuoteDetails.quoteOutsideProducts;
if (productData.length > 0) {
  setComponentCompletion(true);
}

const getOutside = async () => {
  const user = await getUser();
  const headers = {
    Authorization: `Bearer ${user.access_token}`,
  };
  const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/quote/outsideProduct`;

  try {
    const response = await api.get(apiUrl, {
      headers,
    });
    if (response) {
      setOutsideItem(response.data);
    }
  } catch (error) {
    if (error.response && error.response.data) {
      const errorMessages = Object.values(error.response.data).join("\n ");
      toast.error(errorMessages);
    }
  }
};

const accordionClass = isAnyOptionSelected ? 'accordion' : 'accordionerror';
useEffect(() => {
  getOutside();

}, []);
useEffect(() => {
  setIsAnyOptionSelected(productData.length > 0);
}, [productData])
return (
  <div className="form-head">
    <section className={!isDraft && accordionClass} >
      <div class="tab">
        <input type="checkbox" className="CheckInputOutAcc" name="accordion-1" id="cb4" />
        <label for="cb4" class="tab-label form-head quote-detail">
          <h2>{t('Exterior_outsideAccordianCreateQuote')}</h2>
        </label>
        <div class="tab-content fourth">
          <div class="main-outside-content">
            <div class="outside-model">
              <div class="left-content">
                <div class="header">
                <h3>{t('3DModelText_outsideAccordianCreateQuote')}</h3>
                    <p>{t('text1_outsideAccordianCreateQuote')}</p>
                </div>

                <div class="model">
                  {outsideItem?.map((item) => (
                    <div class="check">
                      <input type="checkbox" className="CheckInputOutAcc" checked={productData.map((product) => product.outsideProduct.id).includes(item.id)} value={item.id} onChange={() => handleValueChange(item.id)} />
                      <label for="Roof">{item.name}</label>
                    </div>
                  ))}
                </div>
              </div>

              <div class="right-content">
                <img src={outsideservice} style={RightImage} alt="required service" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {!isDraft && !isAnyOptionSelected && <div className="message">{t('pleaseChooseAtLeastOneField_errorMessage')}</div>}
  </div>
);
};
