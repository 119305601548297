import React, {useEffect, useState} from "react";
import "./insideService.modules.css";
import { useTranslation } from 'react-i18next';
import { getUser } from "../../../../helper/auth_helper";
import api from "../../../../helper/auth_interceptor";
import { toast } from "react-toastify";


export const InsideServiceAccord = ({setNewQuoteDetails, newQuoteDetails, setComponentCompletion, validationCheck, isDraft}) => {
   const [data, setData] = useState([]);
   const [isAnyOptionSelected, setIsAnyOptionSelected] = useState(false);
   const checkOptionsInput = { top: "0px" }
   const { t } = useTranslation();
   const handleValueChange = (selectedId) => {

    const isIdSelected = newQuoteDetails.quoteInsideServices.some((item) => item.insideServices.id === selectedId);
    let updatedProducts;

    if (isIdSelected) {
    
      updatedProducts = newQuoteDetails.quoteInsideServices.filter((item) => item.insideServices.id !== selectedId);
    } else {
    
      const selectedProduct = data.find((item) => item.id === selectedId);
      updatedProducts = [...newQuoteDetails.quoteInsideServices, { insideServices: selectedProduct }];
    }

    const updatedQuoteDetails = { ...newQuoteDetails, quoteInsideServices: updatedProducts };
    setNewQuoteDetails(updatedQuoteDetails);
    setIsAnyOptionSelected(updatedProducts.length > 0);
    setComponentCompletion(updatedProducts.length > 0);
  
    return updatedQuoteDetails;

    
    };

    const serviceData= newQuoteDetails.quoteInsideServices;
    if(serviceData.length > 0){
      setComponentCompletion(true);
    }


   const getData = async() => {
      const user = await getUser();
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/quote/insideServices`;

    try {
      const response = await api.get(apiUrl, {
        headers,
      });
      if (response) {
        
        setData(response.data);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessages = Object.values(error.response.data).join("\n ");
        toast.error(errorMessages);
      }
    }
   }
   const accordionClass = isAnyOptionSelected ? 'accordion' : 'accordionerror';


   useEffect(()=>{
      getData();
      const anyItemSelected = serviceData.length > 0;
    setComponentCompletion(anyItemSelected); 
    setIsAnyOptionSelected(anyItemSelected);  
   },[serviceData])
    return(
        <div className="form-head">
        <section  className={!isDraft && accordionClass}>
           <div class="tab">
              <input type="checkbox" name="accordion-1" id="cb8"/>
              <label for="cb8" class="tab-label form-head quote-detail">
                 <h2>{t('interiorServices_insideServiceQuote')}</h2>
              </label>
              <div class="tab-content record">
              <div>
                  <div class="check-header">
                     <div class="check-header-title">
                        <input type="checkbox" style={checkOptionsInput} checked={isAnyOptionSelected}/>
                        <label >{t('interiorServices_insideServiceQuote')}</label>
                     </div>
                  </div>
                  <div class="check-options inside-service">
                     {data?.map((item,index)=>(
                         <div class={`check ${index === 0 ? 'first-item' : index === 1 ? 'second-item' : ''}`}>
                         <input type="checkbox" style={checkOptionsInput} checked={serviceData.map((service) => service.insideServices.id).includes(item.id)} id={item.id} onChange={() => handleValueChange(item.id)}/>
                         <label for={item.id}>{item.name}</label>
                      </div>
                     ))}
                       
                  </div>

               </div>
              </div>
           </div>
        </section>
        {!isDraft && !isAnyOptionSelected && <div className="message">{t('pleaseChooseAtLeastOneField_errorMessage')}</div>}
     </div>
    )
}