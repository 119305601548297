import React, { useState, useEffect } from "react";
import outsideservice from "../../../../../images/outside-service.png";
import { useTranslation } from 'react-i18next';
import "./outsideAccordian.css";
import { getUser } from "../../../../../helper/auth_helper";
import api from "../../../../../helper/auth_interceptor";
import { toast } from "react-toastify";
import Frame from "../../../../../images/Frame.png";
import secondaryDelete from "../../../../../images/secondaryDelete.png";

export const Outside = ({
 
  newQuoteDetails,
  offerDetails
}) => {
  const [outsideItem, setOutsideItem] = useState([]);
 
 

  const productData = newQuoteDetails.quoteOutsideProducts;
  
  
  const AlignRight={
    textAlign:"right"
  }
  const AlignCenter={
    textAlign:"center"
  }
  const { t } = useTranslation();
  
  const getOutside = async () => {
    const user = await getUser();
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/quote/outsideProduct`;

    try {
      const response = await api.get(apiUrl, {
        headers,
      });
      if (response) {
        setOutsideItem(response.data);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessages = Object.values(error.response.data).join("\n ");
        toast.error(errorMessages);
      }
    }
  };

  function calculateTotalCost(selectedOutsideProductOffer) {
    if (!selectedOutsideProductOffer) return 0;
  
    const totalHours = selectedOutsideProductOffer.outsideProductOffers.reduce((total, item) => total + item.hours, 0);
    const totalRate = selectedOutsideProductOffer.outsideProductOffers.reduce((total, item) => total + item.ratePerHour * item.hours, 0);
    const commissionPercentage = selectedOutsideProductOffer.commission || 0;
  
    const subtotal = totalRate;
    const commissionAmount = (subtotal * commissionPercentage) / 100;
    const totalCost = subtotal + commissionAmount;
  
    return totalCost.toFixed(2);
  }
 
  useEffect(() => {
    getOutside();
  }, []);
 
  return (
    <div className="form-head">
      <section class="accordion">
        <div class="tab">
          <input type="checkbox" name="accordion-1" id="cb3" />
          <label for="cb3" class="tab-label form-head quote-detail">
            <h2>{t('Exterior_outsideAccordianCreateQuote')}</h2>
           
                      </label>
          <div class="tab-content fourth">
            <div class="main-outside-content">
              <div class="outside-model">
                <div className="row outsideModelRow">
                  <div className="col-lg-2 col-md-2 col-sm-2">
                    {" "}
                    <div class="left-content">
                      <div class="header">
                      <h3>{t('3DModelText_outsideAccordianCreateQuote')}</h3>
                      </div>
                      <div class="model">
                        {outsideItem?.map((item) => (
                          <div class="outsideIteminputfields">
                            <input
                            className="outSelOffrInput"
                              type="checkbox"
                              disabled
                              value={item.id}
                              checked={productData
                                .map((product) => product.outsideProduct.id)
                                .includes(item.id)}
                            />
                            <label for="Roof">{item.name}</label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-10 col-md-10 col-sm-10 tableDspEdit">
                    <div className="left-content ">
                      <table className="dspTable">
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th style={AlignCenter}>Area(m2)</th>
                            <th style={AlignCenter}>Hours</th>
                            <th style={AlignCenter}>Rate(€)</th>
                            <th style={AlignRight}>Total(€)</th>
                          </tr>
                        </thead>
                        <tbody>
                        
                            {offerDetails.selectedOutsideProductOffer && offerDetails.selectedOutsideProductOffer.outsideProductOffers.map((item)=>(
                            <tr>
                              <td>{item.quoteOutsideProduct.outsideProduct.name}</td>
                              <td style={AlignCenter}>{item.area}</td>
                              <td style={AlignCenter}>{item.hours}</td>
                              <td style={AlignCenter}>{new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(item.ratePerHour)}</td>
                              <td style={AlignRight}>{new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format((item.hours)*(item.ratePerHour))}</td>
                            
                              </tr>
                            ))}
                         
                        </tbody>
                      </table>

                      <p className="commissionOutTag">{t('commisions_partnerAccord')}- {offerDetails.selectedOutsideProductOffer && offerDetails.selectedOutsideProductOffer.commission}%</p>
                      <p className="commissionOutTag">{t('totalCost_outsideAccordian')} - {new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(calculateTotalCost(offerDetails.selectedOutsideProductOffer))} ({t('commissionsIncluded_outsideAccordian')})</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
    </div>
  );
};
