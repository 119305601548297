import React, { useEffect, useState, useRef } from "react";
import api from "../../../helper/auth_interceptor";
import { Circles } from "react-loader-spinner";
import plus from "../../../images/Frame.png";
import { useTranslation } from 'react-i18next';
import home from "../../../images/home.png";
import rightArrow from "../../../images/right-arrow.png";
import { getUser } from "../../../helper/auth_helper";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import hamburger from "../../../images/hamburger.jpg";
import { InsideProductAccord } from "./components/insideProductAccordian/insideProduct";
import { InsideServiceAccord } from "./components/insideServiceAccordian/insideService";
import { Outside } from "./components/outsideAccordian/outsideAccordian";
import { OutsideExtrasAccord } from "./components/outsideExtrasAccordian/outsideExtras";
import { OutsideInspection } from "./components/outsideInspectionAccordian/outsideInspection";
import { OutsidePurpose } from "./components/outsidePurposeAccordian/outsidePurpose";
import { ProtectedSidebar } from "../../sidebar/protectedSidebar";
import { Planning } from "./components/planning/planning";
import { ProgressBar } from "./components/progressBar/progressBar";
import { QuoteDetails } from "./components/quoteAccordian/quoteAccordian";
import { Workflow } from "./components/workflow/workflow";
import { Partner } from "./components/partnerAccordian/partnerAccordian";
import { toast } from "react-toastify";

const defaultQuoteDetails = {
  clientName: "",
  contact: "",
  projectId: "",
};

const defaultExtras = {
  name: "",
  price: "",
};

const defaultPlanning = {
  desiredInternalDeliveryDateBegin: "",
  desiredInternalDeliveryDateEnd: "",
  // internalMissionStartDateBegin: "",
  // internalMissionStartDateEnd: "",
  desiredOutsideDeliveryDateBegin: "",
  desiredOutsideDeliveryDateEnd: "",
  // outsideMissionStartDateBegin: "",
  // outsideMissionStartDateEnd: "",
  desiredInspectionDeliveryDateBegin: "",
  desiredInspectionDeliveryDateEnd: "",
  // inspectionMissionStartDateBegin: "",
  // inspectionMissionStartDateEnd: "",
};

const defaultErrorDates = {
  desiredInternalDeliveryDate: "",

  // internalMissionStartDate: "",

  desiredOutsideDeliveryDate: "",

  // outsideMissionStartDate: "",

  desiredInspectionDeliveryDate: "",

  // inspectionMissionStartDate: "",

};



const defaultScanOption = {
  productType: "",
};

function ExternalSp({
  suprmenAdmin,
  orgAdmin,
  viewer,
  regular,
  newQuoteDetails,
  setNewQuoteDetails,
  inpectionSelected,

  quotePartners,
}) {
  const params = useParams();
  const navigate = useNavigate();

  // const [newQuoteData, setNewQuoteData] = useState(defaultQuoteData)
  const [newData, setNewData] = useState(newQuoteDetails);
  const [quoteDetails, setQuoteDetails] = useState(defaultQuoteDetails);
  const [planningDetails, setPlanningDetails] = useState(defaultPlanning);
  const [scanOption, setScanOption] = useState(defaultScanOption);
  const [outsideProduct, setOutsideProduct] = useState([]);
  const [outsideProductTotal, setOutsideProductTotal] = useState();
  const [insideServices, setInsideServices] = useState({});
  const [insideServicesTotal, setInsideServicesTotal] = useState();
  const [insideProduct, setInsideProduct] = useState([]);
  const [outsideInspection, setOutsideInspection] = useState({});
  const [outsideInspectionTotal, setOutsideInspectionTotal] = useState();
  const [outsidePurpose, setOutsidePurpose] = useState([]);
  const [outsideExtras, setOutsideExtras] = useState({});
  const [ousideExtrasTotal, setOutsideExtrasTotal] = useState();
  const [otherPurposeText, setOtherPurposeText] = useState("");
  const [loading, setLoading] = useState(false);
  const [partnerResponse, setPartnerResponse] = useState([]);

  const [dateCheck, setDateCheck] = useState(false);
  const [grandTotal, setGrandTotal] = useState();
  const { t } = useTranslation();

  const partnerList = [];
  const partnerStatusList = [];
  const [componentCompletion, setComponentCompletion] = useState({
    quoteDetails: false,
    scanOption: false,
    outside: false,
    outsidePurpose: false,
    outsideInspection: false,
    outsideExtras: false,
    insideService: false,
    insideProduct: false,
    planning: false,
  });
  const [isInternalPartner, setIsInternalPartner] = useState(false);
  const [isExternalPartner, setExternalPartner] = useState(false);
  const [isInspectionPartner, setInspectionPartner] = useState(false);
  const [offerRecieved, setOfferRecieved] = useState(false);
  const [dateErrors, setDateErrors] = useState(defaultErrorDates);


  const format1 = "DD-MM-YYYY";

  async function quotePartnerDetails() {
    const user = await getUser();

    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/quote/quotePartnersByUserEmail/${params.id}`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try {
      const response = await api.get(apiUrl, { headers });

      setPartnerResponse(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const mappedItems =
    partnerResponse && partnerResponse.map((item) => item.partnerType);
  partnerList.push(...mappedItems);
  const mappedStatus =
    partnerResponse && partnerResponse.map((item) => item.quotePartnerStatus);
  partnerStatusList.push(...mappedStatus);

  const isExternal = partnerList.includes("DRONE_OPERATOR");
  const isInternal = partnerList.includes("INTERNAL_PARTNER");
  const isInspection = partnerList.includes("INSPECTION_PARTNER");
  const isOfferReceived = partnerStatusList.includes("OFFER_SENT");

  const collectDataAndUpdateState = (data) => {
    setOutsideExtras(data);
  };

  const collectDataInspection = (data) => {
    setOutsideInspection(data);
  };

  const collectDataInsideServices = (data) => {
    setInsideServices(data);
  };

  const collectDataOutsideAccordian = (data) => {
    setOutsideProduct(data);
  };




  const checkMissingDates = () => {
    const errors = { ...defaultErrorDates };


    if (!planningDetails.desiredOutsideDeliveryDateBegin) {
      errors.desiredOutsideDeliveryDate = `${t('required_errorMessage')}`;
    }
    if (!planningDetails.desiredOutsideDeliveryDateEnd) {
      errors.desiredOutsideDeliveryDate = `${t('required_errorMessage')}`;
    }

    if (!planningDetails.desiredInternalDeliveryDateBegin) {
      errors.desiredInternalDeliveryDate = `${t('required_errorMessage')}`;
    }
    if (!planningDetails.desiredInternalDeliveryDateEnd) {
      errors.desiredInternalDeliveryDate = `${t('required_errorMessage')}`;
    }

    if (!planningDetails.desiredInspectionDeliveryDateBegin) {
      errors.desiredInspectionDeliveryDate = `${t('required_errorMessage')}`;
    }
    if (!planningDetails.desiredInspectionDeliveryDateEnd) {
      errors.desiredInspectionDeliveryDate = `${t('required_errorMessage')}`;
    }

    setDateErrors(errors);
  };

  const [calculatedOutsideNullItems, setCalculatedOutsideNullItems] = useState([]);


  const [extrasNullItems, setExtrasNullItems] = useState([]);
  const [InspectonNullItems, setInspectonNullItems] = useState([]);
  const [InsideNullItems, setInsideNullItems] = useState([]);


  const handleSubmit = async () => {
    setCalculatedOutsideNullItems(outsideProduct.filter(item => Object.values(item).some(value => value === null)));
    setExtrasNullItems(Object.keys(outsideExtras).filter(
      key => outsideExtras[key] === null
    ));
    setInspectonNullItems(Object.keys(outsideInspection).filter(
      key => outsideInspection[key] === null
    ));
    setInsideNullItems(Object.keys(insideServices).filter(
      key => insideServices[key] === null
    ))
    const user = await getUser();

    checkMissingDates();

    const requestBody = {
      partnerOrgId: newQuoteDetails.project.organization.id,
      quoteId: Number(params.id),

      quoteExtraServices: {
        servicePriceMap: outsideExtras,
        totalCost: ousideExtrasTotal,
      },
      quoteInspectionServices: {
        servicePriceMap: outsideInspection,
        totalCost: outsideInspectionTotal,
      },
      quoteInsideServices: {
        servicePriceMap: insideServices,
        totalCost: insideServicesTotal,
      },
      outsideServicesRequest: {
        outsideServicesParameters: outsideProduct,
        totalCost: grandTotal,
      },
    };

    if (isExternalPartner) {
      requestBody.desiredOutsideDeliveryDateBegin = moment(
        planningDetails.desiredOutsideDeliveryDateBegin
      ).format("DD-MM-YYYY");
      requestBody.desiredOutsideDeliveryDateEnd = moment(
        planningDetails.desiredOutsideDeliveryDateEnd
      ).format("DD-MM-YYYY");
    }

    if (isInternalPartner) {
      requestBody.desiredInternalDeliveryDateBegin = moment(
        planningDetails.desiredInternalDeliveryDateBegin
      ).format("DD-MM-YYYY");
      requestBody.desiredInternalDeliveryDateEnd = moment(
        planningDetails.desiredInternalDeliveryDateEnd
      ).format("DD-MM-YYYY");
    }

    if (isInspectionPartner) {
      requestBody.desiredInspectionDeliveryDateBegin = moment(
        planningDetails.desiredInspectionDeliveryDateBegin
      ).format("DD-MM-YYYY");
      requestBody.desiredInspectionDeliveryDateEnd = moment(
        planningDetails.desiredInspectionDeliveryDateEnd
      ).format("DD-MM-YYYY");
    }

    if (isExternalPartner) {
      if (
        !planningDetails.desiredOutsideDeliveryDateBegin ||
        !planningDetails.desiredOutsideDeliveryDateEnd
      ) {
        setDateCheck(true);

        return;
      } else {
        setDateCheck(false);
      }
    }

    if (isInternalPartner) {
      if (
        !planningDetails.desiredInternalDeliveryDateBegin ||
        !planningDetails.desiredInternalDeliveryDateEnd
      ) {
        setDateCheck(true);

        return;
      } else {
        setDateCheck(false);
      }
    }

    if (isInspectionPartner) {
      if (
        !planningDetails.desiredInspectionDeliveryDateBegin ||
        !planningDetails.desiredInspectionDeliveryDateEnd
      ) {
        setDateCheck(true);

        return;
      } else {
        setDateCheck(false);
      }
    }


    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/quote/submitOffer/${params.id}`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    setLoading(true);
    try {
      const response = await api.post(apiUrl, requestBody, { headers });

      if (response.status === 200) {
        navigate("/quoteview");
        toast.success(`${t('quoteUpdated')}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    quotePartnerDetails();
  }, []);

  useEffect(() => {
    setExternalPartner(isExternal);
    setIsInternalPartner(isInternal);
    setInspectionPartner(isInspection);
    setOfferRecieved(isOfferReceived);
  }, [
    partnerResponse,
    partnerList,
    partnerStatusList,
    isExternal,
    isInternal,
    isInspection,
    isOfferReceived,
  ]);


  return (
    <div className={`main-wrap ${loading ? "blur" : ""}`}>
      {loading && (
        <div className="loader-overlay">
          <Circles type="TailSpin" color="#FF6B00" height={150} width={100} />
        </div>
      )}
      <div className="quote-body">
        <input id="slide-sidebar" type="checkbox" role="button" />
        <label for="slide-sidebar">
          <span>
            <i class="fa fa-bars">
              <img class="sidebar-toggle" src={hamburger}></img>
            </i>
          </span>
        </label>
        <div class="sidebar">
          <div class="sidebar-content">
            <div>
              <ProtectedSidebar />
            </div>
          </div>
        </div>
        <div class="portfolio">
          <div class="main-content">
            <div class="main-header-content">
              <div className="pageHeader">
                <h1>{t('quoteCreation_quoteCreation')}</h1>
              </div>
              <div class="user">
                {" "}
                <button
                  className="btn btn-light quoteBtns rqstbtn "
                  type="button"
                >
                  {" "}
                  <img src={home} alt="home icon" />{" "}
                  <img src={rightArrow} alt="right arrow icon" />{" "}
                  <span>{t('requestForQuotes_quoteCreation')}</span>{" "}
                </button>{" "}
              </div>
            </div>
            <div>
              <div className="page-body">
                <div className="formDivquote">

                  <div class="main-record">
                    <Workflow id={params.id} />
                    <div class="quote-details-record">
                      <div class="quote-details">
                        <QuoteDetails
                          quoteDetails={quoteDetails}
                          setQuoteDetails={(quoteDetails) => {
                            setQuoteDetails(quoteDetails);
                          }}
                          newQuoteDetails={newQuoteDetails}
                          setNewQuoteDetails={(newQuoteDetails) => {
                            setNewQuoteDetails(newQuoteDetails);
                          }}
                          suprmenAdmin={suprmenAdmin}
                          setComponentCompletion={(status) => {
                            setComponentCompletion({
                              ...componentCompletion,
                              quoteDetails: status,
                            });
                          }}
                        />
                      </div>
                    </div>
                    {(offerRecieved ||
                      newQuoteDetails?.quoteStatusType ===
                      "QUOTE_FORWARDED") && (
                        <div class="quote-scan-record ">
                          <div class="quote-details">
                            <Partner
                              setNewQuoteDetails={(newQuoteDetails) =>
                                setNewQuoteDetails(newQuoteDetails)
                              }
                              inpectionSelected={inpectionSelected}
                              id={params.id}
                              newQuoteDetails={newQuoteDetails}
                              quotePartners={quotePartners}
                              offerRecieved={offerRecieved}
                            />
                          </div>
                        </div>
                      )}

                    {isExternalPartner && (
                      <div>
                        <div class="quote-outside">
                          <div class="quote-details">
                            <Outside
                              orgId={newQuoteDetails.project.organization.id}
                              outsideProduct={outsideProduct}
                              setOutsideProduct={(outsideProduct) => {
                                setOutsideProduct(outsideProduct);
                              }}
                              setGrandTotal={setGrandTotal}
                              collectDataOutsideAccordian={
                                collectDataOutsideAccordian
                              }
                              setOutsideProductTotal={setOutsideProductTotal}
                              newQuoteDetails={newQuoteDetails}
                              partnerStatusList={partnerStatusList}
                              setNewData={(newData) => {
                                setNewData(newData);
                              }}
                              setComponentCompletion={(status) => {
                                setComponentCompletion({
                                  ...componentCompletion,
                                  outside: status,
                                });
                              }}
                              nullItems={calculatedOutsideNullItems}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    {isInspectionPartner && (
                      <>
                        <div class="quote-option-record">
                          <div class="quote-details">
                            <OutsidePurpose
                              outsidePurpose={outsidePurpose}
                              setOutsidePurpose={(outsidePurpose) => {
                                setOutsidePurpose(outsidePurpose);
                              }}
                              newQuoteDetails={newQuoteDetails}
                              setNewQuoteDetails
                              otherPurposeText={otherPurposeText}
                              setComponentCompletion={(status) => {
                                setComponentCompletion({
                                  ...componentCompletion,
                                  outsidePurpose: status,
                                });
                              }}

                            />
                          </div>
                        </div>
                        <div class="quote-outside-extras-record">
                          <div class="quote-details">
                            <OutsideExtrasAccord
                              outsideExtras={outsideExtras}
                              setOutsideExtras={(outsideExtras) => {
                                setOutsideExtras(outsideExtras);
                              }}
                              newQuoteDetails={newQuoteDetails}
                              setComponentCompletion={(status) => {
                                setComponentCompletion({
                                  ...componentCompletion,
                                  outsideExtras: status,
                                });
                              }}
                              partnerStatusList={partnerStatusList}
                              setOutsideExtrasTotal={setOutsideExtrasTotal}
                              collectDataAndUpdateState={
                                collectDataAndUpdateState
                              }
                              nullItems={extrasNullItems}
                            />
                          </div>
                        </div>
                      </>
                    )}

                    {newQuoteDetails.quoteOutsideInspections.length > 0 ? (
                      <div class="quote-outside-record">
                        <div class="quote-details">
                          <OutsideInspection
                            outsideInspection={outsideInspection}
                            setOutsideInspection={(outsideInspection) => {
                              setOutsideInspection(outsideInspection);
                            }}
                            setOutsideInspectionTotal={
                              setOutsideInspectionTotal
                            }
                            collectDataInspection={collectDataInspection}
                            newQuoteDetails={newQuoteDetails}
                            setComponentCompletion={(status) => {
                              setComponentCompletion({
                                ...componentCompletion,
                                outsideInspection: status,
                              });
                            }}
                            partnerStatusList={partnerStatusList}
                            nullItems={InspectonNullItems}

                          />
                        </div>
                      </div>
                    ) : null}

                    {isInternalPartner && (
                      <div>
                        <div class="quote-inside-summary-record">
                          <div class="quote-details">
                            <InsideProductAccord
                              insideProduct={insideProduct}
                              setInsideProduct={(insideProduct) => {
                                setInsideProduct(insideProduct);
                              }}
                              newQuoteDetails={newQuoteDetails}
                              setComponentCompletion={(status) => {
                                setComponentCompletion({
                                  ...componentCompletion,
                                  insideProduct: status,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div class="quote-inside-services-record">
                          <div class="quote-details">
                            <InsideServiceAccord
                              insideServices={insideServices}
                              setInsideServices={(insideServices) => {
                                setInsideServices(insideServices);
                              }}
                              collectDataInsideServices={
                                collectDataInsideServices
                              }
                              setInsideServicesTotal={setInsideServicesTotal}
                              newQuoteDetails={newQuoteDetails}
                              setComponentCompletion={(status) => {
                                setComponentCompletion({
                                  ...componentCompletion,
                                  insideServices: status,
                                });
                              }}
                              partnerStatusList={partnerStatusList}
                              nullItems={InsideNullItems}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    <div class="quote-planning-record ">
                      <div class="quote-details">
                        <Planning
                          planningDetails={planningDetails}
                          setPlanningDetails={(planningDetails) => {
                            setPlanningDetails(planningDetails);
                          }}
                          partnerStatusList={partnerStatusList}
                          quotePartners={quotePartners}
                          newQuoteDetails={newQuoteDetails}
                          setComponentCompletion={(status) => {
                            setComponentCompletion({
                              ...componentCompletion,
                              planning: status,
                            });
                          }}
                          dateCheck={dateCheck}
                          dateErrors={dateErrors}
                        />
                      </div>
                    </div>
                    {!offerRecieved && (
                      <div class="bottom-button right">
                        <button
                          class="btn-cancel"
                          disabled=""
                          onClick={() => navigate("/quoteview")}
                        >
                          {" "}
                          <span>{t('cancel')}</span>
                        </button>

                        <button
                          type="submit"
                          class="btn-save"
                          onClick={() => handleSubmit()}
                        >
                          {" "}
                          <span>{t('submit')} </span>{" "}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExternalSp;
