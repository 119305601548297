import React, { useState, useEffect } from "react";
import bin from "../../../../images/bin.png";
import api from "../../../../helper/auth_interceptor";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import "./buildAddress.modules.css";
import { getUser } from "../../../../helper/auth_helper";
import { toast } from "react-toastify";
import EditModal from "./editModal";
import BuildingInformaton from "../buildingInformation/buildingInformation";
import Modal from "react-modal";
import { CancelOutlined, Download } from "@mui/icons-material";
import BuildDocUpload from "./buildDocUpload";
import { saveAs } from "file-saver";

const BuildAddress = ({
  id,
  orgId,
  suprmenAdmin,
  project,
  getBuildingCount,
}) => {
  const [buildingAddress, setBuildingAddress] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editedBuilding, setEditedBuilding] = useState(null);

  const getBuildAddresses = async () => {
    const user = await getUser();
    const getApi = `${process.env.REACT_APP_API_GATEWAY_URL}/project-management/api/v1/buildings/project/${id}`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    try {
      const response = await api.get(getApi, { headers });

      setBuildingAddress(response.data);
      getBuildingCount();
    } catch (error) {
      console.log("Error:", error);
    }
  };
  const { t } = useTranslation();
  const [omnibaseUrl, setOmnibaseUrl] = useState("");
  const getVisualizationLink = async () => {
    const user = await getUser();
    const getApi = `${process.env.REACT_APP_API_GATEWAY_URL}/visualization-management/api/get-omnibase-link`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    try {
      const response = await api.get(getApi, { headers });

      setOmnibaseUrl(response.data);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleDownload = async (docId, fileName) => {
    const user = await getUser();
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/project-management/api/v1/buildings/download-document?documentId=${docId}`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    try {
      const response = await api.get(apiUrl, { responseType: "blob", headers });

      if (response) {
        const blob = new Blob([response.data], { type: response.data.type });

        saveAs(blob, fileName, { autoBom: true });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const downloadAnnotations = async (project_uuid) => {
    const user = await getUser();
    const token = user.access_token;
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/visualization-management/api/annotations/download/${project_uuid}`;
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/octet-stream",
        Authorization: `Bearer ${token}`,
      },
    });

    try {
      if (response) {
        const blob = await response.blob();
        saveAs(blob, `Annotation_${project_uuid}_${new Date()}.xlsx`);
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(`${t("deleteBuilding_popUpAlert")}`);
    if (confirmDelete) {
      const user = await getUser();
      const delApi = `${process.env.REACT_APP_API_GATEWAY_URL}/project-management/api/v1/buildings/${id}`;
      const headers = {
        Authorization: `Bearer ${user.access_token}`,
      };
      try {
        await api.delete(delApi, { headers });
        toast.success(`${t("buildingDeleted")}`);
        getBuildAddresses();
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    }
  };

  const handleDocDelete = async (id) => {
    const user = await getUser();
    const token = user.access_token;

    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/project-management/api/v1/buildings/delete-document?documentId=${id}`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const response = await api.post(apiUrl, "", { headers });
      if (response) {
        toast.success(`${t("buildingDocDeleted")}`);
        getBuildAddresses();
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const openEditModal = (building) => {
    setEditedBuilding(building);
    setIsModalOpen(true);
  };

  const closeEditModal = () => {
    setIsModalOpen(false);
  };

  const [orgDetails, setOrgDetails] = useState(null);
  const getOrgDetails = async () => {
    const user = await getUser();
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/organization/${orgId}`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try {
      const response = await api.get(apiUrl, {
        headers,
      });
      setOrgDetails(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getBuildAddresses();
    getOrgDetails();
    getVisualizationLink();
  }, []);

  const getFileIcon = (fileName) => {
    const extension = fileName.split(".").pop().toLowerCase();
    switch (extension) {
      case "pdf":
        return (
          <img
            width="25"
            height="22"
            src="https://img.icons8.com/color/48/pdf.png"
            alt="pdf"
          />
        );
      case "doc":
        return (
          <img
            width="25"
            height="25"
            src="https://img.icons8.com/ultraviolet/40/doc.png"
            alt="doc"
          />
        );
      case "docx":
        return (
          <img
            width="25"
            height="25"
            src="https://img.icons8.com/color/48/ms-word.png"
            alt="ms-word"
          />
        );
      case "xls":
        return (
          <img
            width="25"
            height="25"
            src="https://img.icons8.com/fluency/48/ms-excel.png"
            alt="ms-excel"
          />
        );
      case "csv":
        return (
          <img
            width="25"
            height="25"
            src="https://img.icons8.com/fluency/48/ms-excel.png"
            alt="ms-excel"
          />
        );
      case "xlsx":
        return (
          <img
            width="25"
            height="25"
            src="https://img.icons8.com/fluency/48/ms-excel.png"
            alt="ms-excel"
          />
        );
      case "ppt":
        return (
          <img
            width="25"
            height="25"
            src="https://img.icons8.com/color/48/ms-powerpoint--v1.png"
            alt="ms-powerpoint--v1"
          />
        );
      case "pptx":
        return (
          <img
            width="25"
            height="25"
            src="https://img.icons8.com/color/48/ms-powerpoint--v1.png"
            alt="ms-powerpoint--v1"
          />
        );
      default:
        return "📄";
    }
  };

  const organizationTypeIsDSP =
    orgDetails?.organizationType === "DRONEOPERATOR";

  // Function to convert byte data to URL
  const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1];
        resolve(base64String);
      };
      reader.onerror = reject;
      reader.readAsDataURL(new Blob([blob]));
    });
  };

  return (
    <>
      <div className="main-form-head">
        <div className="form-head">
          <div className="form-head list-address-build">
            <div>
              <h2 className="pageHeading">{t("tabFourHeader_buildAddress")}</h2>
            </div>
            <BuildingInformaton
              project={project}
              projectId={id}
              orgId={orgId}
              reloadAdd={getBuildAddresses}
            />
          </div>
        </div>
      </div>
      <div className="bulk-address-data">
        <div class="bulk-address-data-table">
          <table>
            <thead>
              <tr>
                <th>{t("view3dmodel_editProjectTable")}</th>
                <th>{t("buildingName_editProjectTable")} </th>
                <th>{t("pandId_editProjectTable")}</th>
                <th>{t("streetName_editProjectTable")}</th>
                <th>{t("city_editProjectTable")} </th>
                <th>{t("postCode_editProjectTable")} </th>
                <th>{t("country_editProjectTable")} </th>
                {suprmenAdmin && <th>{t("annotations_editProjectTable")}</th>}
                <th>{t("documents_editProjectTable")}</th>
                <th>{t("babyModel_editProjectTable")}</th>
                <th>{t("delete_editProjectTable")}</th>
              </tr>
            </thead>
            <tbody>
              {buildingAddress &&
                buildingAddress &&
                buildingAddress.map((building) => (
                  <tr key={building.id}>
                    {building.omniBaseProjectId !== null ? (
                      <td>
                        <button className="viewExploreButton">
                        <a
                          href={
                            building.project?.id === 159
                              ? `https://bimpartners.iv.navvis.com/?site=3440531431214893&vlon=3.09&vlat=-0.21&fov=100.0&image=1533100006851755`
                              : `${omnibaseUrl}/projects/${building.omniBaseProjectId}/view?org_uuid=${project?.omnibaseOrgId}`
                          }
                          target="_blank"
                        >
                          {t('exploreIn3D')}
                        </a>
                        </button>
                      </td>
                    ) : (
                      <td style={{ color: "grey" }}>
                          <button className="defaultExploreButton">
                          <a>{t('exploreIn3D')}</a>
                          </button>
                      </td>
                    )}
                    <td>
                      {!project.readOnly ? (
                        <a
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={() => openEditModal(building)}
                        >
                          {building.name}
                        </a>
                      ) : (
                        <p>{building.name}</p>
                      )}
                       
                    </td>
                    <td>{building.pandId}</td>
                    <td>{building.streetName}</td>
                    <td>{building.city}</td>
                    <td>{building.postcode}</td>
                    <td>{building.country}</td>
                    {suprmenAdmin && (
                      <td>
                        <Download
                          onClick={() =>
                            downloadAnnotations(building?.omniBaseProjectId)
                          }
                          style={{ color: "grey" }}
                        />
                      </td>
                    )}
                    <td>
                      {building.projectBuildingDocuments.length === 2 && (
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-evenly",
                            }}
                          >
                            <span
                              onClick={() =>
                                handleDownload(
                                  building.projectBuildingDocuments[0].id,
                                  building.projectBuildingDocuments[0].docName
                                )
                              }
                              style={{ color: "grey" }}
                              title={
                                building.projectBuildingDocuments[0].docName
                              }
                            >
                              {getFileIcon(
                                building.projectBuildingDocuments[0].docName
                              )}
                            </span>
                            {suprmenAdmin && (
                              <CancelOutlined
                                style={{
                                  color: "grey",
                                  marginTop: "4px",
                                  height: "15px",
                                  width: "15px",
                                }}
                                onClick={() =>
                                  handleDocDelete(
                                    building.projectBuildingDocuments[0].id
                                  )
                                }
                              />
                            )}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-evenly",
                            }}
                          >
                            <span
                              onClick={() =>
                                handleDownload(
                                  building.projectBuildingDocuments[1].id,
                                  building.projectBuildingDocuments[1].docName
                                )
                              }
                              style={{ color: "grey" }}
                              title={
                                building.projectBuildingDocuments[1].docName
                              }
                            >
                              {getFileIcon(
                                building.projectBuildingDocuments[1].docName
                              )}
                            </span>
                            {suprmenAdmin && (
                              <CancelOutlined
                                style={{
                                  color: "grey",
                                  marginTop: "4px",
                                  height: "15px",
                                  width: "15px",
                                }}
                                onClick={() =>
                                  handleDocDelete(
                                    building.projectBuildingDocuments[1].id
                                  )
                                }
                              />
                            )}
                          </div>
                        </div>
                      )}

                      <div>
                        {building.projectBuildingDocuments.length === 1 && (
                          <div style={{ display: "flex" }}>
                            {suprmenAdmin && (
                              <div>
                                <BuildDocUpload
                                  projectId={id}
                                  buildingId={building.id}
                                />
                              </div>
                            )}

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                              }}
                            >
                              <span
                                onClick={() =>
                                  handleDownload(
                                    building.projectBuildingDocuments[0].id,
                                    building.projectBuildingDocuments[0].docName
                                  )
                                }
                                style={{ color: "grey" }}
                                title={
                                  building.projectBuildingDocuments[0].docName
                                }
                              >
                                {getFileIcon(
                                  building.projectBuildingDocuments[0].docName
                                )}
                              </span>
                              {suprmenAdmin && (
                                <CancelOutlined
                                  style={{
                                    color: "grey",
                                    marginTop: "4px",
                                    height: "15px",
                                    width: "15px",
                                  }}
                                  onClick={() =>
                                    handleDocDelete(
                                      building.projectBuildingDocuments[0].id
                                    )
                                  }
                                />
                              )}
                            </div>
                          </div>
                        )}
                        {building.projectBuildingDocuments.length === 0 &&
                          suprmenAdmin && (
                            <div style={{ display: "flex" }}>
                              <BuildDocUpload
                                projectId={id}
                                buildingId={building.id}
                                style={{ marginRight: "15px" }}
                              />
                              <BuildDocUpload
                                projectId={id}
                                buildingId={building.id}
                              />
                            </div>
                          )}
                      </div>
                    </td>
                    <td>
                      {building.babyModel ? (
                        <img
                          src={`data:image/png;base64,${building.babyModel}`}
                          style={{ width: "30px", height: "30px" }}
                        />
                      ) : (
                        <></>
                      )}
                    </td>
                    <td>
                      {!project.readOnly ? (
                        <button
                          className="btn btn-light actionBtns"
                          onClick={() => handleDelete(building.id)}
                        >
                          <img src={bin} alt="Delete" />
                        </button>
                      ) : (
                        <button className="btn btn-light actionBtns" disabled>
                          <img src={bin} alt="Delete" />
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          ariaHideApp={false}
          style={{
            content: {
              width: "80%",
              height: "60%",
              margin: "auto",
            },
          }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <div>
                <h2>{t("upDateBuildingDetails")}</h2>
              </div>
              <div>
                <button
                  className="closeBtn"
                  onClick={() => setIsModalOpen(false)}
                >
                  x
                </button>
              </div>
            </div>
          </div>
          <EditModal
            id={id}
            orgId={orgId}
            editedBuilding={editedBuilding}
            closeModal={closeEditModal}
            reloadAdd={getBuildAddresses}
          />
        </Modal>
      </div>
    </>
  );
};

export default BuildAddress;
