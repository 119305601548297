import React from "react";
import { useTranslation } from 'react-i18next';
import checkmark from "../../../../images/checkmark.png";
import "./progressBar.modules.css";

//progress bar is depends upon a boolean for component completion from each tab 
export const ProgressBar = ({ componentCompletion, scanOption, inpectionSelected }) => {
  const { t } = useTranslation();
  
  return (
    <div class="timebar-content">
      <div class="sub-content">
        <input
          type="checkbox"
          checked={componentCompletion.quoteDetails}
          disabled
        />
        <label>
          <h6>{t('quoteDetails_progressBarQuote')}</h6>
        </label>
      </div>

      <div class="sub-content">
        <input
          type="checkbox"
          checked={componentCompletion.scanOption}
          disabled
        />
        <label>
          <h6>{t('selectScanOption_progressBarQuote')}</h6>
        </label>
      </div>

      {(scanOption.productType === "OUTSIDE" ||
        scanOption.productType === "BOTH") && (
        <>
          <div class="sub-content">
            <input
              type="checkbox"
              checked={componentCompletion.outside}
              disabled
            />
            <label>
              <h6>{t('exterior_progressBarQuote')}</h6>
            </label>
          </div>
          <div class="sub-content">
            <input
              type="checkbox"
              checked={componentCompletion.outsidePurpose}
              disabled
            />
            <label>
              <h6>{t('exteriorPurpose_progressBarQuote')}</h6>
            </label>
          </div>
          <div class="sub-content">
            <input
              type="checkbox"
              checked={componentCompletion.outsideExtras}
              disabled
            />
            <label>
              <h6>{t('exteriorExtras_progressBarQuote')}</h6>
            </label>
          </div>
          {inpectionSelected ? (
            <div class="sub-content">
            <input
              type="checkbox"
              checked={componentCompletion.outsideInspection}
              disabled
            />
            <label>
              <h6>{t('exteriorInspectionReport_progressBarQuote')}</h6>
            </label>
          </div>

          ): null}
          
        
        </>
      )}
{(scanOption.productType === "INSIDE" ||
        scanOption.productType === "BOTH") && (
          <>
            <div class="sub-content">
        <input
          type="checkbox"
          checked={componentCompletion.insideProduct}
          disabled
        />
        <label>
          <h6>{t('interiorProduct_progressBarQuote')}</h6>
        </label>
      </div>
           <div class="sub-content">
        <input
          type="checkbox"
          checked={componentCompletion.insideServices}
          disabled
        />
        <label>
          <h6>{t('interiorService_progressBarQuote')}</h6>
        </label>
      </div>

    
          </>
        )}
    
      <div class="sub-content">
        <input
          type="checkbox"
          checked={componentCompletion.planning}
          disabled
        />
        <label>
          <h6>{t('heading_planningQuote')}</h6>
        </label>
      </div>
    </div>
  );
};
