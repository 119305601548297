import React, { useState, useEffect } from "react";
import { getUser } from "../../helper/auth_helper";
import {
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TableContainer,
} from "@mui/material";
import { Link, useNavigate, Outlet } from "react-router-dom";
import "../../App.css";
import SendEmail from "./Email"; // Import the Pop component

const Notification = () => {
  const [items, setItems] = useState([]);
  const [itemsCount, setItemsCount] = useState(0);
  const [controller, setController] = useState({
    pageNumber: 0,
    pageSize: 10,
  });
  const [count, setCount] = useState(0);
  const [user, setUser] = useState(null);
  const navigate = useNavigate(); // Hook to navigate programmatically
  const [isSendEmail, setIsSendEmail] = useState(false); // State for controlling the visibility of the pop-up

  const getData = async (userData) => {
    const url = `${process.env.REACT_APP_API_GATEWAY_URL}/platform-notifications`;

    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${userData.access_token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();

        setItems(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUser().then((user) => {
      if (user) {
        setUser(user);
        getData(user);
      }
    });
  }, [controller]);

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      pageNumber: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setController({
      ...controller,
      pageSize: parseInt(event.target.value, 10),
      pageNumber: 0,
    });
  };

  const handleQuoteSelection = (quote) => {
    if (quote.editAllowed && quote.emailNotificationAllowed) {
      handleSendEmail(quote);
    }
  };

  const handleSendEmail = (item) => {
    navigate("/email", { state: { status: item.status } });
  };

  return (
    <Card className="notification-card">
      <div className="scrollable-container">
        <div className="section">
          <h2>Quote</h2>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Quote Id</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Date/Time</TableCell>
                <TableCell>Send Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.objectId}>
                  <TableCell>
                    <Link to={`/quote/${item.objectId}`}>{item.objectId}</Link>
                  </TableCell>
                  <TableCell>{item.status}</TableCell>
                  <TableCell>{item.dateTime}</TableCell>
                  <TableCell>
                    {item.editAllowed && item.emailNotificationAllowed && (
                      <button
                        className="togglebtn btn btn-primary"
                        onClick={() => handleSendEmail(item)}
                      >
                        Send Email
                      </button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            onPageChange={handlePageChange}
            page={controller.pageNumber}
            count={itemsCount}
            rowsPerPage={controller.pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
        <div className="separator" />
        <div className="section">
          <h2>Project</h2>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Project Id</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Date/Time</TableCell>
                <TableCell>Send Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.objectId}>
                  {/* <TableCell>{item.objectId}</TableCell>
                  <TableCell>{item.status}</TableCell>
                  <TableCell>{item.dateTime}</TableCell>
                  <TableCell>
                  {item.editAllowed && item.emailNotificationAllowed && (
                  <button onClick={() => handleSendEmail(item)}>Send</button>
                  )}
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            onPageChange={handlePageChange}
            page={controller.pageNumber}
            count={itemsCount}
            rowsPerPage={controller.pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
      <Outlet /> {/* Render nested routes */}
      {/* Render the Pop component as a modal */}
      {isSendEmail && <SendEmail setModal={setIsSendEmail} />}
    </Card>
  );
};

export default Notification;
