/* eslint-disable no-undef */
import React, { useState, useEffect, useRef } from "react";
import api from "../../helper/auth_interceptor";
import { useTranslation } from "react-i18next";
import rightArrow from "../../images/right-arrow.png";
import home from "../../images/home.png";
import { getUser, login } from "../../helper/auth_helper";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./quoteView.modules.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import hamburger from "../../images/hamburger.jpg";
import { QuoteTable } from "./components/quoteListTable";
import { PaginationTool } from "../../utility/pagination";
import { QuoteFilter } from "./components/quoteListFilter";
import { ProtectedSidebar } from "../sidebar/protectedSidebar";

function QuoteView({ suprmenAdmin, orgAdmin, viewer, regular }) {
//constants and state declarations

  const [showCalendar, setShowCalendar] = useState(false);

  const [selectedDateRange, setSelectedDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const { t } = useTranslation();
  const [quote, setQuote] = useState([]);
  const [isExternalPartner, setIsExternalPartner] = useState(false);
  const navigate = useNavigate();
  const dateRangeRef = useRef(null);

  const [selectedColumns, setSelectedColumns] = useState({
    clientName: true,
    projectName: true,
    expectedDeliveryDate: true,
    quoteStatus:true,
    actions: true,
  });

  const requestBody = useRef({
    searchText: null,
    status: undefined,
    startDate: "",
    endDate: "",
  });

  const onPageChange = (page) => {
    currentPage.current = page;
    fetchQuoteDetails(page);
  };

  const [pageNumbers, setPageNumbers] = useState(0);
  const handlePageChange = (newPageNumber) => {
    onPageChange(newPageNumber);
  };

  const currentPage = useRef(1);
  const selectedPageSize = useRef(10);

  const pageNumber = currentPage.current;

  const handlePageSize = async (selectedSize) => {
    selectedPageSize.current = selectedSize;
    fetchQuoteDetails();
  };

  //function to quote partner deatils
  async function quotePartnerDetails(id) {
    const user = await getUser();

    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/quote/quotePartnersByUserEmail/${id}`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try {
      const response = await api.get(apiUrl, { headers });
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  //funxtion to to get quote details 
  async function fetchQuoteDetails(page) {
    const user = await getUser();
    const pageForUrl = page || currentPage.current;
    if (!user) {
      login();
      return;
    }
    const selectedSize = selectedPageSize.current;
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/quote/filter/search?pageNumber=${pageForUrl}&pageSize=${selectedSize}`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try {
      const response = await api.post(apiUrl, requestBody.current, {
        headers,
      });
      setIsExternalPartner(response.data.isExternalPartner);
      setQuote(response.data.quoteList);
      setPageNumbers(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  //function to call all the saved visible columns for the listing table 
  const initializeSelectedColumns = async () => {
    const user = await getUser();

    const page = "QUOTE";
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/user-preference/${page}`;

    try {
      const response = await api.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      });

      const savedSettings = response.data.selectedColumns.split(",");
      const defaultColumns = {
        clientName: true,
        projectName: true,
        expectedDeliveryDate: true,
        quoteStatus:true,
        actions: true,
      };

      if (savedSettings.length > 0) {
        const updatedColumns = { ...defaultColumns };
        Object.keys(defaultColumns).forEach((column) => {
          updatedColumns[column] = savedSettings.includes(column);
        });
        return updatedColumns;
      }

      return defaultColumns;
    } catch (error) {
      console.error("Error fetching column visibility:", error);
      return {
        clientName: true,
        projectName: true,
        expectedDeliveryDate: true,
        quoteStatus:true,
        actions: true,
      };
    }
  };

  //useEffect to initalize the proccess on the page load
  useEffect(() => {
    const fetchInitialColumns = async () => {
      const initialColumns = await initializeSelectedColumns();
      setSelectedColumns(initialColumns);
    };

    fetchInitialColumns();
  }, []);

  //saving of visible columns to backend api
  const saveColumnVisibility = async (updatedColumns) => {
    const user = await getUser();
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/user-preference/selected-columns`;

    const userPreference = {
      page: "QUOTE",
      selectedColumns: Object.keys(updatedColumns)
        .filter((key) => updatedColumns[key])
        .join(","),
    };

    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try {
      await api.post(apiUrl, userPreference, { headers });
    } catch (error) {
      console.error("Error saving column selection:", error);
    }
  };
//function to reset the columns
  const handleResetColumns = () => {
    const defaultColumns = {
      clientName: true,
      projectName: true,
      expectedDeliveryDate: true,
      quoteStatus:true,
      actions: true,
    };

    setSelectedColumns(defaultColumns);
    saveColumnVisibility(defaultColumns);
  };

  const columns = Object.keys(selectedColumns).filter(
    (column) => selectedColumns[column]
  );

  //function to handle the toggle event of column
  const handleToggleColumn = (column) => {
    setSelectedColumns((prevSelectedColumns) => {
      const updatedColumns = {
        ...prevSelectedColumns,
        [column]: !prevSelectedColumns[column],
      };
      saveColumnVisibility(updatedColumns);
      return updatedColumns;
    });
  };

  //useEffect to fetch quote details on page load
  useEffect(() => {
    fetchQuoteDetails();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dateRangeRef.current &&
        !dateRangeRef.current.contains(event.target)
      ) {
        setShowCalendar(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  //function to handle edit event 
  const handleEditClick = async (rowData) => {
    try {
      const partnerResponse = await quotePartnerDetails(rowData.id);

      const mappedItems =
        partnerResponse && partnerResponse.map((item) => item.partnerType);
      console.log("partnerResponse:", partnerResponse);
      console.log(mappedItems);
      const isExternal =
        mappedItems &&
        mappedItems.length === 1 &&
        mappedItems.includes("DRONE_OPERATOR");

      if (isExternal) {
        navigate("/dspView/" + rowData.id, { state: { rowData: quote } });
      } else {
        navigate("/editQuote/" + rowData.id, { state: { rowData: quote } });
      }
    } catch (error) {
      console.error("Error fetching partner details:", error);
    }
  };

  if (!quote) {
    return <div>Loading...</div>;
  }

  //handle date form submit action
  const handleDateFormSubmit = (ranges) => {
    setSelectedDateRange([ranges.selection]);

    const startDate = moment(ranges.selection.startDate).format("DD-MM-YYYY");
    const endDate = moment(ranges.selection.endDate).format("DD-MM-YYYY");

    requestBody.current = {
      ...requestBody.current,
      desiredStartDateFrom: startDate,
      desiredDeliveryDateTo: endDate,
    };
    fetchQuoteDetails();
  };
//handle search form submit action
  const handleSearchFormSubmit = (text) => {
    requestBody.current = {
      ...requestBody.current,
      searchText: text,
    };
    fetchQuoteDetails();
  };

  const onSelectStatus = (e) => {
    requestBody.current = {
      ...requestBody.current,
      status: e.target.value ? e.target.value : null,
    };
    fetchQuoteDetails();
  };

  //name conversions for display purposes
  const getProjectStatusDisplayText = (status) => {
    const statusMapping = {
      DRAFT: "Draft",
      REQUESTED: "Requested",
      SENT: "Sent",
      REJECTED: "Rejected",
      ACCEPTED: "Accepted",
      QUOTE_FORWARDED: "Forwarded",
      OFFER_SELECTED: "Selected",
    };
    return statusMapping[status] || status;
  };

  //filter selection for partner status
  const onSelectPartnerStatus = (e) => {
    requestBody.current = {
      ...requestBody.current,
      quotePartnerStatus: e.target.value ? e.target.value : null,
    };
    fetchQuoteDetails();
  };

  //name conversion for quote status 
  const getQuotePartnerStatusDisplayText = (status) => {
    const statusMapping = {
      QUOTE_RECEIVED: "Received",
      OFFER_SENT: "Offer Sent",
      REJECTED: "Rejected",
      ACCEPTED: "Accepted",
    };
    return statusMapping[status] || status;
  };

  return (
    <div class="main-wrap">
      <div className="quote-body">
        <input
          id="slide-sidebar"
          type="checkbox"
          className="checkInputQuoteView"
          role="button"
        />
        <label for="slide-sidebar">
          <span>
            <i class="fa fa-bars">
              <img class="sidebar-toggle" alt="sidebar" src={hamburger}></img>
            </i>
          </span>
        </label>
        <div class="sidebar">
          <div class="sidebar-content">
            <div>
              <ProtectedSidebar />
            </div>
          </div>
        </div>
        <div class="portfolio">
          <div class="main-content">
            <div class="main-header-content">
              <div className="pageHeader">
                <h1>{t("pageHeader_qouteViewPage")}</h1>
              </div>
              <div class="user">
                <button
                  className="btn btn-light quoteBtns rqstbtn "
                  type="button"
                >
                  <img src={home} alt="home icon" />
                  <img src={rightArrow} alt="right arrow icon" />
                  <span>{t("pageHeaderBtn_quoteViewPage")}</span>
                </button>
              </div>
            </div>
            <div className="page-body content-ht">
              <div className="formDiv">
                <div class="quotelist-record">
                  <div class="quotelist-details">
                    <div className="form-head list-quote">
                      <div>
                        <h2 className="pageHeading">
                          {t("tableHeadingBtn_quoteViewPage")}
                        </h2>
                      </div>
                    </div>
                    <QuoteFilter
                      t={t}
                      requestBody={requestBody}
                      handleDateFormSubmit={handleDateFormSubmit}
                      handleSearchFormSubmit={handleSearchFormSubmit}
                      isExternalPartner={isExternalPartner}
                      onSelectStatus={onSelectStatus}
                      onSelectPartnerStatus={onSelectPartnerStatus}
                      setShowCalendar={setShowCalendar}
                      showCalendar={showCalendar}
                      dateRangeRef={dateRangeRef}
                      selectedDateRange={selectedDateRange}
                      suprmenAdmin={suprmenAdmin}
                    />

                    <QuoteTable
                      t={t}
                      isExternalPartner={isExternalPartner}
                      quote={quote}
                      getProjectStatusDisplayText={getProjectStatusDisplayText}
                      getQuotePartnerStatusDisplayText={
                        getQuotePartnerStatusDisplayText
                      }
                      handleEditClick={handleEditClick}
                      columns={columns}
                      selectedColumns={selectedColumns}
                      onToggleColumn={handleToggleColumn}
                      handleResetColumns={handleResetColumns}
                    />

                    <PaginationTool
                      pageNumbers={pageNumbers}
                      pageNumber={pageNumber}
                      handlePageChange={handlePageChange}
                      handlePageSize={handlePageSize}
                      t={t}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuoteView;
