import React, { useState, useEffect } from "react";
import { Circles } from "react-loader-spinner";
import api from "../../helper/auth_interceptor";
import "./project.modules.css";
import { useTranslation } from 'react-i18next';
import moment from "moment";
import home from "../../images/home.png";
import rightArrow from "../../images/right-arrow.png";
import { getUser } from "../../helper/auth_helper";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import ProjectDetails from "./components/projectDetails/projectDetails";
import ProjectLeaderDetails from "./components/projectLeaderDetails/projectLeaderDetails";
import hamburger from "../../images/hamburger.jpg";
import { ProtectedSidebar } from "../sidebar/protectedSidebar";

const format1 = "DD-MM-YYYY";

function ProjectCreation({ suprmenAdmin, orgAdmin, viewer, regular }) {

  // constants and state declarations
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  
  const defaultProject = {
    leaderContactNo: "",
    endDate: "",
    leaderEmail: "",
    description: "",
    leaderFirstName: "",
    name: "",
    selectedId: "",
    startDate: "",
    leaderLastName: "",
  };

  const [project, setProject] = useState(defaultProject);
  
//main submit function
  const handleSubmit = async () => {
    const user = await getUser();
    
    setValidationErrors({});

 
    const errors = {};
    if (!project.name.trim()) {
      errors.name = `${t('tabOneProjectName_projectEditProject')}`;
    }
    if (!project.selectedId.trim()) {
      errors.selectedId = `${t('tabOneOrgName_projectEditProject')} ${t('required_errorMessage')}`;
    }
    if (!project.endDate) {
      errors.endDate = `${t('endDate')} ${t('required_errorMessage')}`;
    } else if (moment(project.endDate).isSameOrBefore(new Date())) {
      errors.endDate = `${t('preferredDileverDate_errorMessage')}`;
    }

    if (!project.leaderFirstName.trim()) {
      errors.leaderFirstName = `${t('firstName')} ${t('required_errorMessage')}`;
    }
    if (!project.leaderLastName.trim()) {
      errors.leaderLastName = `${t('lastName')} ${t('required_errorMessage')}`;
    }
    if (!project.leaderEmail.trim()) {
      errors.leaderEmail= `${t('emailAddress')} ${t('required_errorMessage')}`;
    }
    if (!project.leaderContactNo.trim()) {
      errors.leaderContactNo = `${t('phoneNo')} ${t('required_errorMessage')}`;
    }else if(!/^[\d+()-]*$/.test(project.leaderContactNo)) {
    
      errors.leaderContactNo = `${t('phoneNo_errorMessage')}`;
    }
    if(project.description.length > 255){
      errors.description = `${t('characterLimit_errorMessage')}`
    }    

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }
  
    
    setLoading(true);
    const requestBody = {
      leaderContactNo: project.leaderContactNo,
      endDate:moment(project.endDate).format(format1),
      city: project.city,
      leaderEmail: project.leaderEmail,
      postcode: project.postcode,
      description: project.description,
      streetName: project.streetName,
      leaderFirstName:project.leaderFirstName,
      name: project.name,
      houseNo: project.houseNo,
      selectedId:project.selectedId ,
      startDate:moment(new Date()).format(format1),
      leaderLastName: project.leaderLastName,
    };

    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/project-management/api/v1/project/${project.selectedId}`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      const response = await api.post(apiUrl, requestBody, {
        headers,
      });
      if (response) {
        toast(`${t('project_Created')}`);
        navigate("/projectList");
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessages = Object.values(error.response.data).join("\n ");
        toast.error(errorMessages);
      } else if (error.response && error.response.status === 409) {
        toast.error(`${t('uniqueConstraint_errorMessage')}`)
      }
       else {
        toast.error(`${t('projectCreation_errorMessage')}`);
      }
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
   
    if (viewer||regular) {
      
      toast.error(`${t('access_errorMessage')}`);
      navigate("/"); 
    }
  }, [viewer, navigate]);

  console.log("validation",validationErrors)
  return (
    <div className={`project-creation-container ${loading ? "blur" : ""}`}>
      {loading && (
        <div className="loader-overlay">
          <Circles type="TailSpin" color="#FF6B00" height={150} width={100} />
        </div>
      )}
      <div class="main-wrap">
        <div className="quote-body">
          <input id="slide-sidebar" type="checkbox" role="button" />
          <label for="slide-sidebar">
            <span>
              <i class="fa fa-bars">
                <img class="sidebar-toggle" alt="sidebar" src={hamburger}></img>
              </i>
            </span>
          </label>
          <div class="sidebar">
            <div class="sidebar-content">
              <div>
                <ProtectedSidebar />
              </div>
            </div>
          </div>

          <div class="portfolio">
            <div class="main-content">
              <div class="main-header-content">
                <div className="pageHeader">
                  <h1>{t('tabOneHeader_project')}</h1>
                </div>
                <div class="user">
                  
                  <button
                    className="btn btn-light quoteBtns rqstbtn "
                    type="button"
                  >
                    
                    <img src={home} alt="home icon" />
                    <img src={rightArrow} alt="right arrow icon" />
                    <span>{t('tabOneHeaderBtn_projectEditProject')}</span>
                  </button>
                </div>
              </div>
              <div className="page-body">
                <div className="formDiv">
                  <div class="project-details-record">
                    <ProjectDetails
                      setProject={(project) => setProject(project)}
                      projectInfo={project}
                      isOrgAdmin={orgAdmin}
                      isSuprAdmin={suprmenAdmin}
                      validationErrors={validationErrors}
                    />
                  </div>
                  <div class="project-leaderdetails-record">
                    <ProjectLeaderDetails setProject={(project) => setProject(project)}
                      projectInfo={project} isOrgAdmin={suprmenAdmin}
                      isSuprAdmin={suprmenAdmin}
                      validationErrors={validationErrors} />
                  </div>
                  <div class="bottom-button">
                    
                    <button
                      type="submit"
                      className="btn-save"
                      onClick={() => handleSubmit()}
                    >
                      <span>{t('saveBtn')} </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectCreation;
