import { useState } from 'react';
import { saveAs } from 'file-saver';
import { getUser } from '../../helper/auth_helper';
import api from '../../helper/auth_interceptor';

//a common file used for all the exporting purposes 
const useExport = () => {
    //state declaration
    const [loading, setLoading] = useState(false);

    //main function to handle export event 
    const handleExport = async (fileName, apiUrl, requestBody) => {
        setLoading(true);

        const current = new Date();
        const formattedDate = current.toLocaleDateString().split('/').join('-');
        const formattedTime = current.toLocaleTimeString().replace(/:/g, '-');
        const formattedTimeWithColon = formattedTime.split('-').join(':');
        try {
            const user = await getUser();
            const headers = {
                Authorization: `Bearer ${user.access_token}`,
            };

            const response = await api.post(apiUrl, requestBody, { headers });
            const csvData = response.data.split('\n').map(row => row.split(','));

            const header = csvData.shift();

            csvData.unshift(header);

            const sortedCsvString = csvData.map(row => row.join(',')).join('\n');

            const blob = new Blob([sortedCsvString], { type: 'application/octet-stream' });

            const formattedFileName = `${fileName}_sorted_${formattedDate}_${formattedTimeWithColon}.csv`;

            saveAs(blob, formattedFileName);
            
        } catch (error) {
            console.error('Error exporting data:', error);
        } finally {
            setLoading(false);
        }
    };

    return { handleExport, loading };
};

export default useExport;
