import React, { useEffect, useState, useRef } from "react";
import api from "../../helper/auth_interceptor";
import "./EditQuote.modules.css";
import { Circles } from "react-loader-spinner";
import { useTranslation } from 'react-i18next';
import plus from "../../images/Frame.png";
import home from "../../images/home.png";
import rightArrow from "../../images/right-arrow.png";
import { getUser } from "../../helper/auth_helper";
import { useParams, useNavigate } from "react-router-dom";
import { SelectedOffer } from "./selectedOffer/selectedOffer";
import { ProgressBar } from "./components/progressBar/progressBar";
import { QuoteDetails } from "./components/quoteAccordian/quoteAccordian";
import { ScanOption } from "./components/scanOptionAccordian/scanOptionAccordian";
import { Outside } from "./components/outsideAccordian/outsideAccordian";
import { OutsidePurpose } from "./components/outsidePurposeAccordian/outsidePurpose";
import { OutsideInspection } from "./components/outsideInspectionAccordian/outsideInspection";
import { OutsideExtrasAccord } from "./components/outsideExtrasAccordian/outsideExtras";
import { InsideServiceAccord } from "./components/insideServiceAccordian/insideService";
import { InsideProductAccord } from "./components/insideProductAccordian/insideProduct";
import { Planning } from "./components/planning/planning";
import hamburger from "../../images/hamburger.jpg";
import { ProtectedSidebar } from "../sidebar/protectedSidebar";
import { Workflow } from "./components/workflow/workflow";
import { toast } from "react-toastify";
import { Partner } from "./components/partnerAccordian/partnerAccordian";
import ExternalSp from "./externalServiceProviders/externalServiceProvider";
import { OverViewModal } from "./components/overview/quoteOverview";
import QuoteOverviewModel from "./components/overview/QuoteOverviewModel";

const EditQuoteDefault = {
  id: undefined,
  desiredStartDateFrom: "",
  desiredStartDateTo: "",
  desiredDeliveryDateFrom: "",
  desiredDeliveryDateTo: "",
  clientName: "",
  contact: "",
  quoteRequester: {
    id: "",
    username: "",
    firstName: "",
    prefix: null,
    lastName: "",
    email: "",
    organization: {
      id: undefined,
      name: "",
      organizationType: "",
      houseNo: null,
      streetName: null,
      city: "",
      postcode: null,
      createDate: "",
      updateDate: "",
      endDate: null,
      startDate: "",
    },
    createDate: "",
    updateDate: "",
    endDate: null,
    status: "",
    language: null,
    jobTitle: null,
    contactNo: "",
    startDate: null,
  },
  quoteStatusType: "",
  productType: "",
  project: {
    id: 1,
    organization: {
      id: undefined,
      name: "",
      organizationType: "",
      houseNo: null,
      streetName: "",
      city: "",
      postcode: "",
      createDate: "",
      updateDate: "",
      endDate: "",
      startDate: "",
    },
    name: "",
    description: "",
    startDate: "",
    endDate: "",
    projectLeader: {
      id: "",
      username: "",
      firstName: "",
      prefix: null,
      lastName: "",
      email: "",
      organization: null,
      createDate: "",
      updateDate: "",
      endDate: null,
      status: "",
      language: null,
      jobTitle: null,
      contactNo: "",
      startDate: null,
    },
    createdBy: {
      id: "",
      username: "",
      firstName: "",
      prefix: null,
      lastName: "",
      email: "",
      organization: {
        id: undefined,
        name: "",
        organizationType: "",
        houseNo: null,
        streetName: null,
        city: "",
        postcode: null,
        createDate: "",
        updateDate: "",
        endDate: null,
        startDate: "",
      },
      createDate: "",
      updateDate: "",
      endDate: null,
      status: "",
      language: null,
      jobTitle: null,
      contactNo: "",
      startDate: null,
    },
    status: "",
    createDate: "",
    updateDate: "",
  },
  quoteOutsideProducts: [],
  quoteOutsideExtraServices: [],
  quoteOutsideInspections: [],
  quoteOutsidePurposes: [],
  quoteInsideProducts: [],
  quoteInsideServices: [],
};

function EditQuote({ suprmenAdmin, orgAdmin, viewer, regular }) {
  const params = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState([]);
  const [inpectionSelected, setInpectionSelected] = useState(false);
  const [newQuoteDetails, setNewQuoteDetails] = useState(EditQuoteDefault);
  const [checkboxSelected, setCheckboxSelcted] = useState(false)
  const [projectId, setProjectId] = useState();
  const [dspId, setDspId] = useState();
  const [statBtn, setStatBtn] = useState([]);
  const [quoteState, setQuoteState] = useState();
  const { t } = useTranslation();
  const [otherPurposeText, setOtherPurposeText] = useState("");
  const [insepectionCheck, setInspectionCheck] = useState(false);
  const [loading, setLoading] = useState(false);

  const MainScanDiv = { padding: "0px" };
  const [isDraft, setIsDraft] = useState(false); 

  useEffect(() => {
    setIsDraft(newQuoteDetails?.quoteStatusType === "DRAFT"); 
  }, [newQuoteDetails]);
  const width = isDraft ? "80%" : "100%";
  // const withoutTimebar = isDraft ? width: "80%" : width: "100%";
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const componentCompletion = useRef({
    quoteDetails: false,
    scanOption: false,
    outside: false,
    outsidePurpose: false,
    outsideInspection: false,
    outsideExtras: false,
    insideService: false,
    insideProduct: false,
    planning: false,
    partner: false,
  });
  

  async function FetchProject() {
    const user = await getUser();

    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/project-management/api/v1/project`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try {
      const response = await api.get(apiUrl, { headers });
      setProject(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  const handleShowModal = () => {
    setShowModal(true);
  };
  async function FetchQuote() {
    const user = await getUser();

    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/quote/${params.id}`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try {
      const response = await api.get(apiUrl, { headers });

      setNewQuoteDetails(response.data);
      setQuoteState(response.data.quoteStatusType);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }



  const handleSubmitDraft = async (isDraft) => {
   
    setLoading(true);

    const requestBody = {
      clientName: newQuoteDetails.project.organization.name,
      contact: newQuoteDetails.project.projectLeader.email,
      productType: newQuoteDetails.productType,
      projectId: projectId || newQuoteDetails.project.id,
      quoteOutsideProducts: newQuoteDetails.quoteOutsideProducts.map(
        (item) => item.outsideProduct.id
      ),
      quoteOutsidePurposes: newQuoteDetails.quoteOutsidePurposes.map(
        (item) => item.outsidePurpose.id
      ),
      quoteOutsideExtraServices: newQuoteDetails.quoteOutsideExtraServices.map(
        (item) => item.outsideExtraServices.id
      ),
      quoteOutsideInspections: newQuoteDetails.quoteOutsideInspections.map(
        (item) => item.outsideInspection.id
      ),
      quoteInsideProducts: newQuoteDetails.quoteInsideProducts.map(
        (item) => item.insideProduct.id
      ),
      quoteInsideServices: newQuoteDetails.quoteInsideServices.map(
        (item) => item.insideServices.id
      ),
      otherPurpose: newQuoteDetails.quoteOutsidePurposes.find(
        (item) => item.outsidePurpose.id === 13
      )?.otherPurpose,
      dspOrgIds: newQuoteDetails.selectedDSP,
      internalPartnerIds: newQuoteDetails.selectedInternal,
      inspectionPartnerIds: newQuoteDetails.selectedInspection,
      draft: isDraft,
    };

    try {
      if (quoteState === "DRAFT") {
        const user = await getUser();
        const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/quote/draft/${params.id}`;
        const headers = {
          Authorization: `Bearer ${user.access_token}`,
        };

        await api.put(apiUrl, requestBody, { headers });
        navigate("/quoteview");
        toast.success(`${t('quoteUpdated')}`);
      } else {
        const user = await getUser();
        const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/quote/${params.id}`;
        const headers = {
          Authorization: `Bearer ${user.access_token}`,
        };

        await api.put(apiUrl, requestBody, { headers });
        navigate("/quoteview");
        toast.success(`${t('quoteUpdated')}`);
      }
    } catch (error) {
      console.error("Error updating quote:", error);
    } finally {
      setLoading(false);
    }
  };

  
  const handleSubmit = async (isDraft) => {
    setIsDraft(false);
    setLoading(true);
      if (newQuoteDetails.productType === "OUTSIDE" && (
        newQuoteDetails.quoteOutsideProducts.length === 0 ||
        newQuoteDetails.quoteOutsidePurposes.length === 0 ||
        newQuoteDetails.quoteOutsideExtraServices.length === 0 ||
        (newQuoteDetails.quoteOutsidePurposes.some(purpose => purpose.id === 1) && newQuoteDetails.quoteOutsideInspections.length === 0) 
      )) {
        setLoading(false);
      return;
      }
  
      if (newQuoteDetails.productType === "INSIDE" && (
        newQuoteDetails.quoteInsideProducts.length === 0 ||
        newQuoteDetails.quoteInsideServices.length === 0
      )) {
        setLoading(false);
      return;
      }
  
      if (newQuoteDetails.productType === "BOTH" && (
        (newQuoteDetails.quoteOutsideProducts.length === 0 ||
        newQuoteDetails.quoteOutsidePurposes.length === 0 ||
        (newQuoteDetails.quoteOutsidePurposes.some(purpose => purpose.id === 1) && newQuoteDetails.quoteOutsideInspections.length === 0) ||
        newQuoteDetails.quoteOutsideExtraServices.length === 0) ||
        (newQuoteDetails.quoteInsideProducts.length === 0 ||
        newQuoteDetails.quoteInsideServices.length === 0)
    )) {
        setLoading(false);
        return;
    }

    handleShowModal();

    const requestBody = {
      clientName: newQuoteDetails.project.organization.name,
      contact: newQuoteDetails.project.projectLeader.email,
      productType: newQuoteDetails.productType,
      projectId: projectId || newQuoteDetails.project.id,
      quoteOutsideProducts: newQuoteDetails.quoteOutsideProducts.map(
        (item) => item.outsideProduct.id
      ),
      quoteOutsidePurposes: newQuoteDetails.quoteOutsidePurposes.map(
        (item) => item.outsidePurpose.id
      ),
      quoteOutsideExtraServices: newQuoteDetails.quoteOutsideExtraServices.map(
        (item) => item.outsideExtraServices.id
      ),
      quoteOutsideInspections: newQuoteDetails.quoteOutsideInspections.map(
        (item) => item.outsideInspection.id
      ),
      quoteInsideProducts: newQuoteDetails.quoteInsideProducts.map(
        (item) => item.insideProduct.id
      ),
      quoteInsideServices: newQuoteDetails.quoteInsideServices.map(
        (item) => item.insideServices.id
      ),
      otherPurpose: newQuoteDetails.quoteOutsidePurposes.find(
        (item) => item.outsidePurpose.id === 13
      )?.otherPurpose,
      dspOrgIds: newQuoteDetails.selectedDSP,
      internalPartnerIds: newQuoteDetails.selectedInternal,
      inspectionPartnerIds: newQuoteDetails.selectedInspection,
      draft: isDraft,
    };

    try {
      if (quoteState === "DRAFT") {
        const user = await getUser();
        const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/quote/draft/${params.id}`;
        const headers = {
          Authorization: `Bearer ${user.access_token}`,
        };

        await api.put(apiUrl, requestBody, { headers });
        navigate("/quoteview");
        toast.success(`${t('quoteUpdated')}`);
      } else {
        const user = await getUser();
        const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/quote/${params.id}`;
        const headers = {
          Authorization: `Bearer ${user.access_token}`,
        };

        await api.put(apiUrl, requestBody, { headers });
        navigate("/quoteview");
        toast.success(`${t('quoteUpdated')}`);
      }
    } catch (error) {
      console.error("Error updating quote:", error);
    } finally {
      setLoading(false);
    }
  };

  const [quotePartners, setQuotePartners] = useState([]);

  async function fetchQuotePartners() {
    const user = await getUser();
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/quote/quotePartners/${params.id}`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try {
      const response = await api.get(apiUrl, { headers });

      setQuotePartners(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const draftSetting = () => {
    setIsDraft(true);
    handleSubmitDraft(true);
  };

  const getStatusText = (status) => {
    switch (status) {
      case "REVIEWED":
        return "Send For Review";
      case "REQUESTED":
        return "Request";
      case "FORWARDED":
        return "Forward";
      case "SENT":
        return "Send";
    }
  };

  const updateComponentCompletion = (component, status) => {
    componentCompletion.current = {
      ...componentCompletion.current,
      [component]: status,
    };
  };

  useEffect(() => {
    FetchProject();
    FetchQuote();
    fetchQuotePartners();

    if (newQuoteDetails?.quoteStatusType === "QUOTE_FORWARDED") {
      componentCompletion.partner = true;
    }
  }, []);

  const handleCheckBox = (value) => {
    setCheckboxSelcted(value)
  }



  return (
    <div>
      {(newQuoteDetails?.quoteStatusType === "REQUESTED" ||
        newQuoteDetails?.quoteStatusType === "DRAFT") && (
          <div className={`main-wrap ${loading ? "blur" : ""}`}>
            {loading && (
              <div className="loader-overlay">
                <Circles
                  type="TailSpin"
                  color="#FF6B00"
                  height={150}
                  width={100}
                />
              </div>
            )}
            <div className="quote-body">
              <input id="slide-sidebar" type="checkbox" role="button" />
              <label for="slide-sidebar">
                <span>
                  <i class="fa fa-bars">
                    <img
                      class="sidebar-toggle"
                      alt="sidebar"
                      src={hamburger}
                    ></img>
                  </i>
                </span>
              </label>
              <div class="sidebar">
                <div class="sidebar-content">
                  <div>
                    <ProtectedSidebar />
                  </div>
                </div>
              </div>
              <div class="portfolio">
                <div class="main-content">
                  <div class="main-header-content">
                    <div className="pageHeader">
                      <h1>{t('quote_editQuote')}</h1>
                    </div>
                    <div class="user">
                      <button
                        className="btn btn-light quoteBtns rqstbtn "
                        type="button"
                      >
                        <img src={home} alt="home icon" />
                        <img src={rightArrow} alt="right arrow icon" />
                        <span>{t('requestForQuotes_quoteCreation')}</span>
                      </button>
                    </div>
                  </div>

                  <div>
                    <div className="page-body">
                      <div className="formDivquote">
                        {newQuoteDetails?.quoteStatusType === "DRAFT" && (
                          <div class="timebar">
                            <ProgressBar
                              componentCompletion={componentCompletion}
                              newQuoteDetails={newQuoteDetails}
                              insepectionCheck={insepectionCheck}
                              inpectionSelected={inpectionSelected}
                            />
                          </div>
                        )}

                        <div class="main-record" style={{ width }}>
                          {newQuoteDetails?.quoteStatusType !== "DRAFT" && (
                            <Workflow
                              id={params.id}
                              setStatBtn={(statBtn) => setStatBtn(statBtn)}
                            />
                          )}

                          <div class="quote-details-record">
                            <div class="quote-details">
                              <QuoteDetails
                                suprmenAdmin={suprmenAdmin}
                                setNewQuoteDetails={(newQuoteDetails) =>
                                  setNewQuoteDetails(newQuoteDetails)
                                }
                                newQuoteDetails={newQuoteDetails}
                                projectId={projectId}
                                setProjectId={(projectId) =>
                                  setProjectId(projectId)
                                }
                                setDspId={(dspId) => setDspId(dspId)}
                                setComponentCompletion={(status) =>
                                  updateComponentCompletion(
                                    "quoteDetails",
                                    status
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div class="quote-scan-record " style={MainScanDiv}>
                            <div class="quote-details">
                              <ScanOption
                                setNewQuoteDetails={(newQuoteDetails) =>
                                  setNewQuoteDetails(newQuoteDetails)
                                }
                                newQuoteDetails={newQuoteDetails}
                                setComponentCompletion={(status) =>
                                  updateComponentCompletion("scanOption", status)
                                }
                              />
                            </div>
                          </div>
                          {newQuoteDetails?.quoteStatusType === "REQUESTED" && (
                            <div class="quote-scan-record " style={MainScanDiv}>
                              <div class="quote-details">
                                <Partner
                                  setNewQuoteDetails={(newQuoteDetails) =>
                                    setNewQuoteDetails(newQuoteDetails)
                                  }
                                  newQuoteDetails={newQuoteDetails}
                                  setComponentCompletion={(status) =>
                                    updateComponentCompletion("partner", status)
                                  }
                                  quotePartners={quotePartners}
                                />
                              </div>
                            </div>
                          )}

                          {(newQuoteDetails.productType === "OUTSIDE" ||
                            newQuoteDetails.productType === "BOTH") && (
                              <>
                                <div class="quote-outside">
                                  <div class="quote-details">
                                    <Outside
                                      setNewQuoteDetails={(newQuoteDetails) =>
                                        setNewQuoteDetails(newQuoteDetails)
                                      }
                                      newQuoteDetails={newQuoteDetails}
                                      setComponentCompletion={(status) =>
                                        updateComponentCompletion("outside", status)
                                      }
                                      isDraft = {isDraft}
                                    />
                                  </div>
                                </div>
                                <div class="quote-option-record">
                                  <div class="quote-details">
                                    <OutsidePurpose
                                      setNewQuoteDetails={(newQuoteDetails) =>
                                        setNewQuoteDetails(newQuoteDetails)
                                      }
                                      newQuoteDetails={newQuoteDetails}
                                      setInspectionSelected={(inspectionSelected) =>
                                        setInpectionSelected(inspectionSelected)
                                      }
                                      otherPurposeText={otherPurposeText}
                                      setOtherPurposeText={(otherPurposeText) =>
                                        setOtherPurposeText(otherPurposeText)
                                      }
                                      handleCheckBox={handleCheckBox}
                                      setComponentCompletion={(status) =>
                                        updateComponentCompletion(
                                          "outsidePurpose",
                                          status
                                        )
                                      }
                                      isDraft = {isDraft}
                                    />
                                  </div>
                                </div>
                                {inpectionSelected ? (
                                  <div class="quote-outside-record">
                                    <div class="quote-details">
                                      <OutsideInspection
                                        setNewQuoteDetails={(newQuoteDetails) =>
                                          setNewQuoteDetails(newQuoteDetails)
                                        }
                                        checkboxSelected={checkboxSelected}
                                        newQuoteDetails={newQuoteDetails}
                                        setInspectionCheck={setInspectionCheck}
                                        setComponentCompletion={(status) =>
                                          updateComponentCompletion(
                                            "outsideInspection",
                                            status
                                          )
                                        }
                                        isDraft = {isDraft}
                                      />
                                    </div>
                                  </div>
                                ) : null}
                                <div class="quote-outside-extras-record">
                                  <div class="quote-details">
                                    <OutsideExtrasAccord
                                      setNewQuoteDetails={(newQuoteDetails) =>
                                        setNewQuoteDetails(newQuoteDetails)
                                      }
                                      newQuoteDetails={newQuoteDetails}
                                      setComponentCompletion={(status) =>
                                        updateComponentCompletion(
                                          "outsideExtras",
                                          status
                                        )
                                      }
                                      isDraft = {isDraft}
                                    />
                                  </div>
                                </div>
                              </>
                            )}

                          {(newQuoteDetails.productType === "INSIDE" ||
                            newQuoteDetails.productType === "BOTH") && (
                              <>
                                <div class="quote-inside-summary-record">
                                  <div class="quote-details">
                                    <InsideProductAccord
                                      setNewQuoteDetails={(newQuoteDetails) =>
                                        setNewQuoteDetails(newQuoteDetails)
                                      }
                                      newQuoteDetails={newQuoteDetails}
                                      setComponentCompletion={(status) =>
                                        updateComponentCompletion(
                                          "insideProduct",
                                          status
                                        )
                                      }
                                      isDraft = {isDraft}
                                    />
                                  </div>
                                </div>
                                <div class="quote-inside-services-record">
                                  <div class="quote-details">
                                    <InsideServiceAccord
                                      setNewQuoteDetails={(newQuoteDetails) =>
                                        setNewQuoteDetails(newQuoteDetails)
                                      }
                                      newQuoteDetails={newQuoteDetails}
                                      setComponentCompletion={(status) =>
                                        updateComponentCompletion(
                                          "insideServices",
                                          status
                                        )
                                      }
                                      isDraft = {isDraft}
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          <div class="quote-planning-record ">
                            <div class="quote-details">
                              <Planning
                                setNewQuoteDetails={(newQuoteDetails) =>
                                  setNewQuoteDetails(newQuoteDetails)
                                }
                                newQuoteDetails={newQuoteDetails}
                                setComponentCompletion={(status) =>
                                  updateComponentCompletion("planning", status)
                                }
                              />
                            </div>
                          </div>
                          <div class="bottom-button right">
                            {newQuoteDetails.id ? null : (
                              <button className="btn-save">
                                <span>{t('saveBtn')}</span>
                              </button>
                            )}
                            {newQuoteDetails?.quoteStatusType === "DRAFT" && (
                              <button
                                class="btn-cancel"
                                disabled=""
                                onClick={draftSetting}
                              >
                                <span>{t('saveAsDraft_quoteCreation')}</span>
                              </button>
                            )}
                            <button
                              class="btn-cancel"
                              disabled=""
                              onClick={() => navigate("/quoteview")}
                            >
                              <span>{t('cancel')}</span>
                            </button>
                            {newQuoteDetails?.quoteStatusType === "DRAFT" ? (
                             

                              <div>
                               <button className="btn-save" onClick={handleSubmit}>
                                  <span>{t('submit')}</span>
                                </button>
                                <QuoteOverviewModel
                                  show={showModal}
                                  handleClose={handleCloseModal}
                                  inpectionSelected={inpectionSelected}
                                  newQuoteDetails={newQuoteDetails}
                                  handleSubmit={handleSubmit}
                                /> 
                             </div>
                            ) : (
                              <button
                                type="submit"
                                className="btn-save"
                                onClick={() => handleSubmit()}
                              >
                                <span>{t('submit')} </span>
                              </button>
                            )}


                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        )}

      {newQuoteDetails?.quoteStatusType === "QUOTE_FORWARDED" && (
        <ExternalSp
          quotePartners={quotePartners}
          inpectionSelected={inpectionSelected}
          newQuoteDetails={newQuoteDetails}
          setNewQuoteDetails={(newQuoteDetails) =>
            setNewQuoteDetails(newQuoteDetails)
          }
        />
      )}

      {(newQuoteDetails?.quoteStatusType === "OFFER_SELECTED" ||
        newQuoteDetails?.quoteStatusType === "ACCEPTED" ||
        newQuoteDetails?.quoteStatusType === "REJECTED") && (
          <SelectedOffer id={params.id} inpectionSelected={inpectionSelected} />
        )}
    </div>
  );
}

export default EditQuote;
