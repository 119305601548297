import React, { useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import information from "../../../../../images/information.png";
import { toast, ToastContainer } from 'react-toastify';

const CopyToClipboardButton = ({ link }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(link)
      .then(() => {
        // Show success toaster
        toast.success('Link copied to clipboard!');
      })
      .catch((err) => {
        // Show error toaster
        toast.error('Failed to copy the link');
      });
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Click to copy link
    </Tooltip>
  );

  return (
    <>
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip}
      >
        <Button
          variant="primary"
          onClick={handleCopy}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <img src={information} alt="information" />
        </Button>
      </OverlayTrigger>
      
      {/* Toast Container to show notifications */}
      <ToastContainer position="bottom-right" autoClose={5000}/>
    </>
  );
};

export default CopyToClipboardButton;