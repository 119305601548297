import React from "react";
import outside from "../../../../images/outside.png";
import inside from "../../../../images/Inside.png";
import { useTranslation } from 'react-i18next';
import "./scanOptionAccordian.modules.css";

export const ScanOption = ({
  scanOption,
  setScanOption,
  setComponentCompletion,
  validationCheck
}) => {
  const { t, i18n } = useTranslation();
  
  const handleValueChange = (productType) => {
    setScanOption({
      ...scanOption,
      productType: productType,
    });

    const anyOptionSelected = productType !== null;
    setComponentCompletion(anyOptionSelected);
  };

  const MainScanDivLabel ={
    paddingTop: "25px"
    }
    
    const MainScanDivTabContent ={
    paddingBottom: "25px"
    }
    const mainScanDiv={
      paddingTop: "5px"
      }
  return (
    <div className="form-head">
      <section  class={`accordion ${validationCheck ? 'no-selection' : ''}`}>
        <div className="tab">
          <input type="checkbox" name="accordion-1" id="cb2" />
          <label htmlFor="cb2" className="tab-label form-head quote-detail" style={MainScanDivLabel}>
            <h2>{t('selectScanOption_scanOptionQuote')}</h2>
          </label>
          <div className="tab-content" style={MainScanDivTabContent}>
            <div className="scan-record">
              <div className="projectleader-details">
                <div className="quote-scan-content">
                  <div className="main" style={mainScanDiv}>
                    <div className="Ist-block">
                      <div>
                        <input
                          type="radio"
                          id="Outside"
                          name="scanOption"
                          checked={scanOption.productType === "OUTSIDE"}
                          onChange={() => handleValueChange("OUTSIDE")}
                        />
                      </div>
                      <div className="block-content">
                        <img src={outside} alt="right arrow icon" />
                        <h3>{t('exterior_scanOptionQuote')}</h3>
                        <p>{t('scanExteriorDiscription_scanOptionQuote')}</p>
                      </div>
                    </div>
                    <div className="second-block">
                      <div>
                        <input
                          type="radio"
                          id="Inside"
                          name="scanOption"
                          checked={scanOption.productType === "INSIDE"}
                          onChange={() => handleValueChange("INSIDE")}
                        />
                      </div>
                      <div className="block-content">
                        <img src={inside} alt="right arrow icon" />
                        <h3>{t('interior_scanOptionQuote')}</h3>
                        <p>{t('scanInteriorDescription_scanOptionQuote')}</p>
                      </div>
                    </div>
                    <div className="third-block">
                      <div className="ext-int-block">
                        <input
                          type="radio"
                          id="Both"
                          name="scanOption"
                          checked={scanOption.productType === "BOTH"}
                          onChange={() => handleValueChange("BOTH")}
                        />
                      </div>
                      <div className="img-block">
                        <div class="image-wrapper">
                        <img src={outside} alt="right arrow icon" />
                        </div>
                        <div class="spacer"></div>
                        <div class="image-wrapper">
                        <img src={inside} alt="right arrow icon" />
                        </div>
                      </div>
                      <div className="block-content">
                        <h3>{t('bothExterior&Interior_scanOptionQuote')}</h3>
                        <p>{t('scanBothDescription_scanOptionQuote')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {validationCheck && <div className="message">{t('pleaseChooseAtLeastOneField_errorMessage')}</div>}
    </div>
  );
};
