import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import moment from "moment";

const QuoteOverviewModel = ({ show, handleClose, handleSubmit,
    inpectionSelected,
    newQuoteDetails }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [project, setProject] = useState([]);
    const format1 = "DD-MM-YYYY";

    let uniqueOutsidePurposeTypes = [...new Set(newQuoteDetails.quoteOutsidePurposes.map(item => item.outsidePurpose.outsidePurposeType))];
    let uniqueQuoteOutsidePurposes = newQuoteDetails.quoteOutsidePurposes.filter((item, index, self) =>
        index === self.findIndex((t) => (
            t.outsidePurpose.outsidePurposeType === item.outsidePurpose.outsidePurposeType
        ))
    );
    const prodLabel = {
        width: "20%",
        // border: "1px solid",
        // borderRadius:"1px",
        padding: "7px 5px",
        margin: "2px 0 0 1%",
        display: "inline-flex",
        fontSize: "16px",

    }
    const prodValues = {
        width: "75%",
        padding: "5px",
        margin: "3px 0 0 1%",
        display: "inline-flex",
        fontSize: "16px",
        flexWrap: "wrap"
    }

    const DatesInline = {
        width: '100%',
        display: 'inline-flex',
    }

    const ForMainProdExternal = {
        background: "#d319d3",
        color: "white",
        padding: "1px 10px 4px 10px",
        margin: "2px 5px 2px 5px",
        borderRadius: "50px",
        height: "max-content",
        width: "auto",
        whiteSpace: "nowrap",
    }
    const ForMainProdInternal = {
        background: "#18c5ae",
        color: "white",
        padding: "1px 10px 4px 10px",
        margin: "2px 5px 2px 5px",
        borderRadius: "50px",
        height: "max-content",
        width: "auto",
        whiteSpace: "nowrap",
    }

    const ForSubProd = {
        background: "#c5bc18",
        color: "white",
        padding: "1px 10px 4px 10px",
        margin: "2px 5px 2px 5px",
        borderRadius: "50px",
        height: "max-content",
        width: "auto",
        whiteSpace: "nowrap",
        textTransform: "lowercase"
    }

    const InlineDiv = {
        display: "inline"
    }

    const BorderDivGeneral = {
        border: "1px solid #b1aeae",
        borderRadius: "15px",
        margin: "10px 0"
    }
    const BorderDiv = {
        border: "1px solid #b1aeae",
        borderRadius: "15px",
        margin: "10px 0"
    }

    const mainTitle = {
        marginLeft: "15px",
        marginTop: "15px",
        color: "#ffae00"
    }
    const TrnsformText = {
        textTransform: "capitalize"
    }
    return (
        <Modal show={show} onHide={handleClose} className='modal1'>
            <Modal.Header closeButton><h2>{t('quoteOverView_quoteOverviewQuote')}</h2></Modal.Header>
            <Modal.Body>
                <h6 style={mainTitle}>{t('general_quoteOverviewQuote')}</h6>
                <div style={BorderDivGeneral}>

                    <div>
                        <h5 style={prodLabel}>
                        {t('projectName_quoteOverviewQuote')}
                        </h5>
                        {newQuoteDetails.project.name && (
                            <div style={prodValues}>
                                <div>{newQuoteDetails.project.name}</div>
                            </div>
                        )}
                    </div>
                    <div >
                        <h5 style={prodLabel}>
                        {t('clientName_quoteOverviewQuote')}
                        </h5>
                        {newQuoteDetails.project.organization.name && (
                            <div style={prodValues}>
                                <div>{newQuoteDetails.project.organization.name}</div>
                            </div>
                        )}

                    </div>
                    <div >
                        <h5 style={prodLabel}>
                        {t('projectLeader_quoteOverviewQuote')}
                        </h5>
                        {newQuoteDetails.project.projectLeader.email && (
                            <div style={prodValues}>
                                <div>{newQuoteDetails.project.projectLeader.email}</div>
                            </div>
                        )}

                    </div>
                </div>


                <h6 style={mainTitle}>{t('project_quoteOverviewQuote')}</h6>
                <div style={BorderDiv}>
                    <h5 className="MainLabel" style={prodLabel}>{t('scanOption_quoteOverviewQuote')}  </h5>
                    <div style={prodValues}>
                        {newQuoteDetails && newQuoteDetails.productType && (

                            newQuoteDetails.productType === "INSIDE" ? "Interior" :
                                newQuoteDetails.productType === "OUTSIDE" ? "Exterior" :
                                    newQuoteDetails.productType === "BOTH" ? "Interior and Exterior" :
                                        newQuoteDetails.productType
                        )}
                    </div>

                    {newQuoteDetails.project.endDate && (
                        <div style={DatesInline}>

                            <h5 style={prodLabel}>{t('expectedDate_quoteOverviewQuote')} </h5>
                            <div style={prodValues}>
                                <div>{newQuoteDetails.project.endDate}</div>
                            </div>


                        </div>
                    )}

                </div>


                <h6 style={mainTitle}>{t('product_quoteOverviewQuote')}</h6>
                <div style={BorderDiv}>

                    {(newQuoteDetails.productType === "OUTSIDE" ||
                        newQuoteDetails.productType === "BOTH") && (
                            <div style={InlineDiv}>
                                <h5 style={prodLabel}> {t('exterior_quoteOverviewQuote')} </h5>
                                <div style={prodValues}>
                                    {newQuoteDetails.quoteOutsideProducts.length > 0 && (

                                        newQuoteDetails.quoteOutsideProducts.map((item, index) => {
                                            if (index === 0) {
                                                return <span style={ForMainProdExternal}><span style={TrnsformText}>{item.outsideProduct.name}</span></span>;
                                            } else if (index === newQuoteDetails.quoteOutsideProducts.length - 1) {
                                                return <span style={ForSubProd}><span style={TrnsformText}>  {item.outsideProduct.name}</span></span>;
                                            } else {
                                                return <span style={ForSubProd}><span style={TrnsformText}>{item.outsideProduct.name}</span></span>;
                                            }

                                        })

                                    )}


                                    {uniqueQuoteOutsidePurposes.map((item, index) => {

                                        if (item.otherPurpose !== null) {
                                            return (
                                                <span key={index} style={ForSubProd}>
                                                    <span style={TrnsformText}>{item.outsidePurpose.outsidePurposeType}</span>
                                                    <span style={TrnsformText}>{item.otherPurpose && (
                                                        <span> {item.otherPurpose}</span>
                                                    )}</span>
                                                </span>
                                            );
                                        } else if (index === uniqueQuoteOutsidePurposes.length - 1) {
                                            return (
                                                <span key={index} style={ForSubProd}>
                                                    <span style={TrnsformText}>{item.outsidePurpose.outsidePurposeType.replace(/_/g, ' ').toLowerCase()}</span>
                                                </span>
                                            );
                                        } else {
                                            return (
                                                <span key={index} style={ForSubProd}>
                                                    <span style={TrnsformText}>{item.outsidePurpose.outsidePurposeType.replace(/_/g, ' ').toLowerCase()}</span>
                                                </span>
                                            );
                                        }
                                    })}

                                    {/* 
                                    {newQuoteDetails.quoteOutsidePurposes.map((item, index) => {
                                        console.log("item" + JSON.stringify(item))
                                        if (item.otherPurpose !== null) {
                                           
                                        if (index === 0) {
                                            return <span style={ForSubProd}>{item.otherPurpose}</span>;
                                        } else if (index === newQuoteDetails.quoteOutsidePurposes.length-1) {
                                            return <span style={ForSubProd}>  {item.otherPurpose}</span>;
                                        } else {
                                            return <span style={ForSubProd}>{item.otherPurpose}</span>;
                                        }
                                    }
                                    })} */}



                                    {newQuoteDetails.quoteOutsideExtraServices.map((item, index) => {
                                        if (index === 0) {
                                            return <span style={ForSubProd}><span style={TrnsformText}>{item.outsideExtraServices.name}</span></span>;
                                        } else if (index === newQuoteDetails.quoteOutsideExtraServices.length - 1) {
                                            return <span style={ForSubProd}><span style={TrnsformText}>  {item.outsideExtraServices.name}</span></span>;
                                        } else {
                                            return <span style={ForSubProd}><span style={TrnsformText}>{item.outsideExtraServices.name}</span></span>;
                                        }
                                    })}


                                    {inpectionSelected ? (
                                        <React.Fragment>

                                            {newQuoteDetails.quoteOutsideInspections.map((item, index) => {
                                                if (index === 0) {
                                                    return <span style={ForSubProd}><span style={TrnsformText}>{item.outsideInspection.name}</span></span>;
                                                } else if (index === newQuoteDetails.quoteOutsideInspections.length - 1) {
                                                    return <span style={ForSubProd}><span style={TrnsformText}>  {item.outsideInspection.name}</span></span>;
                                                } else {
                                                    return <span style={ForSubProd}><span style={TrnsformText}>{item.outsideInspection.name}</span></span>;
                                                }
                                            })}

                                        </React.Fragment>
                                    ) : null}
                                </div>
                            </div>
                        )}

                    {(newQuoteDetails.productType === "INSIDE" ||
                        newQuoteDetails.productType === "BOTH") && (
                            <div style={InlineDiv}>

                                <h5 style={prodLabel}>{t('interior_quoteOverviewQuote')} </h5>
                                <div style={prodValues}>
                                    {newQuoteDetails.quoteInsideProducts.length > 0 && (

                                        newQuoteDetails.quoteInsideProducts.map((item, index) => {

                                            if (index === 0) {
                                                return <span style={ForMainProdInternal}><span style={TrnsformText}>{item.insideProduct.name}</span></span>;
                                            } else if (index === newQuoteDetails.quoteInsideProducts.length - 1) {
                                                return <span style={ForSubProd}><span style={TrnsformText}>  {item.insideProduct.name}</span></span>;
                                            } else {
                                                return <span style={ForSubProd}><span style={TrnsformText}>{item.insideProduct.name}</span></span>;
                                            }
                                        })

                                    )}

                                    {newQuoteDetails.quoteInsideServices.map((item, index) => {
                                        if (index === 0) {
                                            return <span style={ForSubProd}><span style={TrnsformText}>{item.insideServices.name}</span></span>;
                                        } else if (index === newQuoteDetails.quoteInsideServices.length - 1) {
                                            return <span style={ForSubProd}><span style={TrnsformText}>  {item.insideServices.name}</span></span>;
                                        } else {
                                            return <span style={ForSubProd}><span style={TrnsformText}>{item.insideServices.name}</span></span>;
                                        }
                                    })}
                                </div>


                            </div>
                        )}

                </div>

                <div className="buttonContainer">
                    <button
                        type="submit"
                        className="btn-save"
                        onClick={() => handleSubmit()}
                    >
                        <span>{t("confirm")}</span>
                    </button>
                </div>


            </Modal.Body>
        </Modal>
    );
};

export default QuoteOverviewModel;
