import React, {useEffect, useState} from "react";
import "./outsideExtras.modules.css";
import { useTranslation } from 'react-i18next';
import { getUser } from "../../../../helper/auth_helper";
import api from "../../../../helper/auth_interceptor";
import { toast } from "react-toastify";
import BIM100 from "../../../../images/BIM 100.png";
import BIM200 from "../../../../images/BIM 200.png";
import BIM300 from "../../../../images/BIM 300.png";
import BIM400 from "../../../../images/BIM 400.png";
import BIM500 from "../../../../images/BIM 500.png";


const imageMap = {
   'BIM 100': BIM100,
   'BIM 200': BIM200,
   'BIM 300': BIM300,
   'BIM 400': BIM400,
   'BIM 500': BIM500,
};

export const OutsideExtrasAccord = ({setNewQuoteDetails, newQuoteDetails, setComponentCompletion, isDraft}) => {
   const [data, setData] = useState([]);
   const [isAnyOptionSelected, setIsAnyOptionSelected] = useState(false);
   const checkOptionsInput = { top: "0px" }
   const ImgParentDiv ={    width: "100%"}
   const mainImgDiv = { display: "flex", flexDirection: "column", width:"19%" }
   const imgCSS = { width: "auto", height: "100%", objectFit: "contain", position: "relative", top: `3px` }
   const checkInputItem = { position: "relative", top: `5px`, marginRight: "10px" }
   const { t } = useTranslation();

   const handleValueChange = (selectedId) => {

      const isIdSelected = newQuoteDetails.quoteOutsideExtraServices.some((item) => item.outsideExtraServices.id === selectedId);
      let updatedProducts;
    
      if (isIdSelected) {
      
        updatedProducts = newQuoteDetails.quoteOutsideExtraServices.filter((item) => item.outsideExtraServices.id !== selectedId);
      } else {
      
        const selectedProduct = data.find((item) => item.id === selectedId);
        updatedProducts = [...newQuoteDetails.quoteOutsideExtraServices, { outsideExtraServices: selectedProduct }];
      }
    
      const updatedQuoteDetails = { ...newQuoteDetails, quoteOutsideExtraServices: updatedProducts };
      setNewQuoteDetails(updatedQuoteDetails);
      setIsAnyOptionSelected(updatedProducts.length > 0)
      setComponentCompletion(updatedProducts.length > 0);
    
      return updatedQuoteDetails;
    
    };

    const backendIds = newQuoteDetails.quoteOutsideExtraServices.map((item)=> item.outsideExtraServices.id);
    if(backendIds.length > 0){
      setComponentCompletion(true);
    }
    

   const getData = async() => {
      const user = await getUser();
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/quote/outsideExtraServices`;

    try {
      const response = await api.get(apiUrl, {
        headers,
      });
      if (response) {
        
        setData(response.data);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessages = Object.values(error.response.data).join("\n ");
        toast.error(errorMessages);
      }
    }
   }

   const accordionClass = isAnyOptionSelected ? 'accordion' : 'accordionerror';
  
   useEffect(()=>{
      getData();
   
   },[])

   useEffect(()=>{
      setIsAnyOptionSelected(backendIds.length>0);  
   },[backendIds])
    return(
        <div className="form-head">
        <section className={!isDraft && accordionClass} >
           <div class="tab">
              <input type="checkbox" name="accordion-1" id="cb7"/>
              <label for="cb7" class="tab-label form-head quote-detail">
                 <h2>{t('exteriorExtras_outsideExtrasQuote')}</h2>
              </label>
              <div class="tab-content record">
                
              <div>
                  <div class="check-header">
                     <div class="check-header-title">
                        <input type="checkbox"  style={checkOptionsInput} checked={isAnyOptionSelected}/>
                        <label for="Services">{t('extraServices_outsideExtrasQuote')}</label>
                     </div>
                  </div>

                     <div>
                        {data?.length > 0 && (
                           <div className="check first-item">
                              <input type="checkbox" style={checkOptionsInput} checked={backendIds.includes(data[0].id)} value={data[0].id} onChange={() => handleValueChange(data[0].id)} />
                              <label htmlFor="BIM">{data[0].name}</label>
                           </div>
                        )}
                     </div>

                  
                     <div className="check-options extras" style={ImgParentDiv}>
                        {data?.slice(1).map((item, index) => (
                           <div className={`check ${index === 0 ? 'second-item' : ''}`} style={mainImgDiv} key={item.id}>
                              <div>
                                 <input type="checkbox" style={checkInputItem} checked={backendIds.includes(item.id)} value={item.id} onChange={() => handleValueChange(item.id)} />
                                 <label htmlFor="BIM">{item.name}</label>
                              </div>
                              <img style={imgCSS} src={imageMap[item.name]} alt={item.name} />
                           </div>
                        ))}
                     </div>

                  </div>
               </div>
            </div>
         </section>
         {!isDraft && !isAnyOptionSelected && <div className="message">{t('pleaseChooseAtLeastOneField_errorMessage')}</div>}
      </div>
   )
}