import React, { useState, useEffect, useRef } from "react";
import "./portalUser.module.css";
import { useTranslation } from "react-i18next";
import home from "../../../images/home.png";
import rightArrow from "../../../images/right-arrow.png";
import hamburger from "../../../images/hamburger.jpg";
import { ProtectedSidebar } from "../protectedSidebar";

import { getUser } from "../../../helper/auth_helper";
import LanguageSwitcher from "../../languageSwitcher/languageSwitcher";
import api from "../../../helper/auth_interceptor";

export const PortalUser = () => {

  //styling constants 
  const mainDiv = { display: "inline-flex", width: "100%" };

  const NameLabel1 = {
    margin: "10px 20px",
    color: "blue",
  };

  const tableHeader = { margin: "10px 15px 10px 15px", fontSize: "16px" };
  const DivPadding = { padding: "12px" };

  //states and constant declaration
  const { t } = useTranslation();

  const [project, setProject] = useState([]);

  //api function to fetch user deatils
  async function fetchUserDetails() {
    const user = await getUser();

    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/project-management/api/v1/project/getUserProfile`;

    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try {
      const response = await api.get(apiUrl, {
        headers,
      });
      setProject(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  //functions to fetch password change URL for redirection though paasword change link
  async function fetchChangePasswordUrl() {
    const user = await getUser();

    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/users/updateUserPassword`;

    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try {
      const response = await api.get(apiUrl, {
        headers,
      });
      if (response.data) {
        window.location.href = response.data;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  //function to call user details 
  function callProjectDetails() {
    fetchUserDetails();
  }

  //function to call URL for chaning password purpose
  const changePassword = async () => {
    await fetchChangePasswordUrl();
  };

  //useEffect to fetch user details on page load
  useEffect(() => {
    fetchUserDetails();
  }, []);
  return (
    <div class="main-wrap">
      <div className="quote-body">
        <input id="slide-sidebar" type="checkbox" role="button" />
        <label for="slide-sidebar">
          <span>
            <i class="fa fa-bars">
              <img
                class="sidebar-toggle"
                alt="hamburger button"
                src={hamburger}
              ></img>
            </i>
          </span>
        </label>
        <div class="sidebar">
          <div class="sidebar-content">
            <div>
              <ProtectedSidebar />
            </div>
          </div>
        </div>
        <div class="portfolio">
          <div class="main-content">
            <div class="main-header-content">
              <div className="pageHeader">
                <h1>{t("pageHeader_portalUser")}</h1>
              </div>
              <div class="user">
                {" "}
                <button
                  className="btn btn-light quoteBtns rqstbtn "
                  type="button"
                >
                  {" "}
                  <img src={home} alt="home icon" />{" "}
                  <img src={rightArrow} alt="right arrow icon" />{" "}
                  <span>{t("pageHeaderBtn_portalUser")}</span>{" "}
                </button>{" "}
              </div>
            </div>
            <div className="page-body content-ht">
              <div className="formDiv">
                <div class="dashboard-record">
                  <div class="dashboard-details">
                    <div className="form-head">
                      <h2 className="pageHeading">
                        {t("mainHeading_portalUser")}
                      </h2>
                    </div>

                    <div className="portalParentDiv">
                      <div className="portalParentDiv1">
                        <h4 style={tableHeader}>
                          {t("Contact details superuser")}
                        </h4>
                        <div style={DivPadding}>
                          <div style={mainDiv}>
                            <h6 className="nameLabel">
                              {t("name_portalUser")} :
                            </h6>
                            <div className="dataDiv">{project.name}</div>
                          </div>
                          {project.organization && (
                            <div style={mainDiv}>
                              <h6 className="nameLabel">
                                {t("organisation_portalUser")} :
                              </h6>
                              <div className="dataDiv">
                                {project.organization}
                              </div>
                            </div>
                          )}
                          <div style={mainDiv}>
                            <h6 className="nameLabel">
                              {t("email_portalUser")} :{" "}
                            </h6>
                            <div className="dataDiv">{project.email}</div>
                          </div>
                          {project.telephone && (
                            <div style={mainDiv}>
                              <h6 className="nameLabel">
                                {t("telephone_portalUser")} :
                              </h6>
                              <div className="dataDiv">{project.telephone}</div>
                            </div>
                          )}
                          <div style={mainDiv}>
                            <h6 className="nameLabel">
                              {t("language_portalUser")} :{" "}
                            </h6>

                            <div className="dataDiv">
                              {" "}
                              <LanguageSwitcher
                                callProjectDetails={callProjectDetails}
                              />
                            </div>
                          </div>
                          <div style={mainDiv}>
                            <a
                              href="#"
                              style={NameLabel1}
                              onClick={changePassword}
                            >
                              {t("changePassword_portalUser")}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
