import React, { useState } from "react";
import styles from "./listingTable.module.css";
import { NewPaginationTool } from "../../../../../helperComponents/PaginationTool/pagination";

export const DataTable = ({
  columns,
  data,
  rowsPerPageOptions = [5, 10, 20],
  handleSortWrapper,
  sortedColumn,
  isAscending,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const totalPages = Math.ceil(data.length / rowsPerPage);

  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const currentRows = data.slice(startIndex, endIndex);

  const getDynamicColumnClass = (cClass) => {
    const floorClassName = "floorPlan";
    return ` ${
      cClass === "Floor Plan" ? styles[floorClassName] : styles[cClass]
    }`;
  };
  const getDynamicRowClass = (cClass) => {
    const floorClassName = "floorPlan";
    return ` ${
      cClass === "Floor Plan" ? styles[floorClassName] : styles[cClass]
    }`;
  };

  const renderHeader = () => (
    <thead>
      <tr className={styles.tableHeadRow}>
        {columns.map((column, index) =>
          column.visible ? (
            <th
              key={index}
              className={getDynamicColumnClass(column.customClass)}
              onClick={
                column.key === "streetHouseNo"
                  ? () => handleSortWrapper("houseNo", currentPage)
                  : undefined
              }
            >
              {column.headerImage ? (
                <img
                  src={column.headerImage}
                  alt={column.headerAlt || "header image"}
                  className={styles.headerImage}
                />
              ) : (
                <>
                  {column.header}
                  {column.key === "streetHouseNo" && (
                    <>
                      {sortedColumn === "houseNo"
                        ? isAscending
                          ? ""
                          : ""
                        : ""}
                      <div className={styles.tableArrow}>
                        <div className={styles.arrowUp}>&#9650;</div>
                        <div className={styles.arrowDown}>&#9660;</div>
                      </div>
                    </>
                  )}
                </>
              )}
            </th>
          ) : null
        )}
      </tr>
    </thead>
  );

  // Render table rows based on the currentRows
  const renderRows = () => (
    <tbody>
      {currentRows.map((row, rowIndex) => (
        <tr key={rowIndex}>
          {columns.map((column, colIndex) => {
            if (column.visible) {
              if (column.type === "image") {
                return (
                  <td
                    className={getDynamicRowClass(column.customClass)}
                    key={colIndex}
                  >
                    <img
                      src={row[column.key]}
                      alt={column.alt || "image"}
                      className={styles.imageCell}
                    />
                  </td>
                );
              }
              return (
                <td
                  className={getDynamicRowClass(column.customClass)}
                  key={colIndex}
                >
                  {row[column.key]}
                </td>
              );
            }
            return null;
          })}
        </tr>
      ))}
    </tbody>
  );

  return (
    <div className={styles.dataTableContainer}>
      <table className={styles.dataTable}>
        {renderHeader()}
        {renderRows()}
      </table>
      <NewPaginationTool
        currentPage={currentPage}
        totalPages={totalPages}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        onPageChange={setCurrentPage}
        onRowsPerPageChange={(rows) => {
          setRowsPerPage(rows);
          setCurrentPage(1); // Reset to the first page
        }}
      />
    </div>
  );
};
