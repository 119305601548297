import { getUser } from "../../../../helper/auth_helper";

export const searchFilter = async (buildingId, searchTerm) => {
  const user = await getUser();
  try {
    const currentPage = 1;
    const pageSize = 10;
    const sortColumn = "id";
    const sortOrder = "desc";

    const requestBody = {
      searchText: searchTerm || "",
    };

    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/report/api/v1/filter/${buildingId}?pageNumber=${currentPage}&pageSize=${pageSize}&sortBy=${sortColumn}&sortOrder=${sortOrder}`;

    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.access_token}`,
      },
      body: JSON.stringify(requestBody),
    });

    if (response.ok) {
      const data = await response.json();

      return {
        searchData: data.data,
      };
    } else {
      console.error("Error fetching filtered addresses");
    }
  } catch (error) {
    console.error("Error during search:", error);
  }
};
