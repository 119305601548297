import React, { useState } from "react";
import "./userItem.modules.css";
import { useTranslation } from 'react-i18next';
import log from "../../../../images/log.jpg";
import { toast } from "react-toastify";
import { getUser } from "../../../../helper/auth_helper";
import api from "../../../../helper/auth_interceptor";
import Modal from "react-modal";
import secondaryDelete from "../../../../images/secondaryDelete.png";
import secondaryEdit from "../../../../images/secondayEdit.png";

const UserItem = ({ user, index, deleteFromUserList, updateUserInList, urlId, fetchProject}) => {
  
  
  const [userModalIsOpen, setUserModalIsOpen] = useState(false);
  const [editedRole, setEditedRole] = useState([]);
  const { t } = useTranslation();


  const PutUser = async() => {
    const userCred = await getUser();
    const putApi = `${process.env.REACT_APP_API_GATEWAY_URL}/project-management/api/v1/project/user/${urlId}`;
    const headers = {
      Authorization: `Bearer ${userCred.access_token}`,
    };
    
    const requestBody={
      projectRoleType: editedRole,
      userId: user.id,
    }

    try {
      const response = await api.put(putApi, requestBody, {
        headers,
      });
      if (response) {
        toast(`${t('userDetailsUpdated')}`);
        setUserModalIsOpen(false);
        fetchProject();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const getProjectRole = (role) => {
    const roleMapping = {
      
      PROJECT_USER: "User",
      PROJECT_VIEWER: "Viewer",
      
      
    };
    return roleMapping[role] || role;
  };





  return (
    <div class="invite-userlist-data">
       <div>
      <div className="invite-left-content">
       <div>
       <div class="profile">
        <div>
            <img src={log} className="rounded-start" alt="..." />
          </div>
          <div>
            <h4>
              {user.firstName} {user.lastName}
            </h4>

            <p>{user.email}</p>
          </div>
        </div>
       </div>
       

        <div class="profile-buttons">
        <div>
            <p>{user.projectRoleType === "PROJECT_USER" ? "User" : "Viewer"}</p>
          </div>
          <div class="">
            <img
              src={secondaryEdit}
              className="img-fluid rounded-start"
              alt="..."
              onClick={() => setUserModalIsOpen(true)}
            />
          </div>
          <div class="">
            <img
              src={secondaryDelete}
              className="img-fluid rounded-start"
              alt="..."
              onClick={deleteFromUserList}
            />
          </div>
        </div>
       
         
        
       
      </div>

      <Modal
        isOpen={userModalIsOpen}
        onRequestClose={() => setUserModalIsOpen(false)}
        style={{
          content: {
            width: "40%",
            height: "40%",
            margin: "auto",
          },
        }}
      >
        <div className="modal-content">
        <div class="modal-header">
        <div>
         <h2>{t('editPageHeader_userItem')}</h2>
         </div>
          <div>
          <button
            className="closeBtn"
            onClick={() => setUserModalIsOpen(false)}
          >
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  height="25px"
                >
                  <line x1="18" y1="6" x2="6" y2="18" />
                  <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
          </button>
          </div>
        </div>
        
        </div>
        <div class="userdiv">
     <div class="invite-details-content">
          <div class="first-row">
            <div class="rows-content">
              
              <label>{t('firstName')} </label>
              <input
                className="form-control"
                placeholder={t('firstName')}
                type="text"
                    name="firstName"
                    value={user.firstName}
                    disabled
              ></input>
            </div>
            <div class="rows-content">
              
              <label>{t('lastName')}</label>
              <input
                className="form-control"
                placeholder={t('lastName')}
                type="text"
                name="lastName"
                value={user.lastName}
                disabled
              ></input>
            </div>
          </div>
          <div class="second-row">
            <div class="rows-content">
              
              <label>{t('emailAddress')}</label>
              <input
                className="form-control"
                placeholder="abc@gmail.com"
                type="text"
                name="email"
                value={user.email} disabled 
              ></input>
            </div>
            <div class="rows-content">
              
              <label>{t('role')}</label>
              <select
                className="form-control "
                name=" projectRoleType "
                value={editedRole}
                onChange={(event)=>setEditedRole(event.target.value)}
              >
                <option >{getProjectRole(user.projectRoleType)}</option>
                <option value="PROJECT_USER">{t('user_editProjectTable')}</option>
                <option value="PROJECT_VIEWER">{t('viewer_editProjectTable')}</option>
              </select>
            </div>
          </div>
        </div>
        <div>
        <button class="btn btn-success modal-invite-save-button" onClick={PutUser}>{t('submit')}</button>
        </div>
    </div>
      </Modal>
    </div>
 
      </div>
   
  );
};

export default UserItem;
