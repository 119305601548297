import React, { useState, useEffect, useRef } from "react";
import "./planning.modules.css";
import { DateRangePicker } from "react-date-range";
import { useTranslation } from 'react-i18next';
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import moment from "moment";
import { toast } from "react-toastify";
export const Planning = ({
  planningDetails,
  setPlanningDetails,
  setComponentCompletion,
  validationCheck,
  quotePartners,
  newQuoteDetails,
  partnerStatusList,
  dateCheck,
  dateErrors
}) => {
  const [startRangeOpen, setStartRangeOpen] = useState(false);
  const [deliveryRangeOpen, setDeliveryRangeOpen] = useState(false);
  const [rangeStartSelected, setRangeStartSelected] = useState(false);
  const [rangeDeliverySelected, setRangeDeliverySelected] = useState(false);
  const [startRangeOpenOutside, setStartRangeOpenOutside] = useState(false);
  const [deliveryRangeOpenOutside, setDeliveryRangeOpenOutside] = useState(false);
  const [rangeStartSelectedOutside, setRangeStartSelectedOutside] = useState(false);
  const [rangeDeliverySelectedOutside, setRangeDeliverySelectedOutside] = useState(false);
  const [startRangeOpenInspection, setStartRangeOpenInspection] = useState(false);
  const [deliveryRangeOpenInspection, setDeliveryRangeOpenInspection] = useState(false);
  const [rangeStartSelectedInspection, setRangeStartSelectedInspection] = useState(false);
  const [rangeDeliverySelectedInspection, setRangeDeliverySelectedInspection] = useState(false);
  const partnerTypes = quotePartners.map(partner => partner.partnerType);
  const [startRange, setStartRange] = useState([
    {
      startDate: "",
      endDate: "",
      key: 'selection'
    }
  ]);
  const [deliveryRange, setDeliveryRange] = useState([
    {
      startDate: "",
      endDate: "",
      key: 'selection'
    }
  ]);
  const [startRangeOutside, setStartRangeOutside] = useState([
    {
      startDate: "",
      endDate: "",
      key: 'selection'
    }
  ]);

  console.log(newQuoteDetails.desiredStartDateFrom);
  const [deliveryRangeOutside, setDeliveryRangeOutside] = useState([
    {
      startDate: "",
      endDate: "",
      key: 'selection'
    }
  ]);
  const [startRangeInspection, setStartRangeInspection] = useState([
    {
      startDate: "",
      endDate: "",
      key: 'selection'
    }
  ]);
  const [deliveryRangeInspection, setDeliveryRangeInspection] = useState([
    {
      startDate: "",
      endDate: "",
      key: 'selection'
    }
  ]);
  const startRangeRef = useRef(null);
  const deliveryRangeRef = useRef(null);
  const startRangeRefOutside = useRef(null);
  const deliveryRangeRefOutside = useRef(null);
  const startRangeRefInspection = useRef(null);
  const deliveryRangeRefInspection = useRef(null);
  const [dateErrorOutside, setDateErrorOutside] = useState(false);
  const [dateErrorStartOutside, setDateErrorStartOutside] = useState(false);
  const [dateErrorInside, setDateErrorInside] = useState(false);
  const [dateErrorStartInside, setDateErrorStartInside] = useState(false);
  const [dateErrorInspection, setDateErrorInspection] = useState(false);
  const [dateErrorStartInspection, setDateErrorStartInspection] = useState(false);
  const { t } = useTranslation();

  const desiredStartDateFrom = moment(newQuoteDetails.desiredStartDateFrom, "DD-MM-YYYY");

  const handleStartRangeChangeOutside = (ranges) => {
    const startDate = moment(ranges.selection.startDate);
    if (startDate && startDate.isSameOrAfter(desiredStartDateFrom, 'day')) {
      setStartRangeOutside([ranges.selection]);
      setRangeStartSelectedOutside(true);
      if (dateErrorStartOutside) {
        setDateErrorStartOutside(false);
      }
    } else {
      setDateErrorStartOutside(true);
    }
  };

  const handleStartRangeChangeInside = (ranges) => {
    const startDate = moment(ranges.selection.startDate);
    if (startDate && startDate.isSameOrAfter(desiredStartDateFrom, 'day')) {
      setStartRange([ranges.selection]);
      setRangeStartSelected(true);
      if (dateErrorStartInside) {
        setDateErrorStartInside(false);
      }
    } else {
      setDateErrorStartInside(true);
    }
  };

  const handleStartRangeChangeInspection = (ranges) => {
    const startDate = moment(ranges.selection.startDate);
    if (startDate && startDate.isSameOrAfter(desiredStartDateFrom, 'day')) {
      setStartRangeInspection([ranges.selection]);
      setRangeStartSelectedInspection(true);
      if (dateErrorStartInspection) {
        setDateErrorStartInspection(false);
      }
    } else {
      setDateErrorStartInspection(true);
    }
  };


  const handleDeliveryRangeChangeOutside = (ranges) => {

    if (ranges.selection.startDate >= startRangeOutside[0].startDate) {
      setDeliveryRangeOutside([ranges.selection]);
      setRangeDeliverySelectedOutside(true);
      if (dateErrorOutside) {
        setDateErrorOutside(false);
      }
    } else {
      setDateErrorOutside(true);
    }
  };

  const handleDeliveryRangeChangeInside = (ranges) => {

    if (ranges.selection.startDate >= startRange[0].startDate) {
      setDeliveryRange([ranges.selection])
      setRangeDeliverySelected(true);
      if (dateErrorInside) {
        setDateErrorInside(false);
      }
    } else {
      setDateErrorInside(true);
    }
  };

  const handleDeliveryRangeChangeInspection = (ranges) => {

    if (ranges.selection.startDate >= startRangeInspection[0].startDate) {
      setDeliveryRangeInspection([ranges.selection])
      setRangeDeliverySelectedInspection(true);
      if (dateErrorInspection) {
        setDateErrorInspection(false);
      }
    } else {
      setDateErrorInspection(true);
    }
  };




  useEffect(() => {
    setPlanningDetails({
      ...planningDetails,
      desiredInternalDeliveryDateBegin: deliveryRange[0].startDate,
      desiredInternalDeliveryDateEnd: deliveryRange[0].endDate,
      desiredOutsideDeliveryDateBegin: deliveryRangeOutside[0].startDate,
      desiredOutsideDeliveryDateEnd: deliveryRangeOutside[0].endDate,
      desiredInspectionDeliveryDateBegin: deliveryRangeInspection[0].startDate,
      desiredInspectionDeliveryDateEnd: deliveryRangeInspection[0].endDate,
    });
    const startRangeSelected = startRange.every(range => range.startDate && range.endDate);
    const deliveryRangeSelected = deliveryRange.every(range => range.startDate && range.endDate);
  }, [startRange, deliveryRange, startRangeOutside, deliveryRangeOutside, startRangeInspection, deliveryRangeInspection]);
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        startRangeOpen &&
        startRangeRef.current &&
        !startRangeRef.current.contains(event.target)
      ) {
        setStartRangeOpen(false);
      }
      if (
        deliveryRangeOpen &&
        deliveryRangeRef.current &&
        !deliveryRangeRef.current.contains(event.target)
      ) {
        setDeliveryRangeOpen(false);
      }
      if (
        startRangeOpenOutside &&
        startRangeRefOutside.current &&
        !startRangeRefOutside.current.contains(event.target)
      ) {
        setStartRangeOpenOutside(false);
      }
      if (
        deliveryRangeOpenOutside &&
        deliveryRangeRefOutside.current &&
        !deliveryRangeRefOutside.current.contains(event.target)
      ) {
        setDeliveryRangeOpenOutside(false);
      }
      if (
        startRangeOpenInspection &&
        startRangeRefInspection.current &&
        !startRangeRefInspection.current.contains(event.target)
      ) {
        setStartRangeOpenInspection(false);
      }
      if (
        deliveryRangeOpenInspection &&
        deliveryRangeRefInspection.current &&
        !deliveryRangeRefInspection.current.contains(event.target)
      ) {
        setDeliveryRangeOpenInspection(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [startRangeOpen, deliveryRangeOpen, startRangeOpenOutside, deliveryRangeOpenOutside, startRangeOpenInspection, deliveryRangeOpenInspection]);
  console.log("quotePartners", quotePartners)
  //
  const deliveryDateStr = newQuoteDetails?.project?.endDate
  const [day, month, year] = deliveryDateStr.split('-').map(Number);
  const deliveryDate = new Date(year, month - 1, day);
  const fourteenDaysEarlier = new Date(deliveryDate);
  fourteenDaysEarlier.setDate(deliveryDate.getDate() - 14);
  const formattedDate = `${fourteenDaysEarlier.getDate().toString().padStart(2, '0')}-${(fourteenDaysEarlier.getMonth() + 1).toString().padStart(2, '0')}-${fourteenDaysEarlier.getFullYear()}`;
  const inspectionDate = new Date(deliveryRangeInspection[0]?.endDate);
  const formattedDateParts = formattedDate.split('-').map(Number);
  const formattedDateObject = new Date(formattedDateParts[2], formattedDateParts[1] - 1, formattedDateParts[0]);
  const isInspectionDateGreaterThanFormattedDate = inspectionDate > formattedDateObject;
  
  const internalDate = new Date(deliveryRange[0]?.endDate)
  const isInternalDateGreaterThanFormattedDate = internalDate > formattedDateObject;
  
  const externalDate = new Date(deliveryRangeOutside[0]?.endDate)
  const isExternalDateGreaterThanFormattedDate = externalDate > formattedDateObject;


  return (
    <div className="form-head">
      <section class={`accordion ${dateCheck ? 'no-selection' : ''}`}>
        <div class="tab plan">
          <input type="checkbox" name="accordion-1" id="cb10" />
          <label htmlFor="cb10" className="tab-label form-head quote-detail">
            <h2>{t('heading_planningQuote')}</h2>
          </label>
           
           <div>
           <label className="ClientDatesLabel"><h5>{t('text1_planingQuote')}</h5></label>
          <div className="planning-content drone">
            <div class="first-row drone">
              <div class="rows-content">
                <input className="date form-control" placeholder={deliveryDateStr} disabled type="text" />
              </div>
            </div>
          </div>
           </div>
         


          {!partnerStatusList.includes("OFFER_SENT") && (
            <div className="PlanningDatesDiv" >
              {partnerTypes.includes("DRONE_OPERATOR") && (
                <div className="first-date">
                  <div className="planning-headers">
                    <label>{t('externalPlaningDate_espPlanning')}</label>
                  </div>
                  <div className="planning-content">
                    <div className="first-row">
                      <div className="rows-content">
                        <label>{t('executionDateRange_espPlanning')}</label>
                        <div class="date form-control">
                          <button class="date-range" onClick={() => setDeliveryRangeOpenOutside(!deliveryRangeOpenOutside)}>{rangeDeliverySelectedOutside ? (
                            moment(deliveryRangeOutside[0].startDate).format("DD-MM-YYYY") + " - " + moment(deliveryRangeOutside[0].endDate).format("DD-MM-YYYY")
                          ) : (`${t('deliveryRange')}`)}</button>
                          <div ref={deliveryRangeRefOutside}>
                            {dateErrorOutside && <p className="PlanningErrorMessage"> {t('dileveryDateErrorMsg')} </p>}
                            {deliveryRangeOpenOutside && (
                                                            <DateRangePicker
                                ranges={deliveryRangeOutside}
                                onChange={handleDeliveryRangeChangeOutside} />
                            )}
                          </div>
                          {isExternalDateGreaterThanFormattedDate && (<span className="dateWarning">{t('deliveryDateSupportErrorMsg')}</span>)}
                          <p className="datemessage">{dateErrors.desiredOutsideDeliveryDate}</p>
                          
                        </div>

                      </div>

                    </div>
                  </div>
                </div>
              )}
              {partnerTypes.includes("INSPECTION_PARTNER") && (
                <div style={{marginTop: isExternalDateGreaterThanFormattedDate ? "30px" : "0px"}} className="second-date">
                  <div className="planning-headers">
                    <label>{t('inspectionDate_espPlanning')}</label>
                  </div>
                  <div className="planning-content">
                    <div className="first-row">
                      <div className="rows-content">
                        <label>{t('executionDateRange_espPlanning')}</label>
                        <div class="date form-control">
                          <button class="date-range" onClick={() => setDeliveryRangeOpenInspection(!deliveryRangeOpenInspection)}>{rangeDeliverySelectedInspection ? (
                            moment(deliveryRangeInspection[0].startDate).format("DD-MM-YYYY") + " - " + moment(deliveryRangeInspection[0].endDate).format("DD-MM-YYYY")
                          ) : (`${t('deliveryRange')}`)}</button>
                          <div ref={deliveryRangeRefInspection}>
                            {dateErrorInspection && <p className="PlanningErrorMessage"> {t('dileveryDateErrorMsg')} </p>}
                            {deliveryRangeOpenInspection && (
                              <DateRangePicker
                                ranges={deliveryRangeInspection}
                                onChange={handleDeliveryRangeChangeInspection}
                              />
                            )}
                          </div>
                          {isInspectionDateGreaterThanFormattedDate && (<span className="dateWarning">{t('deliveryDateSupportErrorMsg')}</span>)}
                          <p className="datemessage">{dateErrors.desiredInspectionDeliveryDate}</p>
                    
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {partnerTypes.includes("INTERNAL_PARTNER") && (
                <div style={{marginTop: isExternalDateGreaterThanFormattedDate ? "30px" : "0px"}}>
                  <div className="planning-headers">
                    <label>{t('internalDate_espPlanning')}</label>
                  </div>
                  <div className="planning-content">
                    <div className="first-row">
                      <div className="rows-content">
                        <label>{t('executionDateRange_espPlanning')}</label>
                        <div class="date form-control">
                          <button class="date-range" onClick={() => setDeliveryRangeOpen(!deliveryRangeOpen)}>{rangeDeliverySelected ? (
                            moment(deliveryRange[0].startDate).format("DD-MM-YYYY") + " - " + moment(deliveryRange[0].endDate).format("DD-MM-YYYY")
                          ) : (`${t('deliveryRange')}`)}</button>
                          <div ref={deliveryRangeRef}>
                            {dateErrorInside && <p className="PlanningErrorMessage"> {t('dileveryDateErrorMsg')} </p>}
                            {deliveryRangeOpen && (
                              <DateRangePicker
                                ranges={deliveryRange}
                                onChange={handleDeliveryRangeChangeInside}
                              />

                            )}

                          </div>
                          {isInternalDateGreaterThanFormattedDate && (<span className="dateWarning">{t('deliveryDateSupportErrorMsg')}</span>)}
                          <p className="datemessage">{dateErrors.desiredInternalDeliveryDate}</p>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

        </div>
      </section>
      {dateCheck && <div className="PlanningErrorMessage">{t('fillAllDates_errorMsg')}</div>}
    </div>
  );
};