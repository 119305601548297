import React, { useEffect, useState } from "react";
import { getUser } from "../../helper/auth_helper";
import { useParams } from "react-router-dom";
import { Circles } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import moment from "moment";
import jwt_decode from "jwt-decode";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import api from "../../helper/auth_interceptor";
import "../../index.css";
import "./editUser.modules.css";
import { toast } from "react-toastify";
import plus from "../../images/Frame.png";
import home from "../../images/home.png";
import rightArrow from "../../images/right-arrow.png";
import "react-datepicker/dist/react-datepicker.css";
import hamburger from "../../images/hamburger.jpg";
import { ProtectedSidebar } from "../sidebar/protectedSidebar";

const userDetailsDefault = {
  userOrgRoleKey: {},
  organization: {
    id: undefined,
    name: "",
    organizationType: "",
    city: "",
    createDate: "",
    updateDate: "",
  },
  role: {
    id: undefined,
    name: "",
    description: null,
    composite: false,
    clientRole: false,
    containerId: undefined,
  },
  user: {
    id: undefined,
    username: "",
    firstName: "",
    prefix: null,
    lastName: "",
    email: "",
    organization: {
      id: undefined,
      name: "",
      organizationType: "",
      city: "",
      createDate: "",
      updateDate: "",
    },
    createDate: "",
    updateDate: "",
    endDate: "",
    status: "",
    language: "",
    jobTitle: "",
    contactNo: "",
    startDate: "",
  },
  createDate: "",
  updateDate: "",
};

function EditUser() {
  const [userDetails, setUserDetails] = useState(userDetailsDefault);
  const [loading, setLoading] = useState(false);
  const [orgId, setOrgId] = useState("");
  const params = useParams();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const format1 = "DD-MM-YYYY";
  const sDate = moment(startDate).format(format1);
  const eDate = moment(endDate).format(format1);
  const navigate = useNavigate();
  const [validationErrors, setValidationErrors] = useState({});
  const { t } = useTranslation();
  const [userOrg, setUserOrg] = useState();

  const fetchUser = async () => {
    const user = await getUser();
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/users/${params.id}`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try {
      const response = await api.get(apiUrl, {
        headers,
      });

      setUserDetails(response.data);
      if (response) {
        setUserOrg(response?.data?.organization);
      }
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateUser = (value, key) => {
    setUserDetails({
      ...userDetails,
      user: {
        ...userDetails.user,
        [key]: value,
      },
    });
  };

  const updateRole = (value, key) => {
    setUserDetails({
      ...userDetails,
      role: {
        ...userDetails.role,
        [key]: value,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userOrg == null) {
      toast.error(
        "External user can not be edited. Please contact SupRmen support desk"
      );
    } else {
      const user = await getUser();

      const editedFields = Object.entries(userDetails.user).filter(
        ([key, value]) => {
          const initialValue = userDetailsDefault.user[key];
          return value !== initialValue && value !== "";
        }
      );

      if (editedFields.length > 0) {
        const requiredFields = ["lastName", "firstName", "email", "status"];
        const fieldLabels = {
          lastName: t("lastName"),
          firstName: t("firstName"),
          email: t("emailAddress"),
          status: t("status"),
        };
        const newValidationErrors = {};
        let hasErrors = false;

        requiredFields.forEach((field) => {
          if (!userDetails.user[field]) {
            newValidationErrors[field] = `${fieldLabels[field]} ${t('required_errorMessage')}`;
            hasErrors = true;
          }
        });

        if (!endDate) {
          newValidationErrors["endDate"] = `${t('endDate')} ${t('required_errorMessage')}`;
          hasErrors = true;
        }
    
        if (!startDate) {
          newValidationErrors["startDate"] = `${t('startDate')} ${t('required_errorMessage')}`;
          hasErrors = true;
        }

        if (moment(endDate).isSameOrBefore(startDate)) {
          newValidationErrors["endDate"] =
          `${t('endDate_errorMessage')}`
          hasErrors = true;
        }

        if (hasErrors) {
          setValidationErrors(newValidationErrors);
          return;
        }
      }
      setValidationErrors({});

      const token = user.access_token;
      const decodedToken = jwt_decode(token);
      setOrgId(decodedToken.orgId);
      setLoading(true);
      const formData = {
        lastName: userDetails.user.lastName,
        firstName: userDetails.user.firstName,
        email: userDetails.user.email,
        contactNo: userDetails.user.contactNo,
        roleType: userDetails.role.name,
        status: userDetails.user.status,
        startDate: sDate,
        endDate: eDate,
        language: userDetails.user.language,
        jobTitle: userDetails.user.jobTitle,
        organization: userDetails.user.organization
          ? {
              id: userDetails.user.organization.id,
            }
          : null,
        username: userDetails.email,
      };

      const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/users/${params.id}`;
      const headers = {
        Authorization: `Bearer ${user.access_token}`,
      };

      try {
        const response = await api.put(apiUrl, formData, {
          headers,
        });
        if (response) {
          toast(`${t('user_Updated')}`);
          navigate("/manageUser");
        }
      } catch (error) {
        if (error.response && error.response.data) {
          const errorMessages = Object.values(error.response.data).join("\n ");
          toast.error(errorMessages);
        } else {
          toast.error(`${('errorInviting_errorMessage')}`);
        }
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePhoneNoChange = (inputValue) => {
    const validPhoneNo = /^[\d+()-]*$/.test(inputValue);

    if (validPhoneNo || inputValue === "") {
      updateUser(inputValue, "contactNo");
    } else {
      const newValidationErrors = {};
      newValidationErrors["contactNo"] =
      `${t('phoneNo_errorMessage')}`;
      setValidationErrors(newValidationErrors);
    }
  };

  function convertDateFromString(dateString) {
    const momentDate = moment(dateString, "DD-MM-YYYY", true);
    if (momentDate.isValid()) {
      const convertedDate = momentDate.toDate();
      return convertedDate;
    } else {
      console.error(`${t("invalidDateFormat_errorMessage")}`);
      return null;
    }
  }

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    setStartDate(convertDateFromString(userDetails.user.startDate));
    setEndDate(convertDateFromString(userDetails.user.endDate));
  }, [userDetails.user.startDate, userDetails.user.endDate]);

  return (
    <div className={`project-creation-container ${loading ? "blur" : ""}`}>
      {loading && (
        <div className="loader-overlay">
          <Circles type="TailSpin" color="#FF6B00" height={150} width={100} />
        </div>
      )}
      <div class="main-wrap">
        <div className="quote-body">
          <input id="slide-sidebar" type="checkbox" role="button" />
          <label for="slide-sidebar">
            <span>
              <i class="fa fa-bars">
                <img class="sidebar-toggle" src={hamburger}></img>
              </i>
            </span>
          </label>
          <div class="sidebar">
            <div class="sidebar-content">
              <div>
                <ProtectedSidebar />
              </div>
            </div>
          </div>
          <div class="portfolio">
            <div class="main-content">
              <div class="main-header-content">
                <div className="pageHeader">
                  <h1>{t("pageHeader_editUser")}</h1>
                </div>
                <div class="user">
                  <button
                    className="btn btn-light quoteBtns rqstbtn "
                    type="button"
                  >
                    <img src={home} alt="home icon" />
                    <img src={rightArrow} alt="right arrow icon" />
                    <span>{t("pageHeaderBtn_editUser")}</span>
                  </button>
                </div>
              </div>
              <div className="page-body">
                <div className="formDiv">
                  <div class="user-details-record">
                    <div class="user-details">
                      <div className="form-head user-details">
                        <h2 className="pageHeading">
                          {t("tableHeading_editUser")}
                        </h2>
                      </div>
                      <div class="user-details-content">
                        <form onSubmit={handleSubmit}>
                          <div class="first-row">
                            <div
                              className={`rows-content ${
                                validationErrors.firstName ? "error" : ""
                              }`}
                            >
                              <label>{t("firstName")}<span className="required">*</span></label>
                              <input
                                class="form-control"
                                value={userDetails.user.firstName}
                                onChange={(e) =>
                                  updateUser(e.target.value, "firstName")
                                }
                                type="text"
                                disabled={userOrg == null}
                              />
                              {validationErrors.firstName && (
                                <span className="error-message">
                                  {validationErrors.firstName}
                                </span>
                              )}
                            </div>
                            <div
                              className={`rows-content ${
                                validationErrors.lastName ? "error" : ""
                              }`}
                            >
                              <label>{t("lastName")}<span className="required">*</span></label>
                              <input
                                class="form-control "
                                value={userDetails.user.lastName}
                                onChange={(e) =>
                                  updateUser(e.target.value, "lastName")
                                }
                                type="text"
                                disabled={userOrg == null}
                              />
                              {validationErrors.lastName && (
                                <span className="error-message">
                                  {validationErrors.lastName}
                                </span>
                              )}
                            </div>
                          </div>
                          <div class="second-row">
                            <div
                              className={`rows-content ${
                                validationErrors.email ? "error" : ""
                              }`}
                            >
                              <label>{t("emailAddress")}<span className="required">*</span></label>
                              <input
                                class="form-control"
                                value={userDetails.user.email}
                                onChange={(e) =>
                                  updateUser(e.target.value, "email")
                                }
                                type="text"
                                disabled={userOrg == null}
                              />
                              {validationErrors.email && (
                                <span className="error-message">
                                  {validationErrors.email}
                                </span>
                              )}
                            </div>
                            <div
                              className={`rows-content ${
                                validationErrors.contactNo ? "error" : ""
                              }`}
                            >
                              <label>{t("phoneNo")}</label>
                              <input
                                class="form-control"
                                value={userDetails.user.contactNo}
                                onChange={(e) =>
                                  handlePhoneNoChange(e.target.value)
                                }
                                type="text"
                                maxLength={18}
                                disabled={userOrg == null}
                              />
                              {validationErrors.contactNo && (
                                <span className="error">
                                  {validationErrors.contactNo}
                                </span>
                              )}
                            </div>
                          </div>
                          {userOrg != null && (
                            <div>
                              <div class="third-row">
                                <div
                                  className={`rows-content ${
                                    validationErrors.roleType ? "error" : ""
                                  }`}
                                >
                                  <label>{t("role")}<span className="required">*</span></label>
                                  <div>
                                    <select
                                      className="form-control"
                                      value={
                                        userDetails.role
                                          ? userDetails.role.name
                                          : ""
                                      }
                                      onChange={(e) =>
                                        updateRole(e.target.value, "name")
                                      }
                                    >
                                      <option>
                                        {userDetails.role
                                          ? userDetails.role.name
                                          : ""}
                                      </option>
                                      <option value="SUPRMEN_ADMIN">
                                        {t("suprmenAdmin_dropDownMenu")}
                                      </option>
                                      <option value="ORG_ADMIN">
                                        {t("orgAdmin_dropDownMenu")}
                                      </option>
                                      <option value="REGULAR">
                                        {t("regular_dropDownMenu")}
                                      </option>
                                      <option value="VIEWER">
                                        {t("viewer_dropDownMenu")}
                                      </option>
                                    </select>
                                    {validationErrors.roleType && (
                                      <span className="error-message">
                                        {validationErrors.roleType}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div
                                  className={`rows-content ${
                                    validationErrors.status ? "error" : ""
                                  }`}
                                >
                                  <label>{t("status")}<span className="required">*</span></label>
                                  <div>
                                    <select
                                      className="form-control"
                                      value={userDetails.user.status}
                                      onChange={(e) =>
                                        updateUser(e.target.value, "status")
                                      }
                                    >
                                      <option>{userDetails.user.status}</option>
                                      <option value="ACTIVE">
                                        {t("active_dropDownMenu")}
                                      </option>
                                      <option value="INACTIVE">
                                        {t("inactive_dropDownMenu")}
                                      </option>
                                    </select>
                                    {validationErrors.status && (
                                      <span className="error-message">
                                        {validationErrors.status}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div class="fourth-row">
                                <div
                                  className={`rows-content ${
                                    validationErrors.startDate ? "error" : ""
                                  }`}
                                >
                                  <label>{t("startDate")}<span className="required">*</span></label>
                                  <div className="date form-control">
                                    <DatePicker
                                      class="form-control"
                                      selected={startDate}
                                      onChange={(date) => {
                                        validationErrors.startDate=null;
                                        setStartDate(date)}}
                                    />
                                  
                                  {validationErrors.startDate && (
                                    <span className="error-message">
                                      {validationErrors.startDate}
                                    </span>
                                  )}
                                  </div>
                                </div>
                                <div
                                  className={`rows-content ${
                                    validationErrors.endDate ? "error" : ""
                                  }`}
                                >
                                  <label>{t("endDate")}<span className="required">*</span></label>
                                  <div className="date form-control">
                                    <DatePicker
                                      class="form-control"
                                      selected={endDate}
                                      onChange={(date) => {
                                      validationErrors.endDate=null;
                                      setEndDate(date)}}
                                    />
                                  
                                  {validationErrors.endDate && (
                                    <span className="error-message">
                                      {validationErrors.endDate}
                                    </span>
                                  )}
                                  </div>
                                </div>
                              </div>
                              <div class="fifth-row">
                                <div class="rows-content">
                                  <label>{t("language_createEditUser")}</label>
                                  <div>
                                    <select
                                      className="form-control"
                                      value={userDetails.user.language}
                                      onChange={(e) =>
                                        updateUser(e.target.value, "language")
                                      }
                                    >
                                      <option>
                                        {userDetails.user.language}
                                      </option>
                                      <option value="ENGLISH">Engels</option>
                                      <option value="DUTCH">Nederlands</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="rows-content">
                                  <label>{t("jobTitle_createEditUser")}</label>
                                  <input
                                    class="form-control"
                                    value={userDetails.user.jobTitle}
                                    onChange={(e) =>
                                      updateUser(e.target.value, "jobTitle")
                                    }
                                    type="text"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                  <div class="bottom-button">
                    <button
                      className="btn-back"
                      onClick={() => navigate("/manageUser")}
                    >
                      <span>{t("backBtn")}</span>
                    </button>
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      className="btn-save"
                    >
                      <span>{t("saveBtn")}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditUser;
