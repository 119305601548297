import React, { useState, useEffect } from "react";
import { getUser } from "../../../helper/auth_helper";
import { useTranslation } from 'react-i18next';
import api from "../../../helper/auth_interceptor";
import { toast } from "react-toastify";
import Frame from "../../../images/Frame.png";
import secondaryDelete from "../../../images/secondaryDelete.png";
import CurrencyInput from 'react-currency-input-field';

export const Outside = ({
  outsideProduct,
  orgId,
  setOutsideProduct,
  nullItems,
  newQuoteDetails,
  popInfo,
  collectDataOutsideAccordian,
  setNewQuoteDetails,
  setGrandTotal,
  partnerStatusList,
}) => {
  const [outsideItem, setOutsideItem] = useState([]);
  const [typing, setTyping] = useState(false);
  const [checkedRows, setCheckedRows] = useState([]);
  const [bigTotal, setBigTotal] = useState(0);
  const [uncheckedRows, setUncheckedRows] = useState([
    { product: "", area: "", hours: "", rate: "" },
  ]);

  const ModalLabel = {
    marginBottom: "10px",
  };
  const checkInputItem = {
    top: "0px",
  };
  const WidthForRowDiv = {
    width: "100%",
  };
  const RightAlign = {
    textAlign: "right"
  }
  const productData = newQuoteDetails.quoteOutsideProducts;
  const { t } = useTranslation();
  const calculateRowTotal = (row) => {
    const hours = parseFloat(row.hours);
    const rate = parseFloat(row.rate);

    if (isNaN(hours) || isNaN(rate)) {
      return 0;
    }

    return hours * rate;
  };

  const calculateGrandTotal = () => {
    let total = 0;
    checkedRows.forEach((item) => {
      total += calculateRowTotal(item);
    });
    uncheckedRows.forEach((item) => {
      total += calculateRowTotal(item);
    });

    setGrandTotal(total);
    setBigTotal(total);
  };

  const handleSubmit = () => {
    const allRowsData = [];

    checkedRows.forEach((item) => {
      const area = item.area !== "" ? Number(item.area) : null;
      const hours = item.hours !== "" ? Number(item.hours) : null;
      const rate = item.rate !== "" ? Number(item.rate) : null;
      const product = String(item.product) || null;

      if (item.product !== "") {
        allRowsData.push({
          area: area,
          totalHours: hours,
          ratePerHour: rate,
          name: product,
        });
      }
    });

    uncheckedRows.forEach((item) => {
      if (item.product !== "") {
        allRowsData.push({
          area: Number(item.area),
          totalHours: Number(item.hours),
          ratePerHour: Number(item.rate),
          name: String(item.product),
        });
      }
    });


    collectDataOutsideAccordian(allRowsData);
  };

  const getOutside = async () => {
    const user = await getUser();
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/quote/outsideProduct`;

    try {
      const response = await api.get(apiUrl, {
        headers,
      });
      if (response) {
        setOutsideItem(response.data);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessages = Object.values(error.response.data).join("\n ");
        toast.error(errorMessages);
      }
    }
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;

    if (name === "area" || name === "hours") {
      const cleanValue = value.replace(/\D/g, "");
      const intValue = parseInt(cleanValue, 10);

      if (!isNaN(intValue) && intValue >= 0) {
        const updatedRows = [...uncheckedRows];
        updatedRows[index][name] = intValue.toString();
        setUncheckedRows(updatedRows);
      } else {
        // If the value is negative or NaN, update the state with an empty string
        const updatedRows = [...uncheckedRows];
        updatedRows[index][name] = "";
        setUncheckedRows(updatedRows);
      }
    } else if (name === "rate") {
      const intValue = value.replace(/[^\d,]/g, "").replace(/,/g, ".");

      if (!isNaN(intValue) && intValue >= 0) {
        const updatedRows = [...uncheckedRows];
        updatedRows[index][name] = intValue.toString();
        setUncheckedRows(updatedRows);
      } else {

        const updatedRows = [...uncheckedRows];
        updatedRows[index][name] = "";
        setUncheckedRows(updatedRows);
      }
    } else {
      const updatedRows = [...uncheckedRows];
      updatedRows[index][name] = value;
      setUncheckedRows(updatedRows);
    }
  };

  const handleInputCheckedChange = (index, event) => {
    const { name, value } = event.target;

    if (name === "area" || name === "hours") {
      const cleanValue = value.replace(/\D/g, "");
      const intValue = parseInt(cleanValue, 10);

      if (!isNaN(intValue) && intValue >= 0) {
        const updatedRows = [...checkedRows];
        updatedRows[index][name] = intValue.toString();
        setCheckedRows(updatedRows);
      } else {
        // If the value is negative or NaN, update the state with an empty string
        const updatedRows = [...checkedRows];
        updatedRows[index][name] = "";
        setCheckedRows(updatedRows);
      }
    } else if (name === "rate") {
      const intValue = value.replace(/[^\d,]/g, "").replace(/,/g, ".");

      if (!isNaN(intValue) && intValue >= 0) {
        const updatedRows = [...checkedRows];
        updatedRows[index][name] = intValue.toString();
        setCheckedRows(updatedRows);
      } else {

        const updatedRows = [...checkedRows];
        updatedRows[index][name] = "";
        setCheckedRows(updatedRows);
      }
    }
    else {
      const updatedRows = [...checkedRows];
      updatedRows[index][name] = value;
      setCheckedRows(updatedRows);
    }
  };
  const nullEntity = nullItems.map((item) => item.name);


  const handleAddRow = () => {
    setUncheckedRows([
      ...uncheckedRows,
      { product: "", area: "", hours: "", rate: "" },
    ]);
  };

  const handleRemoveRow = (index) => {
    const list = [...uncheckedRows];
    list.splice(index, 1);
    setUncheckedRows(list);
  };

  useEffect(() => {
    const newRowData = newQuoteDetails.quoteOutsideProducts
      .filter(item => item.orgId === null || item.orgId === orgId)
      .map((item) => ({
        product: item.outsideProduct.name,
        area: "",
        hours: "",
        rate: "",
      }));

    setCheckedRows([...newRowData]);
  }, [newQuoteDetails]);

  useEffect(() => {
    getOutside();
  }, []);
  useEffect(() => {
    handleSubmit();
    calculateGrandTotal();
  }, [checkedRows, uncheckedRows]);
  return (
    <div className="form-head">
      <section class="accordion">
        <div class="tab">
          <input type="checkbox" name="accordion-1" id="cb3" />
          <label for="cb3" class="tab-label form-head quote-detail">
            <h2>{t('Exterior_outsideAccordianCreateQuote')}</h2>
          </label>
          <div class="tab-content fourth">
            <div class="main-outside-content">
              <div class="outside-model">
                {!partnerStatusList.includes("OFFER_SENT") && (
                  <div className="row" style={WidthForRowDiv}>
                    <div className="col-lg-2 col-md-2 col-sm-2">
                      {" "}
                      <div class="left-content">
                        <div class="header">
                        <h3>{t('3DModelText_outsideAccordianCreateQuote')}</h3>
                        </div>
                        <div class="model">
                          {outsideItem?.map((item) => (
                            <div class="outsideIteminputfields">
                              <input
                                className="OutsideInputCheckInputOthers"
                                type="checkbox"
                                disabled
                                value={item.id}
                                checked={productData
                                  .map((product) => product.outsideProduct.id)
                                  .includes(item.id)}
                              />
                              <label for="Roof">{item.name}</label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-10 col-md-10 col-sm-10 tableDspEdit">
                      <div className="left-content ">
                        <table className="dspTable">
                          <thead>
                            <tr>
                              <th>Product</th>
                              <th>Area(m2)</th>
                              <th>Hours</th>
                              <th>Rate(€)</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          {popInfo?.outsideProductOfferList?.length === 0 && (
                            <tbody>
                              {checkedRows.length > 0 &&
                                checkedRows.map((item, index) => (
                                  <tr
                                    className={
                                      nullEntity.includes(item.product)
                                        ? "no-selection"
                                        : ""
                                    }
                                    key={index}
                                  >
                                    <td>{item.product}</td>

                                    <td>
                                      <input
                                        type="text"
                                        name="area"
                                        style={RightAlign}
                                        className="tableInputs"
                                        onChange={(event) =>
                                          handleInputCheckedChange(index, event)
                                        }
                                        onKeyDown={(event) => {

                                          if (
                                            !/^\d$/.test(event.key) &&
                                            ![
                                              "Backspace",
                                              "Delete",
                                              "ArrowLeft",
                                              "ArrowRight",
                                              "Home",
                                              "End",
                                              "Tab"
                                            ].includes(event.key)
                                          ) {
                                            event.preventDefault();
                                          }
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        name="hours"
                                        style={RightAlign}
                                        className="tableInputs"
                                        onChange={(event) =>
                                          handleInputCheckedChange(index, event)
                                        }
                                        onKeyDown={(event) => {

                                          if (
                                            !/^\d$/.test(event.key) &&
                                            ![
                                              "Backspace",
                                              "Delete",
                                              "ArrowLeft",
                                              "ArrowRight",
                                              "Home",
                                              "End",
                                              "Tab"
                                            ].includes(event.key)
                                          ) {
                                            event.preventDefault();
                                          }
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <div>
                                        <CurrencyInput
                                          name="rate"
                                          className="tableInputs"
                                          placeholder=""
                                          allowDecimals={true}
                                          decimalsLimit={2}
                                          prefix=""
                                          style={RightAlign}
                                          intlConfig={{ locale: 'nl-NL', currency: 'EUR' }}
                                          onChange={(event) =>
                                            handleInputCheckedChange(index, event)
                                          }
                                        />
                                      </div>

                                    </td>
                                    <td>
                                      <div>
                                        <CurrencyInput
                                          name="total"
                                          className="tableInputs"
                                          placeholder=""
                                          disabled
                                          allowDecimals={true}
                                          decimalsLimit={2}
                                          style={RightAlign}
                                          value={parseFloat(item.hours) * (item.rate) || ""}
                                          prefix=""
                                          intlConfig={{ locale: 'nl-NL', currency: 'EUR' }}
                                          onChange={(event) => handleInputChange(index, event)}
                                        />
                                      </div>

                                    </td>
                                    {nullEntity.includes(item.product) && (
                                      <td className="message">
                                        {t('fillTheBox_errorMsg')}
                                      </td>
                                    )}
                                  </tr>
                                ))}
                              {uncheckedRows.map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    <input
                                      type="text"
                                      name="product"
                                      onChange={(event) => {
                                        handleInputChange(index, event);
                                      }}
                                      placeholder={t('enterCustomProduct')}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      name="area"
                                      style={RightAlign}
                                      className="tableInputs"
                                      onChange={(event) =>
                                        handleInputChange(index, event)
                                      }
                                      onKeyDown={(event) => {

                                        if (
                                          !/^\d$/.test(event.key) &&
                                          ![
                                            "Backspace",
                                            "Delete",
                                            "ArrowLeft",
                                            "ArrowRight",
                                            "Home",
                                            "End",
                                            "Tab"
                                          ].includes(event.key)
                                        ) {
                                          event.preventDefault();
                                        }
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      name="hours"
                                      style={RightAlign}
                                      className="tableInputs"
                                      onChange={(event) =>
                                        handleInputChange(index, event)
                                      }
                                      onKeyDown={(event) => {

                                        if (
                                          !/^\d$/.test(event.key) &&
                                          ![
                                            "Backspace",
                                            "Delete",
                                            "ArrowLeft",
                                            "ArrowRight",
                                            "Home",
                                            "End",
                                            "Tab"
                                          ].includes(event.key)
                                        ) {
                                          event.preventDefault();
                                        }
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <div>
                                      <CurrencyInput
                                        name="rate"
                                        className="tableInputs"
                                        placeholder=""
                                        allowDecimals={true}
                                        decimalsLimit={2}
                                        prefix=""
                                        style={RightAlign}
                                        intlConfig={{ locale: 'nl-NL', currency: 'EUR' }}
                                        onChange={(event) =>
                                          handleInputChange(index, event)
                                        }
                                      />
                                    </div>

                                  </td>
                                  <td>
                                    <div>
                                      <CurrencyInput
                                        name="total"
                                        className="tableInputs"
                                        placeholder=""
                                        disabled
                                        style={RightAlign}
                                        allowDecimals={true}
                                        decimalsLimit={2}
                                        value={parseFloat(item.hours) * (item.rate) || ""}
                                        prefix=""
                                        intlConfig={{ locale: 'nl-NL', currency: 'EUR' }}
                                        onChange={(event) => handleInputChange(index, event)}
                                      />
                                    </div>

                                  </td>
                                  <td>
                                    <button
                                      className="AddRowButton"
                                      onClick={() => handleAddRow(index)}
                                    >
                                      <img src={Frame}></img>{" "}
                                    </button>
                                  </td>
                                  <td>
                                    {index !== 0 && (
                                      <button
                                        className="RemoveRowButton"
                                        onClick={() => handleRemoveRow(index)}
                                      >
                                        <img src={secondaryDelete}></img>{" "}
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              ))}
                              <tr>
                                <td>{t('grandTotal_outsideAccordian')} - {bigTotal}€</td>
                              </tr>
                            </tbody>
                          )}
                          {popInfo?.outsideProductOfferList?.length !== 0 && (
                            <tbody>
                              {popInfo.outsideProductOfferList &&
                                popInfo.outsideProductOfferList.map((item) => (
                                  <tr key={item.id}>
                                    <td>
                                      {
                                        item.quoteOutsideProduct.outsideProduct
                                          .name
                                      }
                                    </td>
                                    <td>{item.area}</td>
                                    <td>{item.hours}</td>
                                    <td>{new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(item.ratePerHour)}</td>
                                    <td>{new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(item.hours * item.ratePerHour)}</td>
                                  </tr>
                                ))}
                              <p>{t('grandTotal_outsideAccordian')} -{new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(
                                (popInfo?.outsideProductOfferList?.reduce(
                                  (total, item) => total + item.hours * item.ratePerHour, 0
                                ) || 0) // Adding fallback in case reduce returns undefined or null
                              )}
                              </p>


                            </tbody>
                          )}
                        </table>
                      </div>
                    </div>
                  </div>
                )}
                {partnerStatusList.includes("OFFER_SENT") && (
                  <div class="model">
                    {outsideItem?.map((item) => (
                      <div class="check">
                        <input
                          style={checkInputItem}
                          type="checkbox"
                          disabled
                          value={item.id}
                          checked={productData
                            .map((product) => product.outsideProduct.id)
                            .includes(item.id)}
                        />
                        <label for="Roof">{item.name}</label>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* {validationCheck && (
        <div className="message">Please choose at least one field.</div>
      )} */}
    </div>
  );
};
