import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./home.modules.css";
import home from "../../images/home.png";
import hamburger from "../../images/hamburger.jpg";
import { ProtectedSidebar } from "../sidebar/protectedSidebar";
import { MyProjectsList } from "../myProjectsList/myProjectsList";
import { getUser, login } from "../../helper/auth_helper";
import api from "../../helper/auth_interceptor";

function Home({ suprmenAdmin }) {
 //constants and state declarations

  const tableHeader = { padding: "0 24px 16px 24px",
    margin: "0", fontSize: "16px" };

  const { t } = useTranslation();

  const [project, setProject] = useState([]);

  //fetch project function
  async function fetchMyProjectDetails(page) {
    const user = await getUser();
    if (!user) {
      login();
      return;
    }
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/project-management/api/v1/project/getMyProjects`;

    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try {
      const response = await api.get(apiUrl, {
        headers,
      });
      setProject(response.data.contactDetailsSuperUser);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    fetchMyProjectDetails();
  }, []);

  return (
    <div class="main-wrap">
      <div className="quote-body">
        <input id="slide-sidebar" type="checkbox" role="button" />
        <label for="slide-sidebar">
          <span>
            <i class="fa fa-bars">
              <img
                class="sidebar-toggle"
                alt="hamburger button"
                src={hamburger}
              ></img>
            </i>
          </span>
        </label>
        <div class="sidebar">
          <div class="sidebar-content">
            <div>
              <ProtectedSidebar />
            </div>
          </div>
        </div>
        <div class="portfolio">
          <div class="main-content">
            <div class="main-header-content">
              <div className="pageHeader">
                <h1>{t("dashboard_home")}</h1>
              </div>
              <div class="user">
                {" "}
                <button
                  className="btn btn-light quoteBtns rqstbtn "
                  type="button"
                >
                  {" "}
                  <img src={home} alt="home icon" />
                </button>{" "}
              </div>
            </div>
            <div className="page-body content-ht">
              <div className="formDiv">
                <div class="dashboard-record">
                  <div class="dashboard-details">
                    <div className="form-head">
                      <h2 className="pageHeading">{t("welcome_home")}</h2>
                    </div>
                    <div class="dashboard-details-content">
                      <div class="welcome-section"></div>
                    </div>

                    <div className="mainParentDiv">
                    <div className="subParentDiv2">
                        <h4 style={tableHeader}>{t("myProjects_home")}</h4>
                        <div>
                          <MyProjectsList />
                        </div>
                      </div>
                      <div className="subParentDiv1">
                        <h4 style={tableHeader}>{t("textHeading5_home")}</h4>
                        <div className="DivPadding">
                          <div className="mainDiv">
                            <h6 className="nameLabel">{t("name_home")}:</h6>
                            <div className="homedDataDiv">{project.name}</div>
                          </div>
                          <div className="mainDiv">
                            <h6 className="nameLabel">
                              {t("Organisation_home")}:
                            </h6>
                            <div className="homedDataDiv">
                              {project.organization}
                            </div>
                          </div>
                          <div className="mainDiv">
                            <h6 className="nameLabel">{t("email_home")}:</h6>
                            <div className="homedDataDiv">{project.email}</div>
                          </div>
                          <div className="mainDiv">
                            <h6 className="nameLabel">
                              {t("telephone_home")}:
                            </h6>
                            <div className="homedDataDiv">
                              {project.telephone}
                            </div>
                          </div>
                          <div className="mainDiv">
                            <a
                              className="nameLabel"
                              href="https://suprmen.zohodesk.eu/portal/nl/home"
                              target="blank"
                            >
                              {t("helpCenter_home")}
                            </a>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
