import React, { useState, useEffect } from "react";
import "./quoteAccordian.modules.css";
import { useTranslation } from 'react-i18next';
import { getUser } from "../../../../../helper/auth_helper";
import api from "../../../../../helper/auth_interceptor";
import { toast } from "react-toastify";

export const QuoteDetails = ({
  
  newQuoteDetails,
  setProjectId,

}) => {
  const [projectData, setProjectData] = useState([]);

    const updateProject = (value) => {
    setProjectId(value);
  };
  const { t } = useTranslation();
 

  const getProject = async () => {
    const user = await getUser();
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/project-management/api/v1/project`;

    try {
      const response = await api.get(apiUrl, {
        headers,
      });
      if (response) {
        setProjectData(response.data);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessages = Object.values(error.response.data).join("\n ");
        toast.error(errorMessages);
      }
    }
  };

 

  const mappedData = projectData.map(({ id, name }) => ({ id, name }));

  useEffect(() => {
    getProject();
    
    
  }, []);

  
  return (
    <div className="form-head">
      <section class="accordion">
        <div class="tab">
          <input type="checkbox" name="accordion-1" id="cb1" checked />
          <label for="cb1" class="tab-label form-head quote-detail">
            <h2>{t('quoteDetails_quoteOverviewQuote')}</h2>
          </label>
          <div class="tab-content">
            <div class="quote-content">
              <div class="first-row">
                <div class="rows-content">
                  <label>{t('clientName_quoteOverviewQuote')}</label>
                  <input
                    className="form-control"
                    placeholder="Client Name 01"
                    value={newQuoteDetails.project.organization.name}
                    disabled
                    type="text"
                  />
                </div>
                <div class="rows-content">
                  <label>{t('projectName_quoteOverviewQuote')}</label>

                  <select
                    className="form-control"
                    type="text"
                    disabled
                    onChange={(e) => updateProject(e.target.value)}
                  >
                    <option>{newQuoteDetails.project.name}</option>
                    {mappedData.map((item) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div class="second-row">
                <div class="rows-content">
                  <label>{t('emailAddress_quoteOverviewQuote')}</label>
                  <input
                    className="form-control"
                    placeholder="abc@gmail.com"
                    value={newQuoteDetails.project.projectLeader.email}
                    disabled
                    type="text"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
     
    </div>
  );
};
