import React, { useEffect, useState, useRef } from "react";
import api from "../../../helper/auth_interceptor";
import { useTranslation } from 'react-i18next';
import { Circles } from "react-loader-spinner";
import plus from "../../../images/Frame.png";
import home from "../../../images/home.png";
import rightArrow from "../../../images/right-arrow.png";
import { getUser } from "../../../helper/auth_helper";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import hamburger from "../../../images/hamburger.jpg";
// import { InsideProductAccord } from "./components/insideProductAccordian/insideProduct";
// import { InsideServiceAccord } from "./components/insideServiceAccordian/insideService";
import { Outside } from "./outsideAccordian";
// import { OutsideExtrasAccord } from "./components/outsideExtrasAccordian/outsideExtras";
// import { OutsideInspection } from "./components/outsideInspectionAccordian/outsideInspection";
import { OutsidePurpose } from "../externalServiceProviders/components/outsidePurposeAccordian/outsidePurpose";
import { ProtectedSidebar } from "../../sidebar/protectedSidebar";
import { Planning } from "./planning";
// import { ProgressBar } from "./components/progressBar/progressBar";
import { QuoteDetails } from "../externalServiceProviders/components/quoteAccordian/quoteAccordian";
// import { Workflow } from "./components/workflow/workflow";
// import { Partner } from "./components/partnerAccordian/partnerAccordian";
import { toast } from "react-toastify";
import jwt_decode from 'jwt-decode';

const EditQuoteDefault = {
  id: undefined,
  desiredStartDateFrom: "",
  desiredStartDateTo: "",
  desiredDeliveryDateFrom: "",
  desiredDeliveryDateTo: "",
  clientName: "",
  contact: "",
  quoteRequester: {
    id: "",
    username: "",
    firstName: "",
    prefix: null,
    lastName: "",
    email: "",
    organization: {
      id: undefined,
      name: "",
      organizationType: "",
      houseNo: null,
      streetName: null,
      city: "",
      postcode: null,
      createDate: "",
      updateDate: "",
      endDate: null,
      startDate: "",
    },
    createDate: "",
    updateDate: "",
    endDate: null,
    status: "",
    language: null,
    jobTitle: null,
    contactNo: "",
    startDate: null,
  },
  quoteStatusType: "",
  productType: "",
  project: {
    id: 1,
    organization: {
      id: undefined,
      name: "",
      organizationType: "",
      houseNo: null,
      streetName: "",
      city: "",
      postcode: "",
      createDate: "",
      updateDate: "",
      endDate: "",
      startDate: "",
    },
    name: "",
    description: "",
    startDate: "",
    endDate: "",
    projectLeader: {
      id: "",
      username: "",
      firstName: "",
      prefix: null,
      lastName: "",
      email: "",
      organization: null,
      createDate: "",
      updateDate: "",
      endDate: null,
      status: "",
      language: null,
      jobTitle: null,
      contactNo: "",
      startDate: null,
    },
    createdBy: {
      id: "",
      username: "",
      firstName: "",
      prefix: null,
      lastName: "",
      email: "",
      organization: {
        id: undefined,
        name: "",
        organizationType: "",
        houseNo: null,
        streetName: null,
        city: "",
        postcode: null,
        createDate: "",
        updateDate: "",
        endDate: null,
        startDate: "",
      },
      createDate: "",
      updateDate: "",
      endDate: null,
      status: "",
      language: null,
      jobTitle: null,
      contactNo: "",
      startDate: null,
    },
    status: "",
    createDate: "",
    updateDate: "",
  },
  quoteOutsideProducts: [],
  quoteOutsideExtraServices: [],
  quoteOutsideInspections: [],
  quoteOutsidePurposes: [],
  quoteInsideProducts: [],
  quoteInsideServices: [],
};

const defaultPlanning = {
   
    desiredOutsideDeliveryDateBegin: "",
    desiredOutsideDeliveryDateEnd: "",
   
  };

export const DspView = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [planningDetails, setPlanningDetails] = useState(defaultPlanning);
  const [newQuoteDetails, setNewQuoteDetails] = useState(EditQuoteDefault);
  const [inpectionSelected, setInpectionSelected] = useState(false);
  const [outsideProduct, setOutsideProduct] = useState([]);
  const [outsideProductTotal, setOutsideProductTotal] = useState();
  const [dateCheck, setDateCheck] = useState(false);
 
  const[grandTotal, setGrandTotal] =useState()
  const [popInfo, setPopInfo] = useState({});

 const partnerStatusList = []

 const { t } = useTranslation();

  async function FetchQuote() {
    const user = await getUser();
    
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/quote/${params.id}`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try {
      const response = await api.get(apiUrl, { headers });

      setNewQuoteDetails(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const [orgId, setOrgId] = useState();
  async function getPopInfo() {
    const user = await getUser();
    const token = user.access_token;
    const decodedToken = jwt_decode(token);
   



    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/offer/partner/${params.id}/${decodedToken.orgId}?partnerType=DRONE_OPERATOR`;
    setOrgId(decodedToken.orgId)
    try {
      const response = await api.get(apiUrl, {
        headers,
      });
      if (response) {
    
        setPopInfo(response.data);
      }
    } catch (error) {}
  }
  

  const collectDataOutsideAccordian = (data) => {

    setOutsideProduct(data);
  };

  const [calculatedOutsideNullItems, setCalculatedOutsideNullItems] = useState([]);

  const requestBody = {
    partnerOrgId: newQuoteDetails.project.organization.id,
    quoteId: Number(params.id),
   
    desiredOutsideDeliveryDateBegin: moment(planningDetails.desiredOutsideDeliveryDateBegin).format("DD-MM-YYYY"),
    desiredOutsideDeliveryDateEnd: moment(planningDetails.desiredOutsideDeliveryDateEnd).format("DD-MM-YYYY"),
    outsideMissionStartDateBegin: moment(planningDetails.outsideMissionStartDateBegin).format("DD-MM-YYYY"),
    outsideMissionStartDateEnd: moment(planningDetails.outsideMissionStartDateEnd).format("DD-MM-YYYY"),
    
    outsideServicesRequest: {
        outsideServicesParameters: outsideProduct,
        totalCost: grandTotal,
      },
      desiredInternalDeliveryDateBegin: new Date(),
      desiredInternalDeliveryDateEnd: new Date(),
      internalMissionStartDateBegin: new Date(),
      internalMissionStartDateEnd: new Date(),

      desiredInspectionDeliveryDateBegin: new Date(),
      desiredInspectionDeliveryDateEnd: new Date(),
      inspectionMissionStartDateBegin: new Date(),
      inspectionMissionStartDateEnd:new Date(),


      quoteExtraServices: {
        servicePriceMap:{},
        totalCost:"",
      },
      quoteInspectionServices: {
        servicePriceMap:{},
        totalCost: "",
      },
      quoteInsideServices: {
        servicePriceMap:{},
        totalCost: "",
      },
  };

  

  

  const handleSubmit = async () => {
    setCalculatedOutsideNullItems(outsideProduct.filter(item => Object.values(item).some(value => value === null)));
    const user = await getUser();

    
     
      if (
        !planningDetails.desiredOutsideDeliveryDateBegin ||
        !planningDetails.desiredOutsideDeliveryDateEnd 
      ) {
        setDateCheck(true);
      
        return;
      }
    
   
   

    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/quote/submitOffer/${params.id}`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try {
      const response = await api.post(apiUrl, requestBody, { headers });
   
      if(response.status === 200){
        navigate("/quoteview");
        toast.success(`${t('quoteUpdated')}`);
      }
      } catch (error) {
      console.error("Error fetching data:", error);
    }

  };

  useEffect(() => {
    FetchQuote();
    getPopInfo();
  }, []);
  return (
    <div className="main-wrap">
      <div className="quote-body">
        <input id="slide-sidebar" type="checkbox" role="button" />
        <label for="slide-sidebar">
          <span>
            <i class="fa fa-bars">
              <img class="sidebar-toggle" src={hamburger}></img>
            </i>
          </span>
        </label>
        <div class="sidebar">
          <div class="sidebar-content">
            <div>
              <ProtectedSidebar />
            </div>
          </div>
        </div>
        <div class="portfolio">
          <div class="main-content">
            <div class="main-header-content">
              <div className="pageHeader">
                <h1>{t('quoteCreation_quoteCreation')}</h1>
              </div>
              <div class="user">
                {" "}
                <button
                  className="btn btn-light quoteBtns rqstbtn "
                  type="button"
                >
                  {" "}
                  <img src={home} alt="home icon" />{" "}
                  <img src={rightArrow} alt="right arrow icon" />{" "}
                  <span>{t('requestForQuotes_quoteCreation')}</span>{" "}
                </button>{" "}
              </div>
            </div>
            <div>
              <div className="page-body">
                <div className="formDivquote">
                  <div class="main-record">
                    <div class="quote-details-record">
                      <div class="quote-details">
                        <QuoteDetails
                          newQuoteDetails={newQuoteDetails}
                          setNewQuoteDetails={(newQuoteDetails) => {
                            setNewQuoteDetails(newQuoteDetails);
                          }}
                        />
                      </div>
                    </div>

                    <div>
                      <div class="quote-outside">
                        <div class="quote-details">
                          <Outside 
                          setGrandTotal={setGrandTotal}
                          collectDataOutsideAccordian={collectDataOutsideAccordian}
                          setOutsideProductTotal={setOutsideProductTotal}
                          newQuoteDetails={newQuoteDetails}
                          partnerStatusList={partnerStatusList}
                          popInfo={popInfo}
                          orgId={orgId}
                          nullItems={calculatedOutsideNullItems}
                          />
                        </div>
                      </div>
                      <div class="quote-option-record">
                        <div class="quote-details">
                          <OutsidePurpose 
                          setInspectionSelected={(inspectionSelected) =>
                            {setInpectionSelected(inspectionSelected)}
                          }
                          newQuoteDetails={newQuoteDetails}
                         
                          
                          
                          />
                        </div>
                      </div>

                      <div class="quote-planning-record ">
                        <div class="quote-details">
                          <Planning 
                           planningDetails={planningDetails}
                           setPlanningDetails={(planningDetails) =>
                            { setPlanningDetails(planningDetails)}
                           }
                           dateCheck={dateCheck}
                          newQuoteDetails={newQuoteDetails}
                          popInfo={popInfo}
                          />
                        </div>
                      </div>
                      {popInfo?.outsideProductOfferList?.length === 0 && (
                        <div class="bottom-button right">
                     
                        <button
                          class="btn-cancel"
                          disabled=""
                          onClick={() => navigate("/quoteview")}
                        >
                          {" "}
                          <span>{t('cancel')}</span>
                        </button>
  
                        <button
                          type="submit"
                          class="btn-save"
                          onClick={() => handleSubmit()}
                        >
                          {" "}
                          <span>{t('submit')}</span>{" "}
                        </button>
                      </div>

                      )}
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
