import React, { useEffect, useState, useRef } from "react";
import api from "../../../helper/auth_interceptor";
import { useTranslation } from 'react-i18next';
import { Workflow } from "./components/workflow/workflow";
import hamburger from "../../../images/hamburger.jpg";
import { Circles } from "react-loader-spinner";
import plus from "../../../images/Frame.png";
import home from "../../../images/home.png";
import rightArrow from "../../../images/right-arrow.png";
import { getUser } from "../../../helper/auth_helper";
import { useParams, useNavigate } from "react-router-dom";
import { ProtectedSidebar } from "../../sidebar/protectedSidebar";
import { QuoteDetails } from "./components/quoteAccordian/quoteAccordian";
import { OutsidePurpose } from "./components/outsidePurposeAccordian/outsidePurpose";
import { InsideProductAccord } from "./components/insideProductAccordian/insideProduct";
import { Planning } from "./components/planning/planning";
import { Outside } from "./components/outsideAccordian/outsideAccordian";
import { OutsideExtrasAccord } from "./components/outsideExtrasAccordian/outsideExtras";
import { OutsideInspection } from "./components/outsideInspectionAccordian/outsideInspection";
import { InsideServiceAccord } from "./components/insideServiceAccordian/insideService";
import { toast } from "react-toastify";

const EditQuoteDefault = {
  id: undefined,
  desiredStartDateFrom: "",
  desiredStartDateTo: "",
  desiredDeliveryDateFrom: "",
  desiredDeliveryDateTo: "",
  clientName: "",
  contact: "",
  quoteRequester: {
    id: "",
    username: "",
    firstName: "",
    prefix: null,
    lastName: "",
    email: "",
    organization: {
      id: undefined,
      name: "",
      organizationType: "",
      houseNo: null,
      streetName: null,
      city: "",
      postcode: null,
      createDate: "",
      updateDate: "",
      endDate: null,
      startDate: "",
    },
    createDate: "",
    updateDate: "",
    endDate: null,
    status: "",
    language: null,
    jobTitle: null,
    contactNo: "",
    startDate: null,
  },
  quoteStatusType: "",
  productType: "",
  project: {
    id: 1,
    organization: {
      id: undefined,
      name: "",
      organizationType: "",
      houseNo: null,
      streetName: "",
      city: "",
      postcode: "",
      createDate: "",
      updateDate: "",
      endDate: "",
      startDate: "",
    },
    name: "",
    description: "",
    startDate: "",
    endDate: "",
    projectLeader: {
      id: "",
      username: "",
      firstName: "",
      prefix: null,
      lastName: "",
      email: "",
      organization: null,
      createDate: "",
      updateDate: "",
      endDate: null,
      status: "",
      language: null,
      jobTitle: null,
      contactNo: "",
      startDate: null,
    },
    createdBy: {
      id: "",
      username: "",
      firstName: "",
      prefix: null,
      lastName: "",
      email: "",
      organization: {
        id: undefined,
        name: "",
        organizationType: "",
        houseNo: null,
        streetName: null,
        city: "",
        postcode: null,
        createDate: "",
        updateDate: "",
        endDate: null,
        startDate: "",
      },
      createDate: "",
      updateDate: "",
      endDate: null,
      status: "",
      language: null,
      jobTitle: null,
      contactNo: "",
      startDate: null,
    },
    status: "",
    createDate: "",
    updateDate: "",
  },
  quoteOutsideProducts: [],
  quoteOutsideExtraServices: [],
  quoteOutsideInspections: [],
  quoteOutsidePurposes: [],
  quoteInsideProducts: [],
  quoteInsideServices: [],
};

export const SelectedOffer = ({ id, inpectionSelected }) => {
  const navigate = useNavigate();
  const [offerDetails, setOfferDetails] = useState({});
  const [newQuoteDetails, setNewQuoteDetails] = useState(EditQuoteDefault);
  const { t } = useTranslation();
  const finalStatus = async (event) => {
    const user = await getUser();
    const requestBody = {
      quoteId: id,
      offerId: id,
      finalStatus: event,
    };
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/offer/action`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    try {
      const response = await api.put(apiUrl, requestBody, { headers });
      if (response) {
        navigate("/quoteview");
        toast.success(`${t('quoteUpdated')}`);
       
      }
      setNewQuoteDetails(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  async function FetchQuote() {
    const user = await getUser();

    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/quote/${id}`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try {
      const response = await api.get(apiUrl, { headers });

      setNewQuoteDetails(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const fetchOffer = async () => {
    const user = await getUser();

    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/offer/${id}`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try {
      const response = await api.get(apiUrl, { headers });
   
      setOfferDetails(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchOffer();
    FetchQuote();
  }, []);
  return (
    <div className="main-wrap">
     
      <div className="quote-body">
        <input id="slide-sidebar" type="checkbox" role="button" />
        <label for="slide-sidebar">
          <span>
            <i class="fa fa-bars">
              <img class="sidebar-toggle" alt="sidebar" src={hamburger}></img>
            </i>
          </span>
        </label>
        <div class="sidebar">
          <div class="sidebar-content">
            <div>
              <ProtectedSidebar />
            </div>
          </div>
        </div>
        <div class="portfolio">
          <div class="main-content">
            <div class="main-header-content">
              <div className="pageHeader">
                <h1>{t('quote_editQuote')}</h1>
              </div>
              <div class="user">
                <button
                  className="btn btn-light quoteBtns rqstbtn "
                  type="button"
                >
                  <img src={home} alt="home icon" />
                  <img src={rightArrow} alt="right arrow icon" />
                  <span>{t('requestForQuotes_quoteCreation')}</span>
                </button>
              </div>
            </div>

            <div>
              <div className="page-body">
                <div className="formDivquote">
                  <div class="main-record">
                    <Workflow id={id} />
                    <div class="quote-details-record">
                      <div class="quote-details">
                        <QuoteDetails newQuoteDetails={newQuoteDetails} />
                      </div>
                    </div>

                    {(newQuoteDetails.productType === "OUTSIDE" ||
                      newQuoteDetails.productType === "BOTH") && (
                      <div>
                        <div class="quote-outside">
                          <div class="quote-details">
                            <Outside
                              newQuoteDetails={newQuoteDetails}
                              offerDetails={offerDetails}
                            />
                          </div>
                        </div>
                        <div class="quote-option-record">
                          <div class="quote-details">
                            <OutsidePurpose newQuoteDetails={newQuoteDetails} />
                          </div>
                        </div>

                        <div class="quote-outside-record">
                          <div class="quote-details">
                            <OutsideExtrasAccord
                              newQuoteDetails={newQuoteDetails}
                              offerDetails={offerDetails}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    {newQuoteDetails.quoteOutsideInspections.length > 0 ? (
                      <div class="quote-outside-extras-record">
                        <div class="quote-details">
                          <OutsideInspection
                            newQuoteDetails={newQuoteDetails}
                            offerDetails={offerDetails}
                          />
                        </div>
                      </div>
                    ) : null}

                    {(newQuoteDetails.productType === "INSIDE" ||
                      newQuoteDetails.productType === "BOTH") && (
                      <div>
                        <div class="quote-inside-summary-record">
                          <div class="quote-details">
                            <InsideProductAccord
                              newQuoteDetails={newQuoteDetails}
                            />
                          </div>
                        </div>
                        <div class="quote-inside-services-record">
                          <div class="quote-details">
                            <InsideServiceAccord
                              newQuoteDetails={newQuoteDetails}
                              offerDetails={offerDetails}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    <div class="quote-planning-record ">
                      <div class="quote-details">
                        <Planning
                          newQuoteDetails={newQuoteDetails}
                          offerDetails={offerDetails}
                        />
                      </div>
                    </div>
                    {newQuoteDetails?.quoteStatusType === "OFFER_SELECTED" && (
                      <div class="bottom-button right">
                        <button
                          class="btn-cancel"
                          disabled=""
                          onClick={() => navigate("/quoteview")}
                        >
                          <span>{t('cancel')}</span>
                        </button>
                        <button
                          class="btn-cancel"
                          disabled=""
                          value="REJECTED"
                          onClick={() => finalStatus("REJECTED")}
                        >
                          <span>{t('reject')}</span>
                        </button>
                        <button
                          type="submit"
                          className="btn-save"
                          value="ACCEPTED"
                          onClick={() => finalStatus("ACCEPTED")}
                        >
                          <span> {t('accept')} </span>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
