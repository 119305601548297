import React from "react";
import { Pagination } from "@mui/material";

//pagination component for all the listing tables.
export const PaginationTool = ({
    pageNumbers,
    pageNumber,
    handlePageChange,
    handlePageSize,
    t
}) => {
  return (
    <div class="userlist-details-table-pagination">
      <div class="page-no">
        <div>
          <Pagination
            count={pageNumbers}
            page={pageNumber}
            onChange={(_event, page) => handlePageChange(page)}
          />
        </div>
      </div>
      {/* page size selector */}
      <div class="page-selection">
        <select
          onChange={(e) => {
            handlePageSize(e.target.value);
          }}
        >
          <option>{t("selectPageSize_table")}</option>
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="20">20</option>
        </select>
      </div>
    </div>
  );
};
