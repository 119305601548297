import { getUser } from "../../../helper/auth_helper";
export const handleSort = async (
    column,
    page,
    buildingId,
    sortedColumn,
    isAscending,
    setSortedColumn,
    setIsAscending,
     searchTerm
  ) => {
    const requestBody = {
      searchText: searchTerm || "",
    };
    const user = await getUser();
    const currentPage = page;
    const newIsAscending = column === sortedColumn ? !isAscending : true;
    setSortedColumn(column);
    setIsAscending(newIsAscending);
    const pageSize = 10;
  
    const sortOrder = newIsAscending ? "asc" : "desc";
  
    const sortApi = `${process.env.REACT_APP_API_GATEWAY_URL}/report/api/v1/filter/${buildingId}?pageNumber=${currentPage}&pageSize=${pageSize}&sortBy=${column}&sortOrder=${sortOrder}`;
  
    try {
      const response = await fetch(sortApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.access_token}`,
        },
        body: JSON.stringify(requestBody),
      });
  
      if (response.ok) {
        const data = await response.json();
  
        return {
          searchData: data.data,
        };
      } else {
        console.error("Error fetching filtered addresses");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  