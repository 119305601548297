import deleteBtn from "../../../images/trash.png";
import { DropdownFilter } from "../../../utility/ColumnFilter";

//file to display table on quote view page
export const QuoteTable = ({
  t,
  isExternalPartner,
  quote,
  getProjectStatusDisplayText,
  getQuotePartnerStatusDisplayText,
  handleEditClick,
  columns,
  selectedColumns,
  onToggleColumn,
  handleResetColumns,
}) => {
  return (
    <div class="quotelist-details-table-content">
      <div class="table-content">
        <div className="list">
          <table>
            <thead>
              <tr className="record-row">
                {selectedColumns.clientName && (
                  <th className="ForNameRow opac">
                    {t("clientName_table")}
                    <div class="table-arrow">
                      <div class="arrow-up">&#9650;</div>
                      <div class="arrow-down">&#9660;</div>
                    </div>
                  </th>
                )}
                {selectedColumns.projectName && (
                  <th className="ForNameRow opac">
                    {t("projectName_table")}
                    <div class="table-arrow">
                      <div class="arrow-up">&#9650;</div>
                      <div class="arrow-down">&#9660;</div>
                    </div>
                  </th>
                )}

                {!isExternalPartner && selectedColumns.quoteStatus ? (
                  <th className="ForStatusRow opac">
                    {t("quoteStatus_table")}
                    <div className="table-arrow">
                      <div className="arrow-up">&#9650;</div>
                      <div className="arrow-down">&#9660;</div>
                    </div>
                  </th>
                ) : isExternalPartner && selectedColumns.quoteStatus ? (
                  <th className="ForStatusRow opac">
                    {t("status_table")}
                    <div className="table-arrow">
                      <div className="arrow-up">&#9650;</div>
                      <div className="arrow-down">&#9660;</div>
                    </div>
                  </th>
                ) : null}
                {selectedColumns.expectedDeliveryDate && (
                  <th className="ForDateRow opac">
                    {t("expectedDeliveryDate_table")}
                    <div class="table-arrow">
                      <div class="arrow-up">&#9650;</div>
                      <div class="arrow-down">&#9660;</div>
                    </div>
                  </th>
                )}
                <DropdownFilter
                  columns={Object.keys(selectedColumns)}
                  selectedColumns={selectedColumns}
                  onToggleColumn={onToggleColumn}
                  handleResetColumns={handleResetColumns}
                />
              </tr>
            </thead>
            <tbody>
              {quote.map((quote) => (
                <>
                  <tr>
                    {selectedColumns.clientName && (
                      <td className="ForNameRow">
                        <a
                          className="redirectBtn"
                          onClick={() => {
                            handleEditClick(quote);
                          }}
                        >
                          {quote.project.organization.name}
                        </a>
                      </td>
                    )}
                    {selectedColumns.projectName && (
                      <td className="ForNameRow">{quote?.project?.name}</td>
                    )}

                    {!isExternalPartner && selectedColumns.quoteStatus ? (
                      <td className="ForStatusRow">
                        {getProjectStatusDisplayText(quote.quoteStatusType)}
                      </td>
                    ) : isExternalPartner && selectedColumns.quoteStatus ? (
                      <td className="ForStatusRow">
                        {getQuotePartnerStatusDisplayText(
                          quote.quotePartnerStatus
                        )}
                      </td>
                    ) : null}
                    {selectedColumns.expectedDeliveryDate && (
                      <td className="ForDateRow">{quote.project.endDate}</td>
                    )}

                    {selectedColumns.actions && (
                      <td className="action-buttons">
                        <div>
                          <a>
                            <img src={deleteBtn} alt="delete btn"></img>
                          </a>
                        </div>
                      </td>
                    )}
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
