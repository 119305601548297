import React, { useState, useEffect, useRef } from "react";
import "./planning.modules.css";
import { useTranslation } from 'react-i18next';
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import moment from "moment";

export const Planning = ({
  newQuoteDetails,
  setNewQuoteDetails,
  setComponentCompletion,
  validationCheck
}) => {
  const [startRangeOpen, setStartRangeOpen] = useState(false);
  const [deliveryRangeOpen, setDeliveryRangeOpen] = useState(false);
  const [rangeStartSelected, setRangeStartSelected] = useState(false);
  const [rangeDeliverySelected, setRangeDeliverySelected] = useState(false);
  const [startRangeError, setStartRangeError] = useState(false);
  const [deliveryRangeError, setDeliveryRangeError] = useState(false);
  const { t } = useTranslation();
  const [startRange, setStartRange] = useState([
    {
      startDate: "",
      endDate: "",
      key: 'selection' 
    }
  ]);
  const [deliveryRange, setDeliveryRange] = useState([
    {
      startDate: "",
      endDate: "",
      key: 'selection' 
    }
  ]);

  const startRangeRef = useRef(null);
  const deliveryRangeRef = useRef(null);

  if(newQuoteDetails.desiredStartDateFrom && newQuoteDetails.desiredStartDateTo && newQuoteDetails.desiredDeliveryDateFrom && newQuoteDetails.desiredDeliveryDateTo){
    setComponentCompletion(true);
  }

  useEffect(() => {
    const startDateFrom = startRange[0].startDate ? moment(startRange[0].startDate).format("DD-MM-YYYY") : "";
    const startDateTo = startRange[0].endDate ? moment(startRange[0].endDate).format("DD-MM-YYYY") : "";
    const deliveryDateFrom = deliveryRange[0].startDate ? moment(deliveryRange[0].startDate).format("DD-MM-YYYY") : "";
    const deliveryDateTo = deliveryRange[0].endDate ? moment(deliveryRange[0].endDate).format("DD-MM-YYYY") : "";
    if (moment(startDateFrom, "DD-MM-YYYY", true).isValid() &&
        moment(startDateTo, "DD-MM-YYYY", true).isValid() &&
        moment(deliveryDateFrom, "DD-MM-YYYY", true).isValid() &&
        moment(deliveryDateTo, "DD-MM-YYYY", true).isValid()) {
      setNewQuoteDetails({
        ...newQuoteDetails,
        desiredStartDateFrom: startDateFrom,
        desiredStartDateTo: startDateTo,
        desiredDeliveryDateFrom: deliveryDateFrom,
        desiredDeliveryDateTo: deliveryDateTo
      });
      setComponentCompletion(true);
    }
  }, [startRange, deliveryRange]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        startRangeOpen &&
        startRangeRef.current &&
        !startRangeRef.current.contains(event.target)
      ) {
        setStartRangeOpen(false);
      }
  
      if (
        deliveryRangeOpen &&
        deliveryRangeRef.current &&
        !deliveryRangeRef.current.contains(event.target)
      ) {
        setDeliveryRangeOpen(false);
      }
    }
  
    document.addEventListener("mousedown", handleClickOutside);
  
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [startRangeOpen, deliveryRangeOpen])

  const handleStartRangeChange = (ranges) => {
    const { startDate, endDate } = ranges.selection;
    if (moment(startDate).isSameOrBefore(endDate)) {
      setStartRange([ranges.selection]);
      setRangeStartSelected(true);
      setStartRangeError(false);
    } else {
      setStartRangeError(true);
    }
  };

  const handleDeliveryRangeChange = (ranges) => {
    const { startDate, endDate } = ranges.selection;
    if (moment(startDate).isSameOrBefore(endDate)) {
      setDeliveryRange([ranges.selection]);
      setRangeDeliverySelected(true);
      setDeliveryRangeError(false);
    } else {
      setDeliveryRangeError(true);
    }
  };

  return (
    <div className="form-head">
      <section class={`accordion ${validationCheck ? 'no-selection' : ''}`}>
        <div class="tab plan">
          <input type="checkbox" name="accordion-1" id="cb10" checked />
          <label htmlFor="cb10" className="tab-label form-head quote-detail">
            <h2>{t('heading_planningQuote')}</h2>
          </label>
          <div className="tab-content">
            <div className="planning-headers">
              <label>{t('text1_planingQuote')}</label>
            </div>
            <div className="planning-content">
              <div className="first-row">
                <div className="rows-content">
                <input className="date form-control" placeholder={newQuoteDetails?.project?.endDate} disabled type="text" />
                </div>
                {/* <div className="rows-content">
                  <label>Start Date Range</label>
                  <div class="date form-control">
                    <button class="date-range" onClick={()=>setStartRangeOpen(!startRangeOpen)}>
                      {rangeStartSelected ? (
                        moment(startRange[0].startDate).format("DD-MM-YYYY") + " - " + moment(startRange[0].endDate).format("DD-MM-YYYY")
                      ) : (newQuoteDetails.desiredStartDateFrom + " - " + newQuoteDetails.desiredStartDateTo)}
                    </button>
                    <div ref={startRangeRef}>
                      {startRangeOpen && (
                        <DateRangePicker
                          ranges={startRange}
                          onChange={handleStartRangeChange}
                        />
                      )}
                    </div>
                    {startRangeError && (
                      <span className="error-message">End date must be after start date</span>
                    )}
                  </div>
                </div>

                <div className="rows-content">
                  <label>Delivery Date Range</label>
                  <div class="date form-control"> 
                    <button class="date-range" onClick={()=>setDeliveryRangeOpen(!deliveryRangeOpen)}>
                      {rangeDeliverySelected ? (
                        moment(deliveryRange[0].startDate).format("DD-MM-YYYY") + " - " + moment(deliveryRange[0].endDate).format("DD-MM-YYYY")
                      ) : (newQuoteDetails.desiredDeliveryDateFrom + " - " + newQuoteDetails.desiredDeliveryDateTo)}
                    </button>
                    <div ref={deliveryRangeRef}>
                      {deliveryRangeOpen && (
                        <DateRangePicker
                          ranges={deliveryRange}
                          onChange={handleDeliveryRangeChange}
                        />
                      )}
                    </div>
                    {deliveryRangeError && (
                      <span className="error-message">End date must be after start date</span>
                    )}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {validationCheck && <div className="message">Please fill in the dates.</div>}
    </div>
  );
};
