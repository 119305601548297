import React from "react";
import Sidebar from "./sidebar";
import Protected from "../Protected";
//addtional file to restrict protected sidebar for permitted user access
export const ProtectedSidebar = () => {
  return (
    <>
      <Protected Component={Sidebar} />
      {/* ... other components */}
    </>
  );
};

