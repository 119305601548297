import React, { useState, useEffect } from "react";
import outsideservice from "../../../../images/outside-service.png";
import "./outsideAccordian.modules.css";
import { getUser } from "../../../../helper/auth_helper";
import api from "../../../../helper/auth_interceptor";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';

export const Outside = ({ outsideProduct, setOutsideProduct, setComponentCompletion, validationCheck }) => {
  const { t } = useTranslation();
  const [outsideItem, setOutsideItem] = useState([]);
  
  const RightImage = {
    width: '100%',
    objectFit: 'contain',
    marginTop: '35px'
  }

  const handleValueChange = (selectedId, selectedName) => {
    let updatedProducts;

    const isAlreadySelected = outsideProduct.some((product) => product.id === selectedId);
  
    if (isAlreadySelected) {
      updatedProducts = outsideProduct.filter((product) => product.id !== selectedId);
    } else {
      updatedProducts = [...outsideProduct, { id: selectedId, name: selectedName }];
    }

    setOutsideProduct(updatedProducts);
    setComponentCompletion(updatedProducts.length > 0);
    return updatedProducts;
  };

  const getOutside = async () => {
    const user = await getUser();
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/quote/outsideProduct`;

    try {
      const response = await api.get(apiUrl, {
        headers,
      });
      if (response) {
        setOutsideItem(response.data);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessages = Object.values(error.response.data).join("\n ");
        toast.error(errorMessages);
      }
    }
  };

  useEffect(() => {
    getOutside();
  }, []);

  return (
    <div className="form-head">
      <section class={`accordion ${validationCheck ? 'no-selection' : ''}`}>
        <div class="tab ">
          <input type="checkbox" className="CheckInput" name="accordion-1" id="cb3" />
          <label htmlFor="cb3" class="tab-label form-head quote-detail">
            <h2>{t('Exterior_outsideAccordianCreateQuote')}</h2>
          </label>
          <div class="tab-content fourth">
            <div class="main-outside-content">
              <div class="outside-model">
                <div class="left-content">
                  <div class="header">
                    <h3>{t('3DModelText_outsideAccordianCreateQuote')}</h3>
                    <p>{t('text1_outsideAccordianCreateQuote')}</p>
                  </div>

                  <div class="model">
                    {outsideItem?.map((item) => (
                      <div class="check" key={item.id}>
                        <input
                          className="CheckInput"
                          type="checkbox"
                          value={item.id}
                          onChange={() => handleValueChange(item.id, item.name)}
                        />
                        <label htmlFor="Roof">{item.name}</label>
                      </div>
                    ))}
                  </div>
                </div>

                <div class="right-content">
                  <img src={outsideservice} style={RightImage} alt="outside Service" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {validationCheck && <div className="message">{t('pleaseChooseAtLeastOneField_errorMessage')}</div>}
    </div>
  );
};
