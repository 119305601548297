import React, { useState, useEffect } from "react";
import "./outsidePurpose.modules.css";
import { useTranslation } from 'react-i18next';
import api from "../../../../../helper/auth_interceptor";
import { getUser } from "../../../../../helper/auth_helper";
import { toast } from "react-toastify";

export const OutsidePurpose = ({
  newQuoteDetails,
  
 
 
}) => {
  const [data, setData] = useState([]);
  const inspectionData = data.filter((item) => item.id >= 1 && item.id <= 4);
  const renovationData = data.filter((item) => item.id >= 5 && item.id <= 8);
  const assetData = data.filter((item) => item.id >= 9 && item.id <= 12);
 
  const [isInspectionSelected, setIsInspectionSelected] = useState(true);
  const [isRenovationSelected, setIsRenovationSelected] = useState(true);
  const [isAssetManagementSelected, setIsAssetManagementSelected] =  useState(true);
  const { t } = useTranslation();



  const inspectionDataItem = newQuoteDetails.quoteOutsidePurposes
    .filter(
      (item) => item.outsidePurpose.id >= 1 && item.outsidePurpose.id <= 4
    )
    .map((item) => item.outsidePurpose.id);
  const renovationDataItem = newQuoteDetails.quoteOutsidePurposes
    .filter(
      (item) => item.outsidePurpose.id >= 5 && item.outsidePurpose.id <= 8
    )
    .map((item) => item.outsidePurpose.id);
  const assetDataItem = newQuoteDetails.quoteOutsidePurposes
    .filter(
      (item) => item.outsidePurpose.id >= 9 && item.outsidePurpose.id <= 12
    )
    .map((item) => item.outsidePurpose.id);

    const otherPurposeTextItem = newQuoteDetails.quoteOutsidePurposes.find(
      (item) => item.outsidePurpose.id === 13
    )?.otherPurpose;


  

  const getData = async () => {
    const user = await getUser();
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/quote/outsidePurpose`;

    try {
      const response = await api.get(apiUrl, {
        headers,
      });
      if (response) {
        setData(response.data);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessages = Object.values(error.response.data).join("\n ");
        toast.error(errorMessages);
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="form-head">
      <section class="accordion">
        <div class="tab">
          <input type="checkbox" name="accordion-1" id="cb4" />
          <label for="cb4" class="tab-label form-head quote-detail">
            <h2>{t('exteriorPurpose_outsidePurposeCreateQuote')}</h2>
          </label>
          <div class="tab-content record">
            <div>
              <div class="check-header">
                <div class="check-header-title">
                  
                  <label for="Inspection">{t('inspection_outsideInspectionQuote')}</label>
                </div>
              </div>
              <div class="check-options purpose">
                {inspectionData?.map((item) => (
                  <div class="check">
                    <input
                    className="outPurposeSelOffrInput"
                      type="checkbox"
                      value={item.id}
                      disabled
                      checked={inspectionDataItem.includes(item.id)}
                     
                    />
                    <label for={item.id}>{item.name}</label>
                  </div>
                ))}
              </div>
             
            </div>

            <div>
              <div class="check-header">
                <div class="check-header-title">
                  
                  <label for="Quantities">{t('renovationSustainability_outsidePurposeCreateQuote')}</label>
                </div>
              </div>
              <div class="check-options purpose">
                {renovationData?.map((item) => (
                  <div class="check">
                    <input
                    className="outPurposeSelOffrInput"
                      type="checkbox"
                      value={item.id}
                      disabled
                      checked={renovationDataItem.includes(item.id)}
                    
                    />
                    <label for={item.id}>{item.name}</label>
                  </div>
                ))}
              </div>
             
            </div>

            <div>
              <div class="check-header">
                <div class="check-header-title">
                  
                  <label for="Quantities">{t('assetManagement_outsidePurposeCreateQuote')}</label>
                </div>
              </div>
              <div class="check-options purpose">
                {assetData?.map((item) => (
                  <div class="check">
                    <input
                    className="outPurposeSelOffrInput"
                      type="checkbox"
                      value={item.id}
                      disabled
                      checked={assetDataItem.includes(item.id)}
                      
                    />
                    <label for={item.id}>{item.name}</label>
                  </div>
                ))}
              </div>
              
            </div>

            <div>
              <div class="check-header">
              <div class="check-header-title">
                      <input
                      className="outPurposeSelOffrInput"
                        type="checkbox"
                        checked={otherPurposeTextItem}
                        id="Quantities"
                      />
                      <label for="Quantities">{t('Others_outsidePurposeCreateQuote')}</label>
                    </div>
                    <input
                    
                      type="text"
                      value={otherPurposeTextItem}
                      disabled
                      className="form-control"
                    />
              </div>
            </div>
          </div>
        </div>
      </section>
     
    </div>
  );
};
