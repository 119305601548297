import React, { useState, useEffect, useRef } from "react";
import { getUser } from "../../helper/auth_helper";
import api from "../../helper/auth_interceptor";

export const handleSort = async (column, page, sortedColumn, isAscending, setSortedColumn, setIsAscending, currentPage, selectedPageSize, requestBody, setProject, setPageNumbers) => {
  const user = await getUser();
  const pageForUrl = page || currentPage.current;
  const selectedSize = selectedPageSize.current;
  const newIsAscending = column === sortedColumn ? !isAscending : true;
  setSortedColumn(column);
  setIsAscending(newIsAscending);

  // Determine the sorting order
  const sortOrder = newIsAscending ? "asc" : "desc";

  // You can use the sortOrder and column values as needed in your application
 
  const sortApi = `${process.env.REACT_APP_API_GATEWAY_URL}/project-management/api/v1/project/filter?pageNumber=${pageForUrl}&pageSize=${selectedSize}&sortBy=${column}&sortOrder=${sortOrder}`;
  const headers = {
    Authorization: `Bearer ${user.access_token}`,
  };

  try {
    const response = await api.post(sortApi, requestBody.current, {
      headers,
    });

    setProject(response.data.data);
    setPageNumbers(response.data.totalPages);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};