import React, { useState } from "react";
import "./inviteModal.modules.css";
import { useTranslation } from 'react-i18next';
import { getUser } from "../../../../helper/auth_helper";
import api from "../../../../helper/auth_interceptor";
import { toast } from "react-toastify";
import { Circles } from "react-loader-spinner";

const InviteModal = ({ id, closeModal, fetchProject, searchInputValue }) => {
  const [modalReq, setModalReq] = useState({
    email: searchInputValue,
    projectRoleType: "",
    firstName: "",
    lastName: "",
  });
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const inviteUser = async () => {
    const requiredFields = [
      "email",
      "lastName",
      "firstName",
      "projectRoleType",
    ];

    const fieldLabels = {
      email: t('emailAddress'),
      lastName: t('lastName'),
      firstName: t('firstName'),
      projectRoleType: t('projectRole_table'),
    };

    const newValidationErrors = {};
    let hasErrors = false;

    requiredFields.forEach((field) => {
      if (!modalReq[field]) {
        newValidationErrors[field] = `${fieldLabels[field]} ${t('required_errorMessage')}`;
        hasErrors = true;
      }
    });

    if (hasErrors) {
      setValidationErrors(newValidationErrors);
      return;
    }

    setLoading(true);
    const requestBody = {
      email: modalReq.email,
      projectRoleType: modalReq.projectRoleType,
      firstName: modalReq.firstName,
      lastName: modalReq.lastName,
    };
    const user = await getUser();
    const inviteApi = `${process.env.REACT_APP_API_GATEWAY_URL}/project-management/api/v1/project/user/${id}`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try {
      const response = await api.post(inviteApi, requestBody, {
        headers,
      });
      if (response) {
        fetchProject();
        closeModal();
        console.log("user created!!");
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        toast.error(`${t('userAlreadyExists_errorMessage')}`);
      }
      if (error.response && error.response.status === 403) {
        if (error.response && error.response.status === 400) {
          toast.error(`${t('fillDetails_errorMessage')}`);
        }

        toast.error(`${t('authorize_errorMessage')}`);
      } else if (error.response && error.response.data) {
        // const errorMessages = Object.values(error.response.data).join("\n ");
        toast.error(`${t('userAlreadyExists_errorMessage')}}`);
      } else {
        toast.error(`${t('errorInviting_errorMessage')}`);
      }
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const setProjectRole = (e) => {
    setModalReq({
      ...modalReq,
      projectRoleType: e.target.value,
    });
  };

  return (
    <div className={`project-creation-container ${loading ? "blur" : ""}`}>
      {loading && (
        <div className="loader-overlay">
          <Circles type="TailSpin" color="#FF6B00" height={150} width={100} />
        </div>
      )}
      <div class="userdiv">
        <div class="invite-details-content">
          <div class="first-row">
            <div
              className={`rows-content ${
                validationErrors.firstName ? "error" : ""
              }`}
            >
              
              <label>
                {t('firstName')} <span className="required">*</span>
              </label>
              <input
                className="form-control"
                placeholder={t('firstName')}
                type="text"
                name="firstName"
                value={modalReq.firstName}
                onChange={(e) =>
                  setModalReq({
                    ...modalReq,
                    firstName: e.target.value,
                  })
                }
              ></input>
              {validationErrors.firstName && (
                <span className="error-message">
                  {validationErrors.firstName}
                </span>
              )}
            </div>
            <div
              className={`rows-content ${
                validationErrors.lastName ? "error" : ""
              }`}
            >
              
              <label>
                {t('lastName')} <span className="required">*</span>
              </label>
              <input
                className="form-control"
                placeholder={t('lastName')}
                type="text"
                name="lastName"
                value={modalReq.lastName}
                onChange={(e) =>
                  setModalReq({
                    ...modalReq,
                    lastName: e.target.value,
                  })
                }
              ></input>
              {validationErrors.lastName && (
                <span className="error-message">
                  {validationErrors.lastName}
                </span>
              )}
            </div>
          </div>
          <div class="second-row">
            <div
              className={`rows-content ${
                validationErrors.email ? "error" : ""
              }`}
            >
              
              <label>
                {t('emailAddress')} <span className="required">*</span>
              </label>
              <input
                className="form-control"
                placeholder="abc@gmail.com"
                type="text"
                name="email"
                value={modalReq.email}
                onChange={(e) =>
                  setModalReq({
                    ...modalReq,
                    email: e.target.value,
                  })
                }
              ></input>
              {validationErrors.email && (
                <span className="error-message">{validationErrors.email}</span>
              )}
            </div>
            <div
              className={`rows-content ${
                validationErrors.projectRoleType ? "error" : ""
              }`}
            >
              
              <label>
                {t('role')} <span className="required">*</span>
              </label>
              <select
                className="form-control "
                value={modalReq.projectRoleType}
                onChange={(e) => {
                  setProjectRole(e);
                }}
              >
                <option>{t('selectRole_editProjectTable')}</option>
                <option value="PROJECT_USER">{t('user_editProjectTable')}</option>
                <option value="PROJECT_VIEWER">{t('viewer_editProjectTable')}</option>
              </select>
              {validationErrors.projectRoleType && (
                <span className="error-message">
                  {validationErrors.projectRoleType}
                </span>
              )}
            </div>
          </div>
        </div>
        <div>
          <button
            class="btn btn-success modal-invite-save-button"
            onClick={inviteUser}
          >
            
            {t('submit')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default InviteModal;
