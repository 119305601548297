import React,{ useState, useEffect } from 'react';
import styles from "./tableData.modules.css";
import col from "../../images/column.png";


 function TableData({
    children,
    data,
    columns,
    visibilityObject,
    
  }){
    const [columnVisibility, setColumnVisibility] = useState(visibilityObject);

    const [showToggleColumnDropdown, setShowToggleColumnDropdown] =
    useState(false);

  const toggleColumn = (columnName) => {
    const newVisibility = {
      ...columnVisibility,
      [columnName]: !columnVisibility[columnName]
    };

    setColumnVisibility({ ...newVisibility });
}

const handleToggleColumnDropdown = () => {
    setShowToggleColumnDropdown((prev) => !prev);
  };

    useEffect(() => {
        setColumnVisibility(visibilityObject);
      }, [visibilityObject]);
    
      const renderColumnInvisibilityItem = (column) => {
        const isIcon = !!column?.hasIcon;
    
        return column.hideable && !isIcon ? (
          <div key={column.id} className={styles.columnVisibilityFilterItem}>
            <div className={styles.columnVisibilityFilterItemLabel}>
              <label htmlFor={column.id}>{column.name}</label>
            </div>
            <input
              type="checkbox"
              id={column.id}
              checked={columnVisibility[column.id]}
              onChange={() => toggleColumn(column.id)}
            />
          </div>
        ) : null;
      };

      
  const renderColumnItem = (column, item, key) => {
    const isIcon = !!column?.hasIcon;
    const onClick = () => {
      column?.onClickAction?.(item);
    };

    return isIcon ? (
        <button
          className={styles.iconButton}
          // disabled={rowIconColumnDisabled(item)}
          key={`${column.id} ${key}`}
          onClick={() => onClick()}>
          <img
            src={
             column.imageSource
            }
            alt={column.alt}
          />
        </button>
      ) : (
        <React.Fragment key={`${column.id} ${key}`}>
          {columnVisibility[column.id] && !isIcon && (
            <td className={`${styles.tableData} ${styles.verticalAlignment}`}>
              <div className={styles.tableDataText}>
                {column?.customFormating
                  ? column.customFormating(item[column.id])
                  : item[column.id]}
              </div>
            </td>
          )}
        </React.Fragment>
      );

  };
  return(
<div>
    <div className={styles.filterContainer}>
        <div className={styles.columnVisibilityFilterContainer}>
          <button
            className={`${styles.columnVisibilityFilterDropdown} ${
              showToggleColumnDropdown
                ? styles.columnVisibilityFilterDropdownOpen
                : ''
            }`}
            type="button"
            onClick={handleToggleColumnDropdown}>
            <img className={styles.inputImage} src={col} alt="dot" />
            Columns
          </button>
          {showToggleColumnDropdown && (
            <div className={styles.columnVisibilityFilterItemContainer}>
              {columns.map((column) => renderColumnInvisibilityItem(column))}
            </div>
          )}
        </div>
        {children}
      </div>
      <div className={`w-100 ${styles.tableContainer}`}>
        <table className="w-100">
          <thead>
            <tr>
              {columns.map((column) => (
                <React.Fragment key={`table head ${column.name}`}>
                  {columnVisibility[column.id] && (
                    <th className={styles.tableHeader}>{column.name}</th>
                  )}
                </React.Fragment>
              ))}
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              const key = `${item.id} ${index}`;

              return (
                <tr className={styles.tableRowStyling} key={`TR ${key}`}>
                  {columns.map((column) => renderColumnItem(column, item, key))}</tr>
              );
            })}
          </tbody>
        </table>
      </div>
      </div>
    
  )
  };





 export default TableData;