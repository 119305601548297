import React, { useEffect, useState } from "react";
import "./insideSummary.modules.css";
import { useTranslation } from 'react-i18next';
import { getUser } from "../../../../../helper/auth_helper";
import api from "../../../../../helper/auth_interceptor";
import { toast } from "react-toastify";


export const InsideProductAccord = ({ setNewQuoteDetails, newQuoteDetails, setComponentCompletion, validationCheck }) => {
  const [data, setData] = useState([]);
  const [isAnyOptionSelected, setIsAnyOptionSelected] = useState(false);
  const CheckInputItemMain = {
    top: "2px"
  }
  const { t } = useTranslation();
  const handleValueChange = (selectedId) => {
    
    const isIdSelected = newQuoteDetails.quoteInsideProducts.some((item) => item.insideProduct.id === selectedId);
    let updatedProducts;

    if (isIdSelected) {

      updatedProducts = newQuoteDetails.quoteInsideProducts.filter((item) => item.insideProduct.id !== selectedId);
    } else {

      const selectedProduct = data.find((item) => item.id === selectedId);
      updatedProducts = [...newQuoteDetails.quoteInsideProducts, { insideProduct: selectedProduct }];
    }
    const updatedQuoteDetails = { ...newQuoteDetails, quoteInsideProducts: updatedProducts };
    setNewQuoteDetails(updatedQuoteDetails);
    setIsAnyOptionSelected(updatedProducts.length > 0);
    setComponentCompletion(updatedProducts.length > 0);

    return updatedQuoteDetails;

  };

  const productData = newQuoteDetails.quoteInsideProducts;
  // if(productData){
  //   setComponentCompletion(true);
  // }

  const getData = async () => {
    const user = await getUser();
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/quote/insideProduct`;

    try {
      const response = await api.get(apiUrl, {
        headers,
      });
      if (response) {
        setData(response.data);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessages = Object.values(error.response.data).join("\n ");
        toast.error(errorMessages);
      }
    }
  };



  useEffect(() => {
    getData();
    const anyItemSelected = productData.length > 0;
    setComponentCompletion(anyItemSelected);
    setIsAnyOptionSelected(anyItemSelected);

  }, [productData]);
  return (
    <div className="form-head">
      <section className="accordion">
        <div class="tab">
          <input type="checkbox"  name="accordion-1" id="cb9" />
          <label for="cb9" class="tab-label form-head quote-detail">
            <h2>{t('interiorProduct_insideProductQuote')}</h2>
          </label>
          <div class="tab-content record">
            <div>
              <div class="check-header">
                {/* <div class="check-header-title">
                  <input type="checkbox" style={CheckInputItemMain} id="Extra" checked={isAnyOptionSelected} />
                  <label for="Extra">Extra Services</label>
                </div>  */}
              </div>
              <div class="check-options summary">
                {data?.map((item) => (
                  <div class="check">
                    <input type="checkbox" className="CheckInputInsProductExtServ" checked={productData.map((product) => product.insideProduct.id).includes(item.id)} diasabled value={item.id} onChange={() => handleValueChange(item.id)} />
                    <label for={item.id}>{item.name}</label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
};
