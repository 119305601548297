import React, { useState} from "react";
import Modal from "react-modal";
import { useTranslation } from 'react-i18next';
import { getUser } from "../../../helper/auth_helper";
import api from "../../../helper/auth_interceptor";
import "./bndl.modules.css";
import moment from "moment";

export const BdlListMod = ({ id, userDetails, onUpdateUserDetails,onSubmit }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);
  const [selectedPlan, setSelectedPlan] = useState([]);
  const [bundleDetails, setBundleDetails] = useState([]);
  const { t } = useTranslation();
  const now = moment();
  const todayDate = now.format("DD-MM-YYYY");

  const [buttonTextMap, setButtonTextMap] = useState({});
 
  const handleClicks = (e, licenseType) => {
    e.preventDefault();

    const licenseId = bundleDetails.find(
      (license) => license.name === licenseType
    ).id;
    const isActivated = userDetails.bundleIdList.includes(licenseId);

    const updatedBundleList = isActivated
      ? userDetails.bundleIdList.filter((bundleId) => bundleId !== licenseId)
      : [...userDetails.bundleIdList, licenseId];

    onUpdateUserDetails({
      ...userDetails,
      bundleIdList: updatedBundleList,
    });
    
    setButtonTextMap((prevMap) => ({
      ...prevMap,
      [licenseType]: isActivated ? "Activate" : "Activated",
    }));
    const selectedBundles = bundleDetails
      .filter((bundle) => updatedBundleList.includes(bundle.id))
      .map((bundle) => bundle.name);

    setSelectedPlan(selectedBundles);
  };

    
  const handleValueChange = (newProjectDetailsValues) => {
    onUpdateUserDetails({
      ...userDetails,
      ...newProjectDetailsValues,
    });
  };

    const getLicence = async () => {
    const user = await getUser();

    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/bundle`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try {
      const response = await api.get(apiUrl, {
        headers,
      });

      setBundleDetails(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div>
      <button
        className="btn btn-success bundle-his-Btn"
        onClick={() => {
          openModal();
          getLicence();
        }}
      >
       {t('buyBundles_bndl')}
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            width: "80%",
            height: "70%",
            margin: "auto",
          },
        }}
      >
        <div className="modal-content bundle-address">
          <div class="modal-header">
            <div>
              <h2>{t('popUpHeader_editOrg')}</h2>
            </div>
            <div>
              <button className="closeBtn" onClick={closeModal}>
              <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    height="25px"
                  >
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                  </svg>
              </button>
            </div>
          </div>
          <div class="plan-organization-record">
            <div class="plan-details">
              <div className="form-head-organisation">
                <div class="plan-heading">
                  <h2 className="pageHeading right-plan">
                    
                    {t('tabThreeHeader_createOrgEditOrg')}
                  </h2>
                  <p>
                    {t('tabThreeHeadDescription_createOrgEditOrg')}
                  </p>
                </div>
              </div>
              <div class="organization-content right-plan">
                
                <div className="row price-row">
                  
                  {bundleDetails
                .filter((licence) => licence.name !== "BasicBundle").map((licence) => (
                    <div
                      key={licence.id}
                      className="col-lg-2 col-md-2 col-sm-2 content"
                    >
                      <h5 className="pricingHeading">{licence.name}</h5>
                      <p className="amount">
                        <h3>€{licence.pricePerBucket}</h3>/{t('year_bndl')}
                      </p>
                      <ul className="tick-list">
                        <li>{t('buildings_bndl')}: {licence.numberOfBuildingsInBucket}</li>
                        <li>
                          {t('price/Building_bndl')}: €{licence.avgPricePerBuildingPerYear}
                        </li>
                      </ul>
                      
                        <button
                          className={`btn ${
                            userDetails.bundleIdList.includes(
                              bundleDetails.find(
                                (license) => license.name === licence.name
                              ).id
                            )
                              ? "btn-success activebtn"
                              : "btn-outline-dark activebtn"
                          }`}
                          onClick={(e) => {
                            handleClicks(e, licence.name);
                            setButtonTextMap((prevMap) => ({
                              ...prevMap,
                              [licence.name]: userDetails.bundleIdList.includes(
                                bundleDetails.find(
                                  (license) => license.name === licence.name
                                ).id
                              )
                                ? "Activate"
                                : "Activated",
                            }));
                          }}
                        >
                          {buttonTextMap[licence.name] || "Activate"}
                        </button>
                    
                    </div>
                  ))}
                  <div className="discountDiv">
                    <h5>{t('tabThreeInactiveBuildings_createOrgEditOrg')}</h5>
                    <input
                      placeholder="1-100%"
                      className="form-control inputWidth"
                      onChange={(e) =>
                        handleValueChange({
                          ...userDetails,
                          minInactiveBuildingPercent: e.target.value,
                        })
                      }
                      type="text"
                    ></input>
                  </div>
                  <div className="discountDiv">
                    <h5>{t('tabThreeDiscount_createOrgEditOrg')}</h5>
                    <input
                      placeholder="1-100%"
                      className="form-control inputWidth"
                      onChange={(e) =>
                        handleValueChange({
                          ...userDetails,
                          discount: e.target.value,
                        })
                      }
                      type="text"
                    ></input>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="organization-plan plan-subscription">
            <div>
              <h4> {t('youChose_bndl')} {selectedPlan.length > 1
              ? `${selectedPlan.slice(0, -1).join(', ')} & ${selectedPlan.slice(-1)} plan`
              : `${selectedPlan} plan`} {t('forYourOrganization_bndl')}</h4>
              <p className="">
                
                <span>{t('text1_bndl')} </span>
                {todayDate}.
              </p>
            </div>
          </div>
         <div class="bundle-save">
         <button
        className="btn saveBndleBtn"
        onClick={(e) => {
          if (e) {
            e.preventDefault();
          }
          onSubmit();
          closeModal();

        }}
      >
        {t('saveBtn')}
      </button>
         </div>
        </div>
      </Modal>
    </div>
  );
};
