import React, { useState } from "react";
import {
  ContentState,
  EditorState,
  convertToRaw,
  convertFromRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../App.css";
import { getUser } from "../../helper/auth_helper";
import { toast } from "react-toastify";


const TextEditor = () => {
  const text =
    "Hello <ClientName>, \n We have received your request for the quote.\n Thanks & Regards, \n SupRmen Admin";
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(ContentState.createFromText(text))
  );
  const [recipient, setRecipient] = useState("");
  const [subject, setSubject] = useState("");

  const handleEditorChange = (state) => {
    setEditorState(state);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const contentState = convertFromRaw(rawContentState);
    const body = contentState.getPlainText();

    const emailData = {
      recipient,
      subject,
      body,
    };

    try {
      let user = await getUser();

      const url = `${process.env.REACT_APP_API_GATEWAY_URL}/send-edited-email`;
      const response = await fetch(url, {
        method: "POST",

        headers: {
          Authorization: `Bearer ${user.access_token}`,
          "Content-type": "application/json",
        },
        body: JSON.stringify(emailData),
      });

      if (response.ok) {
        console.log("Email sent successfully!");
      } else {
        console.error("Failed to send email");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      <h2>Email Form</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="recipient">Recipient:</label>
          <input
            type="email"
            id="recipient"
            value={recipient}
            onChange={(e) => setRecipient(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="subject">Subject:</label>
          <input
            type="text"
            id="subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="body">Body:</label>
          <Editor
            editorState={editorState}
            onEditorStateChange={handleEditorChange}
          />
        </div>
        <button
          type="submit"
          onClick={() => {
            setEditorState(
              EditorState.createWithContent(ContentState.createFromText(text))
            );
          }}
          className="btn btn-warning btn1 "
        >
          Cancel
        </button>
        <button
          type="submit"
          onClick={() => {
            toast("Message Sent!");
          }}
          className="btn btn-primary btn1 "
        >
          Send Email
        </button>
      </form>
      
    </div>
  );
};
export default TextEditor;
