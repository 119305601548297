import React, { useEffect, useState } from "react";
import "./outsideExtras.modules.css";
import { useTranslation } from 'react-i18next';
import { getUser } from "../../../../../helper/auth_helper";
import api from "../../../../../helper/auth_interceptor";
import { toast } from "react-toastify";
import Frame from "../../../../../images/Frame.png";
import secondaryDelete from "../../../../../images/secondaryDelete.png";

export const OutsideExtrasAccord = ({
  
  offerDetails,
  newQuoteDetails,
 
}) => {
  const [data, setData] = useState([]);
  const [isAnyOptionSelected, setIsAnyOptionSelected] = useState(true);
 const checkInputItemMain={
  top: '2px',
    position: 'relative'
 }
 const { t } = useTranslation();
 const AlignRight={
  position:"relative",
  right: "15px"
}
const AlignCenter={
  textAlign:"center"
}

 
  const backendIds = newQuoteDetails.quoteOutsideExtraServices.map(
    (item) => item.outsideExtraServices.id
  );

  const getData = async () => {
    const user = await getUser();
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/quote/outsideExtraServices`;

    try {
      const response = await api.get(apiUrl, {
        headers,
      });
      if (response) {
        setData(response.data);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessages = Object.values(error.response.data).join("\n ");
        toast.error(errorMessages);
      }
    }
  };

  function calculateTotalCost(selectedOutsideExtraServiceOffer) {
    if (!selectedOutsideExtraServiceOffer) return 0;
  
    const totalRate = selectedOutsideExtraServiceOffer.outsideExtraServiceOffers.reduce((total, item) => total + item.cost, 0);
 
    const commissionPercentage = selectedOutsideExtraServiceOffer.commission || 0;
  
    // const subtotal = totalRate;
    // const commissionAmount = (subtotal * commissionPercentage) / 100;
    const totalCost = totalRate + commissionPercentage;
  
    return totalCost.toFixed(2);

  }
  function calculateTotalWithiCommCost(selectedOutsideExtraServiceOffer) {
    if (!selectedOutsideExtraServiceOffer) return 0;
  
    const totalRate = selectedOutsideExtraServiceOffer.outsideExtraServiceOffers.reduce((total, item) => total + item.cost, 0);
 
   
  
    // const subtotal = totalRate;
    // const commissionAmount = (subtotal * commissionPercentage) / 100;
    const totalCost = totalRate ;
  
    return totalCost.toFixed(2);

  }
  

  useEffect(() => {
    getData();
  }, []);

  
  return (
    <div className="form-head">
      <section class="accordion">
        <div class="tab">
          <input type="checkbox" name="accordion-1" id="cb6" />
          <label for="cb6" class="tab-label form-head quote-detail">
            <h2>{t('exteriorExtras_outsideExtrasQuote')}</h2>
            
          </label>
          <div class="tab-content record">
            <div>
         
              <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-3">
                  <div class="model">
                    {data?.map((item) => (
                      <div class="outsideIteminputfields">
                        <input
                        className="outExtraPlanextraInput"
                          type="checkbox"
                          value={item.id}
                          disabled
                          checked={backendIds.includes(item.id)}
                        />
                        <label for="Roof">{item.name}</label>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-9 tableDspEdit">
                  <div className="left-content ">
                    <table className="dspTable">
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th  style={AlignRight}>Price(€)</th>
                        </tr>
                      </thead>
                      <tbody>
                       {offerDetails.selectedOutsideExtraServiceOffer && offerDetails.selectedOutsideExtraServiceOffer.outsideExtraServiceOffers.map((item)=>(
                        <tr>
                          <td>{item.quoteOutsideExtraServices.outsideExtraServices.name}</td>
                          <td  style={AlignRight}>{new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(item.cost)}</td>
                        </tr>
                       )) }
                       
                      </tbody>
                     
                    </table>
                    <p className="commissionOutTag">{t('total')} - {new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(calculateTotalWithiCommCost(offerDetails.selectedOutsideExtraServiceOffer))}</p>
                    <p className="commissionOutTag">{t('commisions_partnerAccord')}- {new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(offerDetails.selectedOutsideExtraServiceOffer && offerDetails.selectedOutsideExtraServiceOffer.commission)}</p>
                      <p className="commissionOutTag">{t('totalCost_outsideAccordian')} - {new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(calculateTotalCost(offerDetails.selectedOutsideExtraServiceOffer))} ({t('commissionsIncluded_outsideAccordian')})</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
    </div>
  );
};
