import deleteBtn from "../../../images/trash.png";
import { DropdownFilter } from "../../../utility/ColumnFilter";


export const OrgTable = ({
  handleSortWrapper,
  sortedColumn,
  isAscending,
  t,
  orgs,
  handleDeleteOrganization,
  handleEditClick,
  columns,
  selectedColumns,
  onToggleColumn,
  handleResetColumns,
}) => {

  return (
    <div class="organizationlist-details-table-content">
      <div class="table-content">
        <div className="list">
          <table>
            <thead>
              <tr className="record-row">
                {selectedColumns.orgName && (
                  <th
                    className="ForNameRow opac"
                    onClick={() => handleSortWrapper("name")}
                  >
                    {t("organisationName_table")}
                    {sortedColumn === "name" ? (isAscending ? "" : "") : ""}
                    <div class="table-arrow">
                      <div class="arrow-up">&#9650;</div>
                      <div class="arrow-down">&#9660;</div>
                    </div>
                  </th>
                )}
                {selectedColumns.stad && (
                  <th
                    className="ForCityRow opac"
                    onClick={() => handleSortWrapper("city")}
                  >
                    {t("city_table")}
                    {sortedColumn === "city" ? (isAscending ? "" : "") : ""}
                    <div class="table-arrow">
                      <div class="arrow-up">&#9650;</div>
                      <div class="arrow-down">&#9660;</div>
                    </div>
                  </th>
                )}
                {selectedColumns.startDate && (
                  <th
                    className="ForDateRow opac"
                    onClick={() => handleSortWrapper("startDate")}
                  >
                    {t("startDate_table")}
                    {sortedColumn === "startDate"
                      ? isAscending
                        ? ""
                        : ""
                      : ""}
                    <div class="table-arrow">
                      <div class="arrow-up">&#9650;</div>
                      <div class="arrow-down">&#9660;</div>
                    </div>
                  </th>
                )}
                {selectedColumns.endDate && (
                  <th
                    className="ForDateRow opac"
                    onClick={() => handleSortWrapper("endDate")}
                  >
                    {t("endDate_table")}
                    {sortedColumn === "endDate" ? (isAscending ? "" : "") : ""}
                    <div class="table-arrow">
                      <div class="arrow-up">&#9650;</div>
                      <div class="arrow-down">&#9660;</div>
                    </div>
                  </th>
                )}

                <DropdownFilter
                  columns={Object.keys(selectedColumns)}
                  selectedColumns={selectedColumns}
                  onToggleColumn={onToggleColumn}
                  handleResetColumns={handleResetColumns}
                />
              </tr>
            </thead>
            <tbody>
              {orgs.map((org) => (
                <>
                  <tr>
                    {selectedColumns.orgName && (
                      <td className="ForNameRow">
                        {" "}
                        <a href="" onClick={() => handleEditClick(org)}>
                          {org.name}
                        </a>
                      </td>
                    )}
                    {selectedColumns.stad && (
                      <td className="ForCityRow">{org.city}</td>
                    )}
                    {selectedColumns.startDate && (
                      <td className="ForDateRow">{org.startDate}</td>
                    )}
                    {selectedColumns.endDate && (
                      <td className="ForDateRow">{org.endDate}</td>
                    )}
                    {selectedColumns.actions && (
                      <td className="action-buttons">
                        <div>
                          <a onClick={() => handleDeleteOrganization(org)}>
                            <img src={deleteBtn} alt="delete button"></img>
                          </a>
                        </div>
                      </td>
                    )}
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
