import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { getUser } from "../../../../../../helper/auth_helper";
import api from "../../../../../../helper/auth_interceptor";
import bin from "../../../../../../images/bin.png";
import navigate from "../../../../../../images/navigate.png";

export const InspectionPop = ({ orgId, quoteId, inpectionSelected }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [popInfo, setPopInfo] = useState([]);
  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);
  const userdivMain ={display:"flex",marginTop: "15px"}
  const userdivLabel ={marginBottom:"5px"}
  const userdivInputDivLeft ={width:"49%" ,margin:"0 1% 0 0"}
  const userdivInputDivRight ={width:"50%" ,margin:"0 0 0 0"}
  const droneOpInputButton = {
    background: "#004747", padding: "2px 15px 3px 15px",
    fontSize: "14px", top: "0px",position: "relative",marginTop: "0px"}
    
  async function getPopInfo() {
    const user = await getUser();
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/offer/partner/${quoteId}/${orgId}?partnerType=INSPECTION_PARTNER`;

    try {
      const response = await api.get(apiUrl, {
        headers,
      });
      if (response) {
        setPopInfo(response.data);
      }
    } catch (error) {}
  }

    return (
      <div className="droneOpInputDiv">
        <button
          style={droneOpInputButton}
          className="btn btn-success bulkUploadBtn"
          onClick={() => {
            openModal();
            getPopInfo();
          }}
        >
          View Offer
        </button>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={{
            content: {
              width: "80%",
              height: "70%",
              margin: "auto",
            },
          }}
        >
          <div className="modal-content">
            <div class="modal-header">
              <div>
                <h2>Inspection Partner Offer</h2>
              </div>
              <div>
                <button className="closeBtn" onClick={closeModal}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    height="25px"
                  >
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="userdiv">
            {popInfo?.outsideExtraServiceOfferList && (
              <div class="first-row drone" style={userdivMain}>
                <div class="rows-content" style={userdivInputDivRight}>
                  <label style={userdivLabel}>Delivery Date</label>
                  <input
                    className="date form-control"
                    placeholder={
                      popInfo.deliveryDateBegin +
                      "  -  " +
                      popInfo.deliveryDateEnd
                    }
                    disabled
                    type="text"
                  />
                </div>
              </div>
            )}
            <div className="addressDiv-content">
              <table>
                <thead>
                  <tr>
                    <th>Item </th>
                    <th className="inspPopCostTH">Cost(€)</th>
                  </tr>
                </thead>
                <tbody>
                  {popInfo.outsideExtraServiceOfferList &&
                    popInfo.outsideExtraServiceOfferList.map((item) => (
                      <tr key={item.id}>
                        <td>
                          {
                            item.quoteOutsideExtraServices.outsideExtraServices
                              .name
                          }
                        </td>
                        <td className="inspPopCostTH">{new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(item.cost)}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            <div className="addressDiv-content">
              <table>
                <thead>
                  <tr>
                    <th>Item </th>
                    <th className="inspPopCostTH">Cost(€)</th>
                  </tr>
                </thead>
                <tbody>
                  {popInfo.outsideInspectionOfferList &&
                    popInfo.outsideInspectionOfferList.map((item) => (
                      <tr key={item.id}>
                        <td>
                          {item.quoteOutsideInspection.outsideInspection.name}
                        </td>
                        <td className="inspPopCostTH">{new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(item.cost)}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
       

          {/* <button
            className="btn btn-success modal-save-button"
            onClick={handleSubmit}
          >
            
            Submit
          </button> */}
        </div>
      </Modal>
    </div>
  );
};
